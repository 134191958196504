import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** action_dispatch_uploaded_file */
  File: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Autogenerated return type of AddCategory */
export type AddCategoryPayload = {
  __typename?: 'AddCategoryPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<Array<News>>;
};

/** Autogenerated return type of AddCategoryToStack */
export type AddCategoryToStackPayload = {
  __typename?: 'AddCategoryToStackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddCategoryToTopicStack */
export type AddCategoryToTopicStackPayload = {
  __typename?: 'AddCategoryToTopicStackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddChatroomToVideo */
export type AddChatroomToVideoPayload = {
  __typename?: 'AddChatroomToVideoPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddContentBulkInput = {
  carouselIds: Array<Scalars['ID']>;
  carouselContents: Array<CarouselContentInput>;
};

/** Autogenerated return type of AddContentBulk */
export type AddContentBulkPayload = {
  __typename?: 'AddContentBulkPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddExtraCovidCategory */
export type AddExtraCovidCategoryPayload = {
  __typename?: 'AddExtraCovidCategoryPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<News>;
};

export type AddSuggestedCategoriesToTopicInput = {
  suggestedCategoryId: Scalars['ID'];
  order: Scalars['Int'];
};

/** Autogenerated return type of AddSuggestedCategoriesToTopic */
export type AddSuggestedCategoriesToTopicPayload = {
  __typename?: 'AddSuggestedCategoriesToTopicPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddToCarouselInput = {
  id: Scalars['ID'];
  contentableId: Scalars['ID'];
  contentableType: Scalars['String'];
};

/** Autogenerated return type of AddToCarousel */
export type AddToCarouselPayload = {
  __typename?: 'AddToCarouselPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddVideoCategory */
export type AddVideoCategoryPayload = {
  __typename?: 'AddVideoCategoryPayload';
  errors?: Maybe<Array<Error>>;
  videos?: Maybe<Array<Video>>;
};

export type Admin = {
  __typename?: 'Admin';
  attachments: Array<AdminAttachment>;
  email: Scalars['String'];
  id: Scalars['ID'];
};


export type AdminAttachmentsArgs = {
  page?: Maybe<Scalars['Int']>;
};

export type AdminAttachment = {
  __typename?: 'AdminAttachment';
  filename: Scalars['String'];
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Announcement = {
  __typename?: 'Announcement';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  path: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AppBanner = {
  __typename?: 'AppBanner';
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundColorDark?: Maybe<Scalars['String']>;
  backgroundColorLight?: Maybe<Scalars['String']>;
  buttonBackgroundColorDark?: Maybe<Scalars['String']>;
  buttonBackgroundColorLight?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonTextColorDark?: Maybe<Scalars['String']>;
  buttonTextColorLight?: Maybe<Scalars['String']>;
  buttonTextLocale?: Maybe<Locale>;
  description?: Maybe<Locale>;
  dismissable: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  pathLocale?: Maybe<Locale>;
  title?: Maybe<Locale>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AppBannerInput = {
  title?: Maybe<LocaleInput>;
  description?: Maybe<LocaleInput>;
  image?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  pathLocale?: Maybe<LocaleInput>;
  dismissable?: Maybe<Scalars['Boolean']>;
  buttonTextLocale?: Maybe<LocaleInput>;
  backgroundColorLight?: Maybe<Scalars['String']>;
  backgroundColorDark?: Maybe<Scalars['String']>;
  buttonBackgroundColorLight?: Maybe<Scalars['String']>;
  buttonBackgroundColorDark?: Maybe<Scalars['String']>;
  buttonTextColorLight?: Maybe<Scalars['String']>;
  buttonTextColorDark?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
};

export type Author = {
  __typename?: 'Author';
  authorType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  nameLocale: Locale;
  path?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

export type AuthorInput = {
  nameLocale: LocaleInput;
  image?: Maybe<Scalars['File']>;
  path?: Maybe<Scalars['String']>;
  authorType: Scalars['String'];
  verified?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of BanUser */
export type BanUserPayload = {
  __typename?: 'BanUserPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  bannerImageUrl?: Maybe<Scalars['String']>;
  bannerSubtitle?: Maybe<Locale>;
  bannerTitle?: Maybe<Locale>;
  description?: Maybe<Locale>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Locale>;
  title?: Maybe<Locale>;
};

export type Carousel = {
  __typename?: 'Carousel';
  carouselContents?: Maybe<Array<CarouselContent>>;
  categories: Array<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  newsPublisher: NewsPublisher;
};

export type CarouselContent = {
  __typename?: 'CarouselContent';
  contentable?: Maybe<Contentable>;
  id: Scalars['ID'];
  sponsored: Scalars['Boolean'];
};

export type CarouselContentInput = {
  contentableId: Scalars['ID'];
  contentableType: Scalars['String'];
  sponsored?: Maybe<Scalars['Boolean']>;
};

export type Category = {
  __typename?: 'Category';
  author?: Maybe<Author>;
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionLocale?: Maybe<Locale>;
  emoji?: Maybe<Scalars['String']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  explore?: Maybe<Scalars['Boolean']>;
  exploreNameLocale?: Maybe<Locale>;
  hasComments?: Maybe<Scalars['Boolean']>;
  hasRichContent?: Maybe<Scalars['Boolean']>;
  hasThumbnail: Scalars['Boolean'];
  hot?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  languages: Scalars['JSON'];
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastPublishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  name: Scalars['String'];
  nameLocale?: Maybe<Locale>;
  newStatus?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  searchable?: Maybe<Scalars['Boolean']>;
  show?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  socialImageUrl?: Maybe<Scalars['String']>;
  suggestedCategories: Array<SuggestedCategory>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
  webTitleLocale?: Maybe<Locale>;
};


export type CategoryCommentsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export type ClearContentBulkInput = {
  carouselIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of ClearContentBulk */
export type ClearContentBulkPayload = {
  __typename?: 'ClearContentBulkPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ClearContentInput = {
  carouselId: Scalars['ID'];
};

/** Autogenerated return type of ClearContent */
export type ClearContentPayload = {
  __typename?: 'ClearContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Comment = {
  __typename?: 'Comment';
  commentableId: Scalars['ID'];
  commentableType: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  hasReplies?: Maybe<Scalars['Boolean']>;
  hasReports?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  likesCount: Scalars['Int'];
  parentContent?: Maybe<Commentable>;
  replies: Array<Comment>;
  replyParentComment?: Maybe<Comment>;
  reports: Array<Report>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};


export type CommentRepliesArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type CommentReportsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export type Commentable = Category | Comment | News | Video;

export type Contentable = Category | News | Video;

/** Autogenerated return type of CopyBlockToLangRichContent */
export type CopyBlockToLangRichContentPayload = {
  __typename?: 'CopyBlockToLangRichContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CovidStat = {
  __typename?: 'CovidStat';
  activeCases?: Maybe<Scalars['Int']>;
  cat1?: Maybe<Scalars['Int']>;
  cat2?: Maybe<Scalars['Int']>;
  cat3?: Maybe<Scalars['Int']>;
  cat4?: Maybe<Scalars['Int']>;
  cat5?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['ISO8601DateTime']>;
  deaths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  newCases?: Maybe<Scalars['Int']>;
  recovered?: Maybe<Scalars['Int']>;
  stateData?: Maybe<Array<StateCovidStat>>;
  todayActive?: Maybe<Scalars['Int']>;
  totalCases?: Maybe<Scalars['Int']>;
  totalDeaths?: Maybe<Scalars['Int']>;
  totalRecovered?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of CrawlNewsContent */
export type CrawlNewsContentPayload = {
  __typename?: 'CrawlNewsContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CrawlNewsInput = {
  originalUrl: Scalars['String'];
  newsPublisherId: Scalars['ID'];
  categoryNames: Array<Scalars['String']>;
};

/** Autogenerated return type of CrawlNews */
export type CrawlNewsPayload = {
  __typename?: 'CrawlNewsPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<News>;
};

/** Autogenerated return type of CrawlYoutubeVideo */
export type CrawlYoutubeVideoPayload = {
  __typename?: 'CrawlYoutubeVideoPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Create = {
  language: Scalars['String'];
  keywordString: Scalars['String'];
};

/** Autogenerated return type of CreateAppBanner */
export type CreateAppBannerPayload = {
  __typename?: 'CreateAppBannerPayload';
  appBanner?: Maybe<AppBanner>;
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateAuthorForTopic */
export type CreateAuthorForTopicPayload = {
  __typename?: 'CreateAuthorForTopicPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCarouselBulk = {
  categoryIds: Array<Scalars['ID']>;
  newsPublisherIds: Array<Scalars['ID']>;
  carouselContents?: Maybe<Array<CarouselContentInput>>;
};

/** Autogenerated return type of CreateCarouselBulk */
export type CreateCarouselBulkPayload = {
  __typename?: 'CreateCarouselBulkPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateChatNotificationInput = {
  titleLocale: LocaleInput;
  descriptionLocale: LocaleInput;
  path: Scalars['String'];
  image?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of CreateChatNotification */
export type CreateChatNotificationPayload = {
  __typename?: 'CreateChatNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateFeed */
export type CreateFeedPayload = {
  __typename?: 'CreateFeedPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateInput = {
  categoryIds: Array<Scalars['ID']>;
  newsPublisherId: Scalars['ID'];
  carouselContents?: Maybe<Array<CarouselContentInput>>;
};

/** Autogenerated return type of CreateKeyword */
export type CreateKeywordPayload = {
  __typename?: 'CreateKeywordPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateNewsNotificationInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  newsId: Scalars['ID'];
  customNotificationIds: Array<Scalars['ID']>;
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  highPriority?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateNewsNotification */
export type CreateNewsNotificationPayload = {
  __typename?: 'CreateNewsNotificationPayload';
  errors?: Maybe<Array<Error>>;
  newsNotifications?: Maybe<Array<NewsNotification>>;
};

/** Autogenerated return type of Create */
export type CreatePayload = {
  __typename?: 'CreatePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreatePinnedNews */
export type CreatePinnedNewsPayload = {
  __typename?: 'CreatePinnedNewsPayload';
  errors?: Maybe<Array<Error>>;
  pinnedNewsArticle?: Maybe<PinnedNewsArticle>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePrn2023ContentNotificationInput = {
  contentId: Scalars['ID'];
  contentType: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  states: Array<Scalars['String']>;
};

/** Autogenerated return type of CreatePrn2023ContentNotification */
export type CreatePrn2023ContentNotificationPayload = {
  __typename?: 'CreatePrn2023ContentNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type CreatePrn2023GenericNotificationInput = {
  titleLocale: LocaleInput;
  descriptionLocale: LocaleInput;
  path: Scalars['String'];
  image?: Maybe<Scalars['File']>;
  states: Array<Scalars['String']>;
};

/** Autogenerated return type of CreatePrn2023GenericNotification */
export type CreatePrn2023GenericNotificationPayload = {
  __typename?: 'CreatePrn2023GenericNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type CreatePrn2023NewsStackNotificationInput = {
  titleLocale: LocaleInput;
  descriptionLocale: LocaleInput;
  trendingTopicId: Scalars['ID'];
  states: Array<Scalars['String']>;
};

/** Autogenerated return type of CreatePrn2023NewsStackNotification */
export type CreatePrn2023NewsStackNotificationPayload = {
  __typename?: 'CreatePrn2023NewsStackNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateRichContent */
export type CreateRichContentPayload = {
  __typename?: 'CreateRichContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateTopicInput = {
  name: Scalars['String'];
  emoji?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
  hot?: Maybe<Scalars['Boolean']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  newStatus?: Maybe<Scalars['Boolean']>;
  explore?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['File']>;
  thumbnail?: Maybe<Scalars['File']>;
  nameLocale?: Maybe<LocaleInput>;
  exploreNameLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
  webTitleLocale?: Maybe<LocaleInput>;
  isNewsStack?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateTopic */
export type CreateTopicPayload = {
  __typename?: 'CreateTopicPayload';
  errors?: Maybe<Array<Error>>;
  topic?: Maybe<Category>;
};

export type CreateTopicStackInput = {
  title: Scalars['String'];
  titleLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
};

/** Autogenerated return type of CreateTopicStack */
export type CreateTopicStackPayload = {
  __typename?: 'CreateTopicStackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateVideoNotificationInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  videoId: Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of CreateVideoNotification */
export type CreateVideoNotificationPayload = {
  __typename?: 'CreateVideoNotificationPayload';
  errors?: Maybe<Array<Error>>;
  videoNotifications?: Maybe<Array<VideoNotification>>;
};

export type CustomBanner = {
  __typename?: 'CustomBanner';
  activeCasesLabel?: Maybe<Locale>;
  communityBannerColor?: Maybe<Locale>;
  communityBannerImage?: Maybe<Locale>;
  communityBannerLink?: Maybe<Locale>;
  communityBannerSubtitle?: Maybe<Locale>;
  communityBannerTitle?: Maybe<Locale>;
  confirmedCasesLabel?: Maybe<Locale>;
  covidTabBanner?: Maybe<Locale>;
  customLatestTabBanner?: Maybe<Locale>;
  customLatestTabBannerColor?: Maybe<Locale>;
  customLatestTabBannerLink?: Maybe<Locale>;
  dailyDosesLabel?: Maybe<Locale>;
  fatalitiesCasesLabel?: Maybe<Locale>;
  goodNewsBanner?: Maybe<Locale>;
  id?: Maybe<Scalars['ID']>;
  latestTabBanner?: Maybe<Locale>;
  newCovidCasesLabel?: Maybe<Locale>;
  oneDoseLabel?: Maybe<Locale>;
  recoveredCasesLabel?: Maybe<Locale>;
  searchBarHintText?: Maybe<Locale>;
  stateCasesLabel?: Maybe<Locale>;
  totalDosesLabel?: Maybe<Locale>;
  twoDosesLabel?: Maybe<Locale>;
  vaccinationGoalLabel?: Maybe<Locale>;
};

export type CustomNotification = {
  __typename?: 'CustomNotification';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of DecrawlNewsContent */
export type DecrawlNewsContentPayload = {
  __typename?: 'DecrawlNewsContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteAdminAttachment */
export type DeleteAdminAttachmentPayload = {
  __typename?: 'DeleteAdminAttachmentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteCarousel */
export type DeleteCarouselPayload = {
  __typename?: 'DeleteCarouselPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteComment */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteNewPublisherNotification */
export type DeleteNewPublisherNotificationPayload = {
  __typename?: 'DeleteNewPublisherNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteNewsNotification */
export type DeleteNewsNotificationPayload = {
  __typename?: 'DeleteNewsNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteNews */
export type DeleteNewsPayload = {
  __typename?: 'DeleteNewsPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<News>;
};

/** Autogenerated return type of DeleteRichContent */
export type DeleteRichContentPayload = {
  __typename?: 'DeleteRichContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteTopic */
export type DeleteTopicPayload = {
  __typename?: 'DeleteTopicPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteTopicThumbnail */
export type DeleteTopicThumbnailPayload = {
  __typename?: 'DeleteTopicThumbnailPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteTrendingTopicNotification */
export type DeleteTrendingTopicNotificationPayload = {
  __typename?: 'DeleteTrendingTopicNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteUser */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteVideoNotification */
export type DeleteVideoNotificationPayload = {
  __typename?: 'DeleteVideoNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteVideo */
export type DeleteVideoPayload = {
  __typename?: 'DeleteVideoPayload';
  errors?: Maybe<Array<Error>>;
  video?: Maybe<Video>;
};

/** Autogenerated return type of DuplicateRichContent */
export type DuplicateRichContentPayload = {
  __typename?: 'DuplicateRichContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EditCampaignInput = {
  bannerTitle: LocaleInput;
  bannerSubtitle: LocaleInput;
  bannerImage?: Maybe<Scalars['File']>;
  title: LocaleInput;
  subtitle: LocaleInput;
  image?: Maybe<Scalars['File']>;
  description: LocaleInput;
};

/** Autogenerated return type of EditCampaign */
export type EditCampaignPayload = {
  __typename?: 'EditCampaignPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EditNewsInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  readerMode?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EditNews */
export type EditNewsPayload = {
  __typename?: 'EditNewsPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of EditReport */
export type EditReportPayload = {
  __typename?: 'EditReportPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EditVideoInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  orientation?: Maybe<Scalars['String']>;
  isWebViewable?: Maybe<Scalars['Boolean']>;
  liveChatId?: Maybe<Scalars['String']>;
  isShort?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of EditVideo */
export type EditVideoPayload = {
  __typename?: 'EditVideoPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of FeatureGe15Video */
export type FeatureGe15VideoPayload = {
  __typename?: 'FeatureGe15VideoPayload';
  errors?: Maybe<Array<Error>>;
  video?: Maybe<Video>;
};

/** Autogenerated return type of FeatureVideo */
export type FeatureVideoPayload = {
  __typename?: 'FeatureVideoPayload';
  errors?: Maybe<Array<Error>>;
  video?: Maybe<Video>;
};

export type FeaturedVideo = {
  __typename?: 'FeaturedVideo';
  id: Scalars['ID'];
  language?: Maybe<Language>;
  video?: Maybe<Video>;
};

/** Autogenerated return type of FetchImage */
export type FetchImagePayload = {
  __typename?: 'FetchImagePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};


export type Ge15Stack = {
  __typename?: 'Ge15Stack';
  categories: Array<Category>;
  descriptionLocale?: Maybe<Locale>;
  id: Scalars['ID'];
  title: Scalars['String'];
  titleLocale?: Maybe<Locale>;
};



export type Keyword = {
  __typename?: 'Keyword';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  keywordString: Scalars['String'];
  language: Scalars['String'];
  position: Scalars['Int'];
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Locale = {
  __typename?: 'Locale';
  en?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ms?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
};

export type LocaleInput = {
  en: Scalars['String'];
  ms: Scalars['String'];
  zh: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<Admin>;
};

/** Autogenerated return type of Logout */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCategory?: Maybe<AddCategoryPayload>;
  addCategoryToGe15Stack?: Maybe<AddCategoryToStackPayload>;
  addCategoryToTopicStack?: Maybe<AddCategoryToTopicStackPayload>;
  addChatroomToVideo?: Maybe<AddChatroomToVideoPayload>;
  addContentBulk?: Maybe<AddContentBulkPayload>;
  addExtraCovidCategory?: Maybe<AddExtraCovidCategoryPayload>;
  addSuggestedCategoriesToTopic?: Maybe<AddSuggestedCategoriesToTopicPayload>;
  addToCarousel?: Maybe<AddToCarouselPayload>;
  addVideoCategory?: Maybe<AddVideoCategoryPayload>;
  banUser?: Maybe<BanUserPayload>;
  clearContent?: Maybe<ClearContentPayload>;
  clearContentBulk?: Maybe<ClearContentBulkPayload>;
  copyBlockToLangRichContent?: Maybe<CopyBlockToLangRichContentPayload>;
  crawlNews?: Maybe<CrawlNewsPayload>;
  crawlNewsContent?: Maybe<CrawlNewsContentPayload>;
  crawlYoutubeVideo?: Maybe<CrawlYoutubeVideoPayload>;
  createAppBanner?: Maybe<CreateAppBannerPayload>;
  createAuthorForTopic?: Maybe<CreateAuthorForTopicPayload>;
  createCarousel?: Maybe<CreatePayload>;
  createCarouselBulk?: Maybe<CreateCarouselBulkPayload>;
  /** Send a notification to users to join chat */
  createChatNotification?: Maybe<CreateChatNotificationPayload>;
  createKeyword?: Maybe<CreateKeywordPayload>;
  createNewsNotification?: Maybe<CreateNewsNotificationPayload>;
  createPinnedNews?: Maybe<CreatePinnedNewsPayload>;
  /** Send a noti that is tied to a content (News/Video) to users who've set up prn2023 notification */
  createPrn2023ContentNotification?: Maybe<CreatePrn2023ContentNotificationPayload>;
  /** Send a generic noti (not tied to any content) to users who've set up prn2023 notification */
  createPrn2023GenericNotification?: Maybe<CreatePrn2023GenericNotificationPayload>;
  /** Send a news stack noti to users who've set up prn2023 notification */
  createPrn2023NewsStackNotification?: Maybe<CreatePrn2023NewsStackNotificationPayload>;
  createRichContent?: Maybe<CreateRichContentPayload>;
  createTopic?: Maybe<CreateTopicPayload>;
  createTopicFeed?: Maybe<CreateFeedPayload>;
  createTopicStack?: Maybe<CreateTopicStackPayload>;
  createVideoNotification?: Maybe<CreateVideoNotificationPayload>;
  decrawlNewsContent?: Maybe<DecrawlNewsContentPayload>;
  deleteAdminAttachment?: Maybe<DeleteAdminAttachmentPayload>;
  deleteCarousel?: Maybe<DeleteCarouselPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteNewPublisherNotification?: Maybe<DeleteNewPublisherNotificationPayload>;
  deleteNews?: Maybe<DeleteNewsPayload>;
  deleteNewsNotification?: Maybe<DeleteNewsNotificationPayload>;
  deleteRichContent?: Maybe<DeleteRichContentPayload>;
  deleteTopic?: Maybe<DeleteTopicPayload>;
  deleteTopicThumbnail?: Maybe<DeleteTopicThumbnailPayload>;
  deleteTrendingTopicNotification?: Maybe<DeleteTrendingTopicNotificationPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteVideo?: Maybe<DeleteVideoPayload>;
  deleteVideoNotification?: Maybe<DeleteVideoNotificationPayload>;
  duplicateRichContent?: Maybe<DuplicateRichContentPayload>;
  editCampaign?: Maybe<EditCampaignPayload>;
  editNews?: Maybe<EditNewsPayload>;
  editReport?: Maybe<EditReportPayload>;
  editVideo?: Maybe<EditVideoPayload>;
  featureGe15Video?: Maybe<FeatureGe15VideoPayload>;
  featureVideo?: Maybe<FeatureVideoPayload>;
  fetchImage?: Maybe<FetchImagePayload>;
  login?: Maybe<LoginPayload>;
  logout?: Maybe<LogoutPayload>;
  notifyCovidStat?: Maybe<NotifyCovidStatPayload>;
  pinNews?: Maybe<PinNewsPayload>;
  removeCategory?: Maybe<RemoveCategoryPayload>;
  removeCategoryFromTopicStack?: Maybe<RemoveCategoryFromTopicStackPayload>;
  removeCategoryToGe15Stack?: Maybe<RemoveCategoryFromStackPayload>;
  removeFromCarousel?: Maybe<RemoveFromCarouselPayload>;
  removeSuggestedCategoryFromTopic?: Maybe<RemoveSuggestedCategoryFromTopicPayload>;
  removeVideoCategory?: Maybe<RemoveVideoCategoryPayload>;
  sendAnnouncementNotification?: Maybe<SendAnnouncementNotificationPayload>;
  sendNewPublisherNotification?: Maybe<SendNewPublisherNotificationPayload>;
  sendPublisherNotification?: Maybe<SendPublisherNotificationPayload>;
  sendTrendingTopicNotification?: Maybe<SendTrendingTopicNotificationPayload>;
  setGoodNews?: Maybe<SetGoodNewsPayload>;
  unfeatureGe15Video?: Maybe<UnfeatureGe15VideoPayload>;
  unfeatureVideo?: Maybe<UnfeatureVideoPayload>;
  unpinNews?: Maybe<UnpinNewsPayload>;
  updateAppBanner?: Maybe<UpdateAppBannerPayload>;
  updateAuthorForTopic?: Maybe<UpdateAuthorForTopicPayload>;
  updateCarouselContent?: Maybe<UpdateCarouselContentPayload>;
  updateCategoryToGe15Stack?: Maybe<UpdateCategoryPayload>;
  updateCovidStat?: Maybe<UpdateCovidStatPayload>;
  updateCustomBanner?: Maybe<UpdateCustomBannerPayload>;
  updateGe15Stack?: Maybe<UpdateGe15StackPayload>;
  updateKeyword?: Maybe<UpdateKeywordPayload>;
  updatePinnedNews?: Maybe<UpdatePinnedNewsPayload>;
  updatePublisher?: Maybe<UpdatePublisherPayload>;
  updateRichContent?: Maybe<UpdateRichContentPayload>;
  updateTopic?: Maybe<UpdateTopicPayload>;
  updateTopicFeed?: Maybe<UpdateFeedPayload>;
  updateTopicStack?: Maybe<UpdateTopicStackPayload>;
  uploadAdminAttachment?: Maybe<UploadAdminAttachmentPayload>;
  uploadImageRichContent?: Maybe<UploadImageRichContentPayload>;
};


export type MutationAddCategoryArgs = {
  ids: Array<Scalars['ID']>;
  categoryName: Scalars['String'];
};


export type MutationAddCategoryToGe15StackArgs = {
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
};


export type MutationAddCategoryToTopicStackArgs = {
  id: Scalars['ID'];
  categoryIds: Array<Scalars['ID']>;
};


export type MutationAddChatroomToVideoArgs = {
  id: Scalars['ID'];
  live: Scalars['Boolean'];
};


export type MutationAddContentBulkArgs = {
  data: AddContentBulkInput;
};


export type MutationAddExtraCovidCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationAddSuggestedCategoriesToTopicArgs = {
  id: Scalars['ID'];
  suggestedCategories: Array<AddSuggestedCategoriesToTopicInput>;
};


export type MutationAddToCarouselArgs = {
  data: AddToCarouselInput;
};


export type MutationAddVideoCategoryArgs = {
  ids: Array<Scalars['ID']>;
  categoryName: Scalars['String'];
};


export type MutationBanUserArgs = {
  userId: Scalars['ID'];
  isBan: Scalars['Boolean'];
};


export type MutationClearContentArgs = {
  data: ClearContentInput;
};


export type MutationClearContentBulkArgs = {
  data: ClearContentBulkInput;
};


export type MutationCopyBlockToLangRichContentArgs = {
  categoryId: Scalars['ID'];
  languageCodes: Array<Scalars['String']>;
  blocks: Array<Scalars['JSON']>;
};


export type MutationCrawlNewsArgs = {
  input: CrawlNewsInput;
};


export type MutationCrawlNewsContentArgs = {
  id: Scalars['ID'];
};


export type MutationCrawlYoutubeVideoArgs = {
  link: Scalars['String'];
  publisherId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isWebViewable?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateAppBannerArgs = {
  key: Scalars['String'];
  input: AppBannerInput;
};


export type MutationCreateAuthorForTopicArgs = {
  categoryId: Scalars['ID'];
  input: AuthorInput;
};


export type MutationCreateCarouselArgs = {
  data: CreateInput;
};


export type MutationCreateCarouselBulkArgs = {
  data: CreateCarouselBulk;
};


export type MutationCreateChatNotificationArgs = {
  input: CreateChatNotificationInput;
};


export type MutationCreateKeywordArgs = {
  data: Create;
};


export type MutationCreateNewsNotificationArgs = {
  input: CreateNewsNotificationInput;
};


export type MutationCreatePinnedNewsArgs = {
  languageCode: Scalars['String'];
  key: Scalars['String'];
  newsId: Scalars['String'];
};


export type MutationCreatePrn2023ContentNotificationArgs = {
  input: CreatePrn2023ContentNotificationInput;
};


export type MutationCreatePrn2023GenericNotificationArgs = {
  input: CreatePrn2023GenericNotificationInput;
};


export type MutationCreatePrn2023NewsStackNotificationArgs = {
  input: CreatePrn2023NewsStackNotificationInput;
};


export type MutationCreateRichContentArgs = {
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['ID']>;
};


export type MutationCreateTopicArgs = {
  input: CreateTopicInput;
};


export type MutationCreateTopicFeedArgs = {
  categoryId: Scalars['ID'];
  languageCode: Scalars['ID'];
};


export type MutationCreateTopicStackArgs = {
  input: CreateTopicStackInput;
};


export type MutationCreateVideoNotificationArgs = {
  input: CreateVideoNotificationInput;
};


export type MutationDecrawlNewsContentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAdminAttachmentArgs = {
  attachmentId: Scalars['ID'];
};


export type MutationDeleteCarouselArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  commentIds: Array<Scalars['ID']>;
};


export type MutationDeleteNewPublisherNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRichContentArgs = {
  categoryId: Scalars['ID'];
  languageCode: Scalars['ID'];
};


export type MutationDeleteTopicArgs = {
  topicId: Scalars['ID'];
};


export type MutationDeleteTopicThumbnailArgs = {
  topicId: Scalars['ID'];
};


export type MutationDeleteTrendingTopicNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVideoArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVideoNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateRichContentArgs = {
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
  fromLanguageCode: Scalars['String'];
  languageCodes?: Maybe<Array<Scalars['String']>>;
};


export type MutationEditCampaignArgs = {
  id: Scalars['ID'];
  input: EditCampaignInput;
};


export type MutationEditNewsArgs = {
  input?: Maybe<EditNewsInput>;
  id: Scalars['ID'];
};


export type MutationEditReportArgs = {
  reportIds: Array<Scalars['ID']>;
  type: Scalars['String'];
};


export type MutationEditVideoArgs = {
  input: EditVideoInput;
  id: Scalars['ID'];
};


export type MutationFeatureGe15VideoArgs = {
  id: Scalars['ID'];
  languageCode: Scalars['String'];
};


export type MutationFeatureVideoArgs = {
  id: Scalars['ID'];
  languageCode: Scalars['String'];
};


export type MutationFetchImageArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPinNewsArgs = {
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  categoryId?: Maybe<Scalars['String']>;
};


export type MutationRemoveCategoryArgs = {
  id: Scalars['ID'];
  categoryName: Scalars['String'];
};


export type MutationRemoveCategoryFromTopicStackArgs = {
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
};


export type MutationRemoveCategoryToGe15StackArgs = {
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
};


export type MutationRemoveFromCarouselArgs = {
  data: AddToCarouselInput;
};


export type MutationRemoveSuggestedCategoryFromTopicArgs = {
  id: Scalars['ID'];
  suggestedCategoryId: Scalars['ID'];
};


export type MutationRemoveVideoCategoryArgs = {
  id: Scalars['ID'];
  categoryName: Scalars['String'];
};


export type MutationSendAnnouncementNotificationArgs = {
  title: Scalars['String'];
  path: Scalars['String'];
  languageCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  states?: Maybe<Array<Scalars['String']>>;
  targetNonPrn2023NotiPreference?: Maybe<Scalars['Boolean']>;
  targetChatNoti?: Maybe<Scalars['Boolean']>;
};


export type MutationSendNewPublisherNotificationArgs = {
  input: SendNewPublisherNotificationInput;
};


export type MutationSendPublisherNotificationArgs = {
  input: SendPublisherNotificationInput;
};


export type MutationSendTrendingTopicNotificationArgs = {
  input: SendTrendingTopicNotificationInput;
};


export type MutationSetGoodNewsArgs = {
  id: Scalars['ID'];
};


export type MutationUnfeatureGe15VideoArgs = {
  languageCode: Scalars['String'];
};


export type MutationUnfeatureVideoArgs = {
  languageCode: Scalars['String'];
};


export type MutationUnpinNewsArgs = {
  languageCode: Scalars['String'];
  categoryId?: Maybe<Scalars['String']>;
};


export type MutationUpdateAppBannerArgs = {
  key: Scalars['String'];
  input: AppBannerInput;
};


export type MutationUpdateAuthorForTopicArgs = {
  authorId: Scalars['ID'];
  input: AuthorInput;
};


export type MutationUpdateCarouselContentArgs = {
  data: UpdateCarouselContentInput;
};


export type MutationUpdateCategoryToGe15StackArgs = {
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  position: Scalars['Int'];
};


export type MutationUpdateCovidStatArgs = {
  input: UpdateCovidStatInput;
};


export type MutationUpdateCustomBannerArgs = {
  input: UpdateCustomBannerInput;
};


export type MutationUpdateGe15StackArgs = {
  id: Scalars['ID'];
  input: UpdateGe15StackInput;
};


export type MutationUpdateKeywordArgs = {
  keywordId: Scalars['ID'];
  data: Update;
};


export type MutationUpdatePinnedNewsArgs = {
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  key: Scalars['String'];
  newsId: Scalars['String'];
};


export type MutationUpdatePublisherArgs = {
  id: Scalars['ID'];
  input: UpdatePublisherInput;
};


export type MutationUpdateRichContentArgs = {
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
  data: Scalars['JSON'];
  publish?: Maybe<Scalars['Boolean']>;
  updatePublishedAt?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateTopicArgs = {
  id: Scalars['ID'];
  input: UpdateTopicInput;
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatePublishedAt?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateTopicFeedArgs = {
  categoryId: Scalars['ID'];
  languageCode: Scalars['ID'];
  data: Scalars['JSON'];
  publish?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateTopicStackArgs = {
  id: Scalars['ID'];
  input: UpdateTopicStackInput;
};


export type MutationUploadAdminAttachmentArgs = {
  attachment: Scalars['File'];
};


export type MutationUploadImageRichContentArgs = {
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
  image: Scalars['File'];
};

export type NewPublisherNotification = {
  __typename?: 'NewPublisherNotification';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  newsPublisher: NewsPublisher;
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  bookmark: Scalars['Boolean'];
  categories: Array<Category>;
  comments: Array<Comment>;
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  good: Scalars['Boolean'];
  hasComments?: Maybe<Scalars['Boolean']>;
  hasImageAttached: Scalars['Boolean'];
  hasNewsContent: Scalars['Boolean'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  like: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  link: Scalars['String'];
  newsContent?: Maybe<NewsContent>;
  newsNotifications: Array<NewsNotification>;
  newsPublisher: NewsPublisher;
  pubDate: Scalars['ISO8601DateTime'];
  shareUrl: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};


export type NewsCommentsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export type NewsContent = {
  __typename?: 'NewsContent';
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type NewsNotification = {
  __typename?: 'NewsNotification';
  createdAt: Scalars['ISO8601DateTime'];
  customNotification: CustomNotification;
  description: Scalars['String'];
  id: Scalars['ID'];
  news: News;
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
};

export type NewsPublisher = {
  __typename?: 'NewsPublisher';
  active: Scalars['Boolean'];
  badges?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  lastCrawlAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastCrawlTrendingAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastSendTrendingNotificationAt?: Maybe<Scalars['ISO8601DateTime']>;
  name: Scalars['String'];
  new: Scalars['Boolean'];
  order: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['String']>;
  rssUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  trendingUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  vectorUrl?: Maybe<Scalars['String']>;
};

export type NewsStackNotification = {
  __typename?: 'NewsStackNotification';
  category: Category;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  titleLocale: Locale;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of NotifyCovidStat */
export type NotifyCovidStatPayload = {
  __typename?: 'NotifyCovidStatPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Attributes for Infinite Scrolling */
export type OffsetAttributes = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of PinNews */
export type PinNewsPayload = {
  __typename?: 'PinNewsPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PinnedNewsArticle = {
  __typename?: 'PinnedNewsArticle';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  language?: Maybe<Language>;
  news?: Maybe<News>;
};

export type Playlist = {
  __typename?: 'Playlist';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  announcements: Array<Announcement>;
  appBanners: Array<AppBanner>;
  appsflyerInvites?: Maybe<Scalars['JSON']>;
  author?: Maybe<Author>;
  campaign?: Maybe<Campaign>;
  carousel: Carousel;
  carousels?: Maybe<Array<Carousel>>;
  categories?: Maybe<Array<Category>>;
  categoriesWithIds?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  comment?: Maybe<Comment>;
  comments?: Maybe<Array<Comment>>;
  covidStat: CovidStat;
  customBanner: CustomBanner;
  customNotifications: Array<CustomNotification>;
  failedRssPublishers?: Maybe<Array<NewsPublisher>>;
  featuredVideo?: Maybe<Array<FeaturedVideo>>;
  ge15FeaturedVideo?: Maybe<Array<FeaturedVideo>>;
  ge15Stacks: Array<Ge15Stack>;
  keyword?: Maybe<Keyword>;
  keywords?: Maybe<Array<Keyword>>;
  languages: Array<Language>;
  me?: Maybe<Admin>;
  newPublisherNotifications?: Maybe<Array<NewPublisherNotification>>;
  news?: Maybe<News>;
  newsNotification?: Maybe<NewsNotification>;
  newsNotifications?: Maybe<Array<NewsNotification>>;
  newsStackNotifications?: Maybe<Array<NewsStackNotification>>;
  newsWithIds?: Maybe<Array<News>>;
  pinnedNewsArticle?: Maybe<PinnedNewsArticle>;
  pinnedNewsArticleById?: Maybe<PinnedNewsArticle>;
  pinnedNewsArticles?: Maybe<Array<PinnedNewsArticle>>;
  playlists: Array<Playlist>;
  publisher?: Maybe<NewsPublisher>;
  publishers?: Maybe<Array<NewsPublisher>>;
  report?: Maybe<Report>;
  reports?: Maybe<Array<Report>>;
  richContent?: Maybe<RichContent>;
  richContentCategories?: Maybe<Array<Category>>;
  topNews?: Maybe<TopNews>;
  topNewsList?: Maybe<Array<TopNews>>;
  topicFeed: TopicFeed;
  topicFeeds: Array<TopicFeed>;
  topicStack?: Maybe<TopicStack>;
  topicStacks: Array<TopicStack>;
  typesearchAlgoliaFlag?: Maybe<TypesenseAlgoliaFlag>;
  user?: Maybe<User>;
  usersWithIds?: Maybe<Array<User>>;
  video?: Maybe<Video>;
  videoNotifications?: Maybe<Array<VideoNotification>>;
  videos?: Maybe<Array<Video>>;
  videosWithIds?: Maybe<Array<Video>>;
};


export type QueryAuthorArgs = {
  id: Scalars['ID'];
};


export type QueryCarouselArgs = {
  id: Scalars['ID'];
};


export type QueryCarouselsArgs = {
  publisherId?: Maybe<Scalars['ID']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryCategoriesArgs = {
  active?: Maybe<Scalars['Boolean']>;
  orderByName?: Maybe<Scalars['Boolean']>;
  withoutStacks?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  explore?: Maybe<Scalars['Boolean']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
  categoryType?: Maybe<Scalars['String']>;
};


export type QueryCategoriesWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommentsArgs = {
  type?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryKeywordArgs = {
  id: Scalars['ID'];
};


export type QueryKeywordsArgs = {
  languageCodes?: Maybe<Array<Scalars['String']>>;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryNewPublisherNotificationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryNewsArgs = {
  id: Scalars['ID'];
};


export type QueryNewsNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNewsNotificationsArgs = {
  customNotificationId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryNewsStackNotificationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryNewsWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPinnedNewsArticleArgs = {
  languageCode: Scalars['String'];
  categoryId: Scalars['ID'];
};


export type QueryPinnedNewsArticleByIdArgs = {
  id: Scalars['ID'];
};


export type QueryPlaylistsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryPublisherArgs = {
  id: Scalars['ID'];
};


export type QueryPublishersArgs = {
  type?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
  orderByName?: Maybe<Scalars['Boolean']>;
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryReportsArgs = {
  type?: Maybe<Scalars['String']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryRichContentArgs = {
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryRichContentCategoriesArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryTopNewsArgs = {
  id: Scalars['ID'];
};


export type QueryTopNewsListArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryTopicFeedArgs = {
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryTopicFeedsArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryTopicStackArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
};


export type QueryUsersWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryVideoArgs = {
  id: Scalars['ID'];
};


export type QueryVideoNotificationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryVideosArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryVideosWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of RemoveCategoryFromStack */
export type RemoveCategoryFromStackPayload = {
  __typename?: 'RemoveCategoryFromStackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RemoveCategoryFromTopicStack */
export type RemoveCategoryFromTopicStackPayload = {
  __typename?: 'RemoveCategoryFromTopicStackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RemoveCategory */
export type RemoveCategoryPayload = {
  __typename?: 'RemoveCategoryPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<News>;
};

/** Autogenerated return type of RemoveFromCarousel */
export type RemoveFromCarouselPayload = {
  __typename?: 'RemoveFromCarouselPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RemoveSuggestedCategoryFromTopic */
export type RemoveSuggestedCategoryFromTopicPayload = {
  __typename?: 'RemoveSuggestedCategoryFromTopicPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RemoveVideoCategory */
export type RemoveVideoCategoryPayload = {
  __typename?: 'RemoveVideoCategoryPayload';
  errors?: Maybe<Array<Error>>;
  video?: Maybe<Video>;
};

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  reportedComment?: Maybe<Comment>;
  reportingUser?: Maybe<User>;
  status: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type RichContent = {
  __typename?: 'RichContent';
  availableLanguages?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['ISO8601DateTime'];
  data: Scalars['JSON'];
  draft: Scalars['JSON'];
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SendAnnouncementNotification */
export type SendAnnouncementNotificationPayload = {
  __typename?: 'SendAnnouncementNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SendNewPublisherNotificationInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  publisherId: Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of SendNewPublisherNotification */
export type SendNewPublisherNotificationPayload = {
  __typename?: 'SendNewPublisherNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SendPublisherNotificationInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  languageIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of SendPublisherNotification */
export type SendPublisherNotificationPayload = {
  __typename?: 'SendPublisherNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SendTrendingTopicNotificationInput = {
  titleLocale: LocaleInput;
  descriptionLocale?: Maybe<LocaleInput>;
  trendingTopicId: Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  highPriority?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SendTrendingTopicNotification */
export type SendTrendingTopicNotificationPayload = {
  __typename?: 'SendTrendingTopicNotificationPayload';
  errors?: Maybe<Array<Error>>;
  newsStackNotification?: Maybe<NewsStackNotification>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SetGoodNews */
export type SetGoodNewsPayload = {
  __typename?: 'SetGoodNewsPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<News>;
};

export type StateCovidStat = {
  __typename?: 'StateCovidStat';
  id: Scalars['ID'];
  name: Scalars['String'];
  newCases?: Maybe<Scalars['Int']>;
  totalCases?: Maybe<Scalars['Int']>;
};

export type SuggestedCategory = {
  __typename?: 'SuggestedCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  targetedCategoryId: Scalars['ID'];
};

export type TopNews = {
  __typename?: 'TopNews';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  metadata?: Maybe<Array<TopNewsMetadata>>;
  news: Array<News>;
  newsIdArray: Array<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TopNewsMetadata = {
  __typename?: 'TopNewsMetadata';
  languageCode?: Maybe<Scalars['String']>;
  newsId?: Maybe<Scalars['ID']>;
  trendingRank?: Maybe<Scalars['String']>;
};

export type TopicFeed = {
  __typename?: 'TopicFeed';
  category: Category;
  data: Scalars['JSON'];
  id: Scalars['ID'];
  lastPublishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TopicStack = {
  __typename?: 'TopicStack';
  categories?: Maybe<Array<Category>>;
  descriptionLocale?: Maybe<Locale>;
  id: Scalars['ID'];
  title: Scalars['String'];
  titleLocale?: Maybe<Locale>;
};

export type TypesenseAlgoliaFlag = {
  __typename?: 'TypesenseAlgoliaFlag';
  typesenseIsOn: Scalars['Boolean'];
};

/** Autogenerated return type of UnfeatureGe15Video */
export type UnfeatureGe15VideoPayload = {
  __typename?: 'UnfeatureGe15VideoPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UnfeatureVideo */
export type UnfeatureVideoPayload = {
  __typename?: 'UnfeatureVideoPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UnpinNews */
export type UnpinNewsPayload = {
  __typename?: 'UnpinNewsPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Update = {
  language: Scalars['String'];
  keywordString: Scalars['String'];
  position: Scalars['Int'];
};

/** Autogenerated return type of UpdateAppBanner */
export type UpdateAppBannerPayload = {
  __typename?: 'UpdateAppBannerPayload';
  appBanner?: Maybe<AppBanner>;
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateAuthorForTopic */
export type UpdateAuthorForTopicPayload = {
  __typename?: 'UpdateAuthorForTopicPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCarouselContentInput = {
  id: Scalars['ID'];
  sponsored?: Maybe<Scalars['Boolean']>;
  contentableId?: Maybe<Scalars['ID']>;
  contentableType?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCarouselContent */
export type UpdateCarouselContentPayload = {
  __typename?: 'UpdateCarouselContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateCategory */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCovidStatInput = {
  cat1: Scalars['Int'];
  cat2: Scalars['Int'];
  cat3: Scalars['Int'];
  cat4: Scalars['Int'];
  cat5: Scalars['Int'];
};

/** Autogenerated return type of UpdateCovidStat */
export type UpdateCovidStatPayload = {
  __typename?: 'UpdateCovidStatPayload';
  covidStat?: Maybe<CovidStat>;
  errors?: Maybe<Array<Error>>;
};

export type UpdateCustomBannerInput = {
  latestTabBanner?: Maybe<LocaleInput>;
  covidTabBanner?: Maybe<LocaleInput>;
  goodNewsBanner?: Maybe<LocaleInput>;
  dailyDosesLabel?: Maybe<LocaleInput>;
  vaccinationGoalLabel?: Maybe<LocaleInput>;
  searchBarHintText?: Maybe<LocaleInput>;
  totalDosesLabel?: Maybe<LocaleInput>;
  oneDoseLabel?: Maybe<LocaleInput>;
  twoDosesLabel?: Maybe<LocaleInput>;
  newCovidCasesLabel?: Maybe<LocaleInput>;
  confirmedCasesLabel?: Maybe<LocaleInput>;
  activeCasesLabel?: Maybe<LocaleInput>;
  recoveredCasesLabel?: Maybe<LocaleInput>;
  fatalitiesCasesLabel?: Maybe<LocaleInput>;
  stateCasesLabel?: Maybe<LocaleInput>;
  customLatestTabBanner?: Maybe<LocaleInput>;
  customLatestTabBannerColor?: Maybe<LocaleInput>;
  customLatestTabBannerLink?: Maybe<LocaleInput>;
  communityBannerLink?: Maybe<LocaleInput>;
  communityBannerImage?: Maybe<LocaleInput>;
  communityBannerTitle?: Maybe<LocaleInput>;
  communityBannerSubtitle?: Maybe<LocaleInput>;
  communityBannerColor?: Maybe<LocaleInput>;
};

/** Autogenerated return type of UpdateCustomBanner */
export type UpdateCustomBannerPayload = {
  __typename?: 'UpdateCustomBannerPayload';
  customBanner?: Maybe<CustomBanner>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of UpdateFeed */
export type UpdateFeedPayload = {
  __typename?: 'UpdateFeedPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateGe15StackInput = {
  title?: Maybe<Scalars['String']>;
  titleLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
  position?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateGe15Stack */
export type UpdateGe15StackPayload = {
  __typename?: 'UpdateGe15StackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateKeyword */
export type UpdateKeywordPayload = {
  __typename?: 'UpdateKeywordPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdatePinnedNews */
export type UpdatePinnedNewsPayload = {
  __typename?: 'UpdatePinnedNewsPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdatePublisherInput = {
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  new?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['File']>;
  icon?: Maybe<Scalars['File']>;
  vector?: Maybe<Scalars['File']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  badges?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePublisher */
export type UpdatePublisherPayload = {
  __typename?: 'UpdatePublisherPayload';
  errors?: Maybe<Array<Error>>;
  publisher?: Maybe<NewsPublisher>;
};

/** Autogenerated return type of UpdateRichContent */
export type UpdateRichContentPayload = {
  __typename?: 'UpdateRichContentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateTopicInput = {
  name?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
  hot?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['Boolean']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  newStatus?: Maybe<Scalars['Boolean']>;
  explore?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['File']>;
  thumbnail?: Maybe<Scalars['File']>;
  nameLocale?: Maybe<LocaleInput>;
  webTitleLocale?: Maybe<LocaleInput>;
  exploreNameLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
  languages?: Maybe<Scalars['JSON']>;
  searchable?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateTopic */
export type UpdateTopicPayload = {
  __typename?: 'UpdateTopicPayload';
  errors?: Maybe<Array<Error>>;
  topic?: Maybe<Category>;
};

export type UpdateTopicStackInput = {
  title?: Maybe<Scalars['String']>;
  titleLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
};

/** Autogenerated return type of UpdateTopicStack */
export type UpdateTopicStackPayload = {
  __typename?: 'UpdateTopicStackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UploadAdminAttachment */
export type UploadAdminAttachmentPayload = {
  __typename?: 'UploadAdminAttachmentPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UploadImageRichContent */
export type UploadImageRichContentPayload = {
  __typename?: 'UploadImageRichContentPayload';
  errors?: Maybe<Array<Error>>;
  imageUrl?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  comments: Array<Comment>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isBannedFromComment: Scalars['Boolean'];
  reportingComments: Array<Comment>;
  username: Scalars['String'];
  uuid: Scalars['String'];
  verified: Scalars['Boolean'];
};


export type UserCommentsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type UserIsBannedFromCommentArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export type Video = {
  __typename?: 'Video';
  categories: Array<Category>;
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  externalId: Scalars['String'];
  hasComments?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isShort?: Maybe<Scalars['Boolean']>;
  isWebViewable?: Maybe<Scalars['Boolean']>;
  liveChatId?: Maybe<Scalars['String']>;
  newsPublisher: NewsPublisher;
  orientation?: Maybe<Scalars['String']>;
  pubDate: Scalars['ISO8601DateTime'];
  shareUrl: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  videoNotifications: Array<VideoNotification>;
};


export type VideoCommentsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export type VideoNotification = {
  __typename?: 'VideoNotification';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
  video: Video;
};

export type CommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'createdAt' | 'content' | 'likesCount' | 'hasReports' | 'commentableType' | 'commentableId' | 'hasReplies' | 'deletedAt'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ), replyParentComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'commentableType' | 'commentableId' | 'content' | 'deletedAt'>
  )> }
);

export type CovidStatFragment = (
  { __typename?: 'CovidStat' }
  & Pick<CovidStat, 'id' | 'newCases' | 'activeCases' | 'recovered' | 'deaths' | 'totalCases' | 'totalRecovered' | 'totalDeaths' | 'todayActive' | 'date' | 'updatedAt' | 'cat1' | 'cat2' | 'cat3' | 'cat4' | 'cat5'>
  & { stateData?: Maybe<Array<(
    { __typename?: 'StateCovidStat' }
    & Pick<StateCovidStat, 'id' | 'name' | 'newCases' | 'totalCases'>
  )>> }
);

export type CustomBannerFragment = (
  { __typename?: 'CustomBanner' }
  & Pick<CustomBanner, 'id'>
  & { latestTabBanner?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, covidTabBanner?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, goodNewsBanner?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, dailyDosesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, vaccinationGoalLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, totalDosesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, oneDoseLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, twoDosesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, newCovidCasesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, confirmedCasesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, activeCasesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, recoveredCasesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, fatalitiesCasesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, stateCasesLabel?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, searchBarHintText?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, customLatestTabBanner?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, customLatestTabBannerColor?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, customLatestTabBannerLink?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, communityBannerColor?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, communityBannerImage?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, communityBannerLink?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, communityBannerSubtitle?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, communityBannerTitle?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )> }
);

export type LocaleFragment = (
  { __typename?: 'Locale' }
  & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
);

export type CustomNotificationFragment = (
  { __typename?: 'CustomNotification' }
  & Pick<CustomNotification, 'id' | 'name'>
);

export type ErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'detail'>
);

export type FeaturedVideoFragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'id' | 'createdAt' | 'description' | 'externalId' | 'pubDate' | 'title'>
  & { newsPublisher: (
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name'>
  ) }
);

export type NewsNotificationFragment = (
  { __typename?: 'NewsNotification' }
  & Pick<NewsNotification, 'id' | 'title' | 'sendAt' | 'description' | 'createdAt'>
  & { news: (
    { __typename?: 'News' }
    & Pick<News, 'id'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>, newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ) }
  ), customNotification: (
    { __typename?: 'CustomNotification' }
    & CustomNotificationFragment
  ) }
);

export type PublisherFragment = (
  { __typename?: 'NewsPublisher' }
  & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'order' | 'imageUrl' | 'iconUrl' | 'vectorUrl' | 'description' | 'email' | 'phoneNumber' | 'active' | 'url' | 'badges' | 'lastCrawlAt' | 'lastCrawlTrendingAt' | 'lastSendTrendingNotificationAt' | 'rssUrl' | 'trendingUrl'>
  & { newPublisher: NewsPublisher['new'] }
);

export type ReplyFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'createdAt' | 'content' | 'likesCount' | 'hasReports' | 'commentableType' | 'commentableId'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type ReportFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'reason' | 'createdAt' | 'status'>
  & { reportingUser?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )>, reportedComment?: Maybe<(
    { __typename?: 'Comment' }
    & CommentFragment
  )> }
);

export type TopicFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'slug' | 'emoji' | 'order' | 'show' | 'imageUrl' | 'explore' | 'newStatus' | 'hot' | 'updated' | 'exclusive' | 'hasThumbnail' | 'thumbnailUrl' | 'lastEditedAt' | 'lastPublishedAt' | 'updatedAt' | 'searchable'>
  & { nameLocale?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, exploreNameLocale?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, webTitleLocale?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )>, descriptionLocale?: Maybe<(
    { __typename?: 'Locale' }
    & LocaleFragment
  )> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'username' | 'uuid' | 'isBannedFromComment'>
);

export type VideoSummaryFragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'id' | 'title'>
);

export type VideoNotificationFragment = (
  { __typename?: 'VideoNotification' }
  & Pick<VideoNotification, 'id' | 'title' | 'sendAt' | 'description' | 'createdAt'>
);

export type AuthorDetailsFragment = (
  { __typename?: 'Author' }
  & Pick<Author, 'id' | 'imageUrl' | 'authorType' | 'verified' | 'path'>
  & { nameLocale: (
    { __typename?: 'Locale' }
    & LocaleFragment
  ) }
);

export type DeleteAdminAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['ID'];
}>;


export type DeleteAdminAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdminAttachment?: Maybe<(
    { __typename?: 'DeleteAdminAttachmentPayload' }
    & Pick<DeleteAdminAttachmentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UploadAdminAttachmentMutationVariables = Exact<{
  attachment: Scalars['File'];
}>;


export type UploadAdminAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { uploadAdminAttachment?: Maybe<(
    { __typename?: 'UploadAdminAttachmentPayload' }
    & Pick<UploadAdminAttachmentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateAppBannerMutationVariables = Exact<{
  key: Scalars['String'];
  input: AppBannerInput;
}>;


export type UpdateAppBannerMutation = (
  { __typename?: 'Mutation' }
  & { updateAppBanner?: Maybe<(
    { __typename?: 'UpdateAppBannerPayload' }
    & Pick<UpdateAppBannerPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>>, appBanner?: Maybe<(
      { __typename: 'AppBanner' }
      & Pick<AppBanner, 'id' | 'key' | 'updatedAt' | 'image' | 'icon' | 'path' | 'dismissable' | 'buttonText' | 'backgroundColorLight' | 'backgroundColorDark' | 'buttonBackgroundColorLight' | 'buttonBackgroundColorDark' | 'buttonTextColorLight' | 'buttonTextColorDark'>
      & { title?: Maybe<(
        { __typename?: 'Locale' }
        & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
      )>, description?: Maybe<(
        { __typename?: 'Locale' }
        & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
      )>, pathLocale?: Maybe<(
        { __typename?: 'Locale' }
        & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
      )>, buttonTextLocale?: Maybe<(
        { __typename?: 'Locale' }
        & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
      )> }
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & { me?: Maybe<(
      { __typename?: 'Admin' }
      & Pick<Admin, 'id' | 'email'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutPayload' }
    & Pick<LogoutPayload, 'success'>
  )> }
);

export type UpdateAuthorForTopicMutationVariables = Exact<{
  authorId: Scalars['ID'];
  nameLocale: LocaleInput;
  image?: Maybe<Scalars['File']>;
  authorType: Scalars['String'];
  verified?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['String']>;
}>;


export type UpdateAuthorForTopicMutation = (
  { __typename?: 'Mutation' }
  & { updateAuthorForTopic?: Maybe<(
    { __typename?: 'UpdateAuthorForTopicPayload' }
    & Pick<UpdateAuthorForTopicPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateCustomBannerMutationVariables = Exact<{
  latestTabBanner?: Maybe<LocaleInput>;
  covidTabBanner?: Maybe<LocaleInput>;
  goodNewsBanner?: Maybe<LocaleInput>;
  dailyDosesLabel?: Maybe<LocaleInput>;
  vaccinationGoalLabel?: Maybe<LocaleInput>;
  totalDosesLabel?: Maybe<LocaleInput>;
  oneDoseLabel?: Maybe<LocaleInput>;
  twoDosesLabel?: Maybe<LocaleInput>;
  newCovidCasesLabel?: Maybe<LocaleInput>;
  confirmedCasesLabel?: Maybe<LocaleInput>;
  activeCasesLabel?: Maybe<LocaleInput>;
  recoveredCasesLabel?: Maybe<LocaleInput>;
  fatalitiesCasesLabel?: Maybe<LocaleInput>;
  stateCasesLabel?: Maybe<LocaleInput>;
  searchBarHintText?: Maybe<LocaleInput>;
  customLatestTabBanner?: Maybe<LocaleInput>;
  customLatestTabBannerColor?: Maybe<LocaleInput>;
  customLatestTabBannerLink?: Maybe<LocaleInput>;
  communityBannerColor?: Maybe<LocaleInput>;
  communityBannerImage?: Maybe<LocaleInput>;
  communityBannerLink?: Maybe<LocaleInput>;
  communityBannerSubtitle?: Maybe<LocaleInput>;
  communityBannerTitle?: Maybe<LocaleInput>;
}>;


export type UpdateCustomBannerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomBanner?: Maybe<(
    { __typename?: 'UpdateCustomBannerPayload' }
    & { customBanner?: Maybe<(
      { __typename?: 'CustomBanner' }
      & CustomBannerFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type EditCampaignMutationVariables = Exact<{
  id: Scalars['ID'];
  title: LocaleInput;
  subtitle: LocaleInput;
  bannerTitle: LocaleInput;
  bannerSubtitle: LocaleInput;
  description: LocaleInput;
  image?: Maybe<Scalars['File']>;
  bannerImage?: Maybe<Scalars['File']>;
}>;


export type EditCampaignMutation = (
  { __typename?: 'Mutation' }
  & { editCampaign?: Maybe<(
    { __typename?: 'EditCampaignPayload' }
    & Pick<EditCampaignPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateCarouselContentMutationVariables = Exact<{
  id: Scalars['ID'];
  sponsored?: Maybe<Scalars['Boolean']>;
  contentableId?: Maybe<Scalars['ID']>;
  contentableType?: Maybe<Scalars['String']>;
}>;


export type UpdateCarouselContentMutation = (
  { __typename?: 'Mutation' }
  & { updateCarouselContent?: Maybe<(
    { __typename?: 'UpdateCarouselContentPayload' }
    & Pick<UpdateCarouselContentPayload, 'success'>
  )> }
);

export type AddToCarouselMutationVariables = Exact<{
  id: Scalars['ID'];
  contentableId: Scalars['ID'];
  contentableType: Scalars['String'];
}>;


export type AddToCarouselMutation = (
  { __typename?: 'Mutation' }
  & { addToCarousel?: Maybe<(
    { __typename?: 'AddToCarouselPayload' }
    & Pick<AddToCarouselPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'detail' | 'path' | 'payload' | 'type'>
    )>> }
  )> }
);

export type AddContentBulkMutationVariables = Exact<{
  carouselIds: Array<Scalars['ID']> | Scalars['ID'];
  carouselContents: Array<CarouselContentInput> | CarouselContentInput;
}>;


export type AddContentBulkMutation = (
  { __typename?: 'Mutation' }
  & { addContentBulk?: Maybe<(
    { __typename?: 'AddContentBulkPayload' }
    & Pick<AddContentBulkPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'detail' | 'path' | 'payload' | 'type'>
    )>> }
  )> }
);

export type ClearContentMutationVariables = Exact<{
  carouselId: Scalars['ID'];
}>;


export type ClearContentMutation = (
  { __typename?: 'Mutation' }
  & { clearContent?: Maybe<(
    { __typename?: 'ClearContentPayload' }
    & Pick<ClearContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'detail' | 'path' | 'payload' | 'type'>
    )>> }
  )> }
);

export type ClearContentBulkMutationVariables = Exact<{
  carouselIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ClearContentBulkMutation = (
  { __typename?: 'Mutation' }
  & { clearContentBulk?: Maybe<(
    { __typename?: 'ClearContentBulkPayload' }
    & Pick<ClearContentBulkPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'detail' | 'path' | 'payload' | 'type'>
    )>> }
  )> }
);

export type CreateCarouselMutationVariables = Exact<{
  categoryIds: Array<Scalars['ID']> | Scalars['ID'];
  newsPublisherId: Scalars['ID'];
  carouselContents?: Maybe<Array<CarouselContentInput> | CarouselContentInput>;
}>;


export type CreateCarouselMutation = (
  { __typename?: 'Mutation' }
  & { createCarousel?: Maybe<(
    { __typename?: 'CreatePayload' }
    & Pick<CreatePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'detail' | 'path' | 'payload' | 'type'>
    )>> }
  )> }
);

export type CreateCarouselBulkMutationVariables = Exact<{
  categoryIds: Array<Scalars['ID']> | Scalars['ID'];
  newsPublisherIds: Array<Scalars['ID']> | Scalars['ID'];
  carouselContents?: Maybe<Array<CarouselContentInput> | CarouselContentInput>;
}>;


export type CreateCarouselBulkMutation = (
  { __typename?: 'Mutation' }
  & { createCarouselBulk?: Maybe<(
    { __typename?: 'CreateCarouselBulkPayload' }
    & Pick<CreateCarouselBulkPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'detail' | 'path' | 'payload' | 'type'>
    )>> }
  )> }
);

export type DeleteCarouselMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCarouselMutation = (
  { __typename?: 'Mutation' }
  & { deleteCarousel?: Maybe<(
    { __typename?: 'DeleteCarouselPayload' }
    & Pick<DeleteCarouselPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'detail' | 'path' | 'payload' | 'type'>
    )>> }
  )> }
);

export type RemoveFromCarouselMutationVariables = Exact<{
  id: Scalars['ID'];
  contentableId: Scalars['ID'];
  contentableType: Scalars['String'];
}>;


export type RemoveFromCarouselMutation = (
  { __typename?: 'Mutation' }
  & { removeFromCarousel?: Maybe<(
    { __typename?: 'RemoveFromCarouselPayload' }
    & Pick<RemoveFromCarouselPayload, 'success'>
  )> }
);

export type DeleteCommentMutationVariables = Exact<{
  commentIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment?: Maybe<(
    { __typename?: 'DeleteCommentPayload' }
    & Pick<DeleteCommentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type NotifyCovidStatMutationVariables = Exact<{ [key: string]: never; }>;


export type NotifyCovidStatMutation = (
  { __typename?: 'Mutation' }
  & { notifyCovidStat?: Maybe<(
    { __typename?: 'NotifyCovidStatPayload' }
    & Pick<NotifyCovidStatPayload, 'success'>
  )> }
);

export type UpdateCovidStatMutationVariables = Exact<{
  cat1: Scalars['Int'];
  cat2: Scalars['Int'];
  cat3: Scalars['Int'];
  cat4: Scalars['Int'];
  cat5: Scalars['Int'];
}>;


export type UpdateCovidStatMutation = (
  { __typename?: 'Mutation' }
  & { updateCovidStat?: Maybe<(
    { __typename?: 'UpdateCovidStatPayload' }
    & { covidStat?: Maybe<(
      { __typename?: 'CovidStat' }
      & CovidStatFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateKeywordMutationVariables = Exact<{
  language: Scalars['String'];
  keywordString: Scalars['String'];
}>;


export type CreateKeywordMutation = (
  { __typename?: 'Mutation' }
  & { createKeyword?: Maybe<(
    { __typename?: 'CreateKeywordPayload' }
    & Pick<CreateKeywordPayload, 'success'>
  )> }
);

export type UpdateKeywordMutationVariables = Exact<{
  keywordId: Scalars['ID'];
  position: Scalars['Int'];
  language: Scalars['String'];
  keywordString: Scalars['String'];
}>;


export type UpdateKeywordMutation = (
  { __typename?: 'Mutation' }
  & { updateKeyword?: Maybe<(
    { __typename?: 'UpdateKeywordPayload' }
    & Pick<UpdateKeywordPayload, 'success'>
  )> }
);

export type DeleteNewPublisherNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNewPublisherNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNewPublisherNotification?: Maybe<(
    { __typename?: 'DeleteNewPublisherNotificationPayload' }
    & Pick<DeleteNewPublisherNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AddCategoryMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  categoryName: Scalars['String'];
}>;


export type AddCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addCategory?: Maybe<(
    { __typename?: 'AddCategoryPayload' }
    & { news?: Maybe<Array<(
      { __typename?: 'News' }
      & Pick<News, 'id'>
      & { categories: Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type AddExtraCovidCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AddExtraCovidCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addExtraCovidCategory?: Maybe<(
    { __typename?: 'AddExtraCovidCategoryPayload' }
    & { news?: Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id'>
      & { categories: Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CrawlNewsMutationVariables = Exact<{
  originalUrl: Scalars['String'];
  newsPublisherId: Scalars['ID'];
  categoryNames: Array<Scalars['String']> | Scalars['String'];
}>;


export type CrawlNewsMutation = (
  { __typename?: 'Mutation' }
  & { crawlNews?: Maybe<(
    { __typename?: 'CrawlNewsPayload' }
    & { news?: Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'title'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CrawlNewsContentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CrawlNewsContentMutation = (
  { __typename?: 'Mutation' }
  & { crawlNewsContent?: Maybe<(
    { __typename?: 'CrawlNewsContentPayload' }
    & Pick<CrawlNewsContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DecrawlNewsContentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DecrawlNewsContentMutation = (
  { __typename?: 'Mutation' }
  & { decrawlNewsContent?: Maybe<(
    { __typename?: 'DecrawlNewsContentPayload' }
    & Pick<DecrawlNewsContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteNewsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNewsMutation = (
  { __typename?: 'Mutation' }
  & { deleteNews?: Maybe<(
    { __typename?: 'DeleteNewsPayload' }
    & { news?: Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type EditNewsMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  readerMode?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
}>;


export type EditNewsMutation = (
  { __typename?: 'Mutation' }
  & { editNews?: Maybe<(
    { __typename?: 'EditNewsPayload' }
    & Pick<EditNewsPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type FetchImageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchImageMutation = (
  { __typename?: 'Mutation' }
  & { fetchImage?: Maybe<(
    { __typename?: 'FetchImagePayload' }
    & Pick<FetchImagePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type PinNewsMutationVariables = Exact<{
  id: Scalars['ID'];
  languageCode: Scalars['String'];
}>;


export type PinNewsMutation = (
  { __typename?: 'Mutation' }
  & { pinNews?: Maybe<(
    { __typename?: 'PinNewsPayload' }
    & Pick<PinNewsPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  categoryName: Scalars['String'];
}>;


export type RemoveCategoryMutation = (
  { __typename?: 'Mutation' }
  & { removeCategory?: Maybe<(
    { __typename?: 'RemoveCategoryPayload' }
    & { news?: Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id'>
      & { categories: Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SetGoodNewsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetGoodNewsMutation = (
  { __typename?: 'Mutation' }
  & { setGoodNews?: Maybe<(
    { __typename?: 'SetGoodNewsPayload' }
    & { news?: Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'good'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UnpinNewsMutationVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type UnpinNewsMutation = (
  { __typename?: 'Mutation' }
  & { unpinNews?: Maybe<(
    { __typename?: 'UnpinNewsPayload' }
    & Pick<UnpinNewsPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateNewsNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  newsId: Scalars['ID'];
  customNotificationIds: Array<Scalars['ID']> | Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  highPriority?: Maybe<Scalars['Boolean']>;
}>;


export type CreateNewsNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createNewsNotification?: Maybe<(
    { __typename?: 'CreateNewsNotificationPayload' }
    & { newsNotifications?: Maybe<Array<(
      { __typename?: 'NewsNotification' }
      & NewsNotificationFragment
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteNewsNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNewsNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNewsNotification?: Maybe<(
    { __typename?: 'DeleteNewsNotificationPayload' }
    & Pick<DeleteNewsNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePrn2023ContentNotificationMutationVariables = Exact<{
  contentId: Scalars['ID'];
  contentType: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  states: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreatePrn2023ContentNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createPrn2023ContentNotification?: Maybe<(
    { __typename?: 'CreatePrn2023ContentNotificationPayload' }
    & Pick<CreatePrn2023ContentNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePrn2023GenericNotificationMutationVariables = Exact<{
  titleLocale: LocaleInput;
  descriptionLocale: LocaleInput;
  path: Scalars['String'];
  states: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreatePrn2023GenericNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createPrn2023GenericNotification?: Maybe<(
    { __typename?: 'CreatePrn2023GenericNotificationPayload' }
    & Pick<CreatePrn2023GenericNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePrn2023NewsStackNotificationMutationVariables = Exact<{
  titleLocale: LocaleInput;
  descriptionLocale: LocaleInput;
  trendingTopicId: Scalars['ID'];
  states: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreatePrn2023NewsStackNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createPrn2023NewsStackNotification?: Maybe<(
    { __typename?: 'CreatePrn2023NewsStackNotificationPayload' }
    & Pick<CreatePrn2023NewsStackNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type SendAnnouncementNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  path: Scalars['String'];
  languageCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  targetNonPrn2023NotiPreference?: Maybe<Scalars['Boolean']>;
  targetChatNoti?: Maybe<Scalars['Boolean']>;
}>;


export type SendAnnouncementNotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendAnnouncementNotification?: Maybe<(
    { __typename?: 'SendAnnouncementNotificationPayload' }
    & Pick<SendAnnouncementNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SendNewPublisherNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  publisherId: Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type SendNewPublisherNotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendNewPublisherNotification?: Maybe<(
    { __typename?: 'SendNewPublisherNotificationPayload' }
    & Pick<SendNewPublisherNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SendPublisherNotificationMutationVariables = Exact<{
  languageIds: Array<Scalars['ID']> | Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type SendPublisherNotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendPublisherNotification?: Maybe<(
    { __typename?: 'SendPublisherNotificationPayload' }
    & Pick<SendPublisherNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdatePublisherMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  new?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['File']>;
  vector?: Maybe<Scalars['File']>;
  icon?: Maybe<Scalars['File']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  badges?: Maybe<Scalars['String']>;
}>;


export type UpdatePublisherMutation = (
  { __typename?: 'Mutation' }
  & { updatePublisher?: Maybe<(
    { __typename?: 'UpdatePublisherPayload' }
    & { publisher?: Maybe<(
      { __typename?: 'NewsPublisher' }
      & PublisherFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type EditReportMutationVariables = Exact<{
  reportIds: Array<Scalars['ID']> | Scalars['ID'];
  type: Scalars['String'];
}>;


export type EditReportMutation = (
  { __typename?: 'Mutation' }
  & { editReport?: Maybe<(
    { __typename?: 'EditReportPayload' }
    & Pick<EditReportPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CopyBlockToLangRichContentMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCodes: Array<Scalars['String']> | Scalars['String'];
  blocks: Array<Scalars['JSON']> | Scalars['JSON'];
}>;


export type CopyBlockToLangRichContentMutation = (
  { __typename?: 'Mutation' }
  & { copyBlockToLangRichContent?: Maybe<(
    { __typename?: 'CopyBlockToLangRichContentPayload' }
    & Pick<CopyBlockToLangRichContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateRichStacksMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['ID']>;
}>;


export type CreateRichStacksMutation = (
  { __typename?: 'Mutation' }
  & { createRichContent?: Maybe<(
    { __typename?: 'CreateRichContentPayload' }
    & Pick<CreateRichContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type DeleteRichContentMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCode: Scalars['ID'];
}>;


export type DeleteRichContentMutation = (
  { __typename?: 'Mutation' }
  & { deleteRichContent?: Maybe<(
    { __typename?: 'DeleteRichContentPayload' }
    & Pick<DeleteRichContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type DuplicateRichStacksMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
  fromLanguageCode: Scalars['String'];
  languageCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DuplicateRichStacksMutation = (
  { __typename?: 'Mutation' }
  & { duplicateRichContent?: Maybe<(
    { __typename?: 'DuplicateRichContentPayload' }
    & Pick<DuplicateRichContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateRichStacksMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  data: Scalars['JSON'];
  publish?: Maybe<Scalars['Boolean']>;
  languageCode?: Maybe<Scalars['String']>;
  updatePublishedAt?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateRichStacksMutation = (
  { __typename?: 'Mutation' }
  & { updateRichContent?: Maybe<(
    { __typename?: 'UpdateRichContentPayload' }
    & Pick<UpdateRichContentPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UploadImageRichStacksMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  image: Scalars['File'];
  languageCode?: Maybe<Scalars['String']>;
}>;


export type UploadImageRichStacksMutation = (
  { __typename?: 'Mutation' }
  & { uploadImageRichContent?: Maybe<(
    { __typename?: 'UploadImageRichContentPayload' }
    & Pick<UploadImageRichContentPayload, 'success' | 'imageUrl'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type AddSuggestedCategoriesToTopicMutationVariables = Exact<{
  id: Scalars['ID'];
  suggestedCategories: Array<AddSuggestedCategoriesToTopicInput> | AddSuggestedCategoriesToTopicInput;
}>;


export type AddSuggestedCategoriesToTopicMutation = (
  { __typename?: 'Mutation' }
  & { addSuggestedCategoriesToTopic?: Maybe<(
    { __typename?: 'AddSuggestedCategoriesToTopicPayload' }
    & Pick<AddSuggestedCategoriesToTopicPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateAuthorForTopicMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  nameLocale: LocaleInput;
  image?: Maybe<Scalars['File']>;
  authorType: Scalars['String'];
  verified?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['String']>;
}>;


export type CreateAuthorForTopicMutation = (
  { __typename?: 'Mutation' }
  & { createAuthorForTopic?: Maybe<(
    { __typename?: 'CreateAuthorForTopicPayload' }
    & Pick<CreateAuthorForTopicPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateTopicMutationVariables = Exact<{
  name: Scalars['String'];
  emoji?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
  newStatus?: Maybe<Scalars['Boolean']>;
  hot?: Maybe<Scalars['Boolean']>;
  explore?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['File']>;
  thumbnail?: Maybe<Scalars['File']>;
  nameLocale?: Maybe<LocaleInput>;
  exploreNameLocale?: Maybe<LocaleInput>;
  webTitleLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
  isNewsStack?: Maybe<Scalars['Boolean']>;
}>;


export type CreateTopicMutation = (
  { __typename?: 'Mutation' }
  & { createTopic?: Maybe<(
    { __typename?: 'CreateTopicPayload' }
    & { topic?: Maybe<(
      { __typename?: 'Category' }
      & TopicFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateTopicFeedMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCode: Scalars['ID'];
}>;


export type CreateTopicFeedMutation = (
  { __typename?: 'Mutation' }
  & { createTopicFeed?: Maybe<(
    { __typename?: 'CreateFeedPayload' }
    & Pick<CreateFeedPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteTopicMutationVariables = Exact<{
  topicId: Scalars['ID'];
}>;


export type DeleteTopicMutation = (
  { __typename?: 'Mutation' }
  & { deleteTopic?: Maybe<(
    { __typename?: 'DeleteTopicPayload' }
    & Pick<DeleteTopicPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteTopicThumbnailMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTopicThumbnailMutation = (
  { __typename?: 'Mutation' }
  & { deleteTopicThumbnail?: Maybe<(
    { __typename?: 'DeleteTopicThumbnailPayload' }
    & Pick<DeleteTopicThumbnailPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type DeleteTrendingTopicNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTrendingTopicNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteTrendingTopicNotification?: Maybe<(
    { __typename?: 'DeleteTrendingTopicNotificationPayload' }
    & Pick<DeleteTrendingTopicNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveSuggestedCategoryFromTopicMutationVariables = Exact<{
  id: Scalars['ID'];
  suggestedCategoryId: Scalars['ID'];
}>;


export type RemoveSuggestedCategoryFromTopicMutation = (
  { __typename?: 'Mutation' }
  & { removeSuggestedCategoryFromTopic?: Maybe<(
    { __typename?: 'RemoveSuggestedCategoryFromTopicPayload' }
    & Pick<RemoveSuggestedCategoryFromTopicPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SendTrendingTopicNotificationMutationVariables = Exact<{
  trendingTopicId: Scalars['ID'];
  titleLocale: LocaleInput;
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
  descriptionLocale?: Maybe<LocaleInput>;
  highPriority?: Maybe<Scalars['Boolean']>;
}>;


export type SendTrendingTopicNotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendTrendingTopicNotification?: Maybe<(
    { __typename?: 'SendTrendingTopicNotificationPayload' }
    & { newsStackNotification?: Maybe<(
      { __typename?: 'NewsStackNotification' }
      & Pick<NewsStackNotification, 'id' | 'createdAt' | 'sendAt'>
      & { titleLocale: (
        { __typename?: 'Locale' }
        & LocaleFragment
      ), category: (
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateTopicMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
  newStatus?: Maybe<Scalars['Boolean']>;
  hot?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['Boolean']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  explore?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['File']>;
  thumbnail?: Maybe<Scalars['File']>;
  nameLocale?: Maybe<LocaleInput>;
  exploreNameLocale?: Maybe<LocaleInput>;
  webTitleLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
  languages?: Maybe<Scalars['JSON']>;
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatePublishedAt?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateTopicMutation = (
  { __typename?: 'Mutation' }
  & { updateTopic?: Maybe<(
    { __typename?: 'UpdateTopicPayload' }
    & { topic?: Maybe<(
      { __typename?: 'Category' }
      & TopicFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateTopicFeedMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCode: Scalars['ID'];
  data: Scalars['JSON'];
  publish?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateTopicFeedMutation = (
  { __typename?: 'Mutation' }
  & { updateTopicFeed?: Maybe<(
    { __typename?: 'UpdateFeedPayload' }
    & Pick<UpdateFeedPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AddCategoryToTopicStackMutationVariables = Exact<{
  id: Scalars['ID'];
  categoryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AddCategoryToTopicStackMutation = (
  { __typename?: 'Mutation' }
  & { addCategoryToTopicStack?: Maybe<(
    { __typename?: 'AddCategoryToTopicStackPayload' }
    & Pick<AddCategoryToTopicStackPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateTopicStackMutationVariables = Exact<{
  title: Scalars['String'];
  titleLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
}>;


export type CreateTopicStackMutation = (
  { __typename?: 'Mutation' }
  & { createTopicStack?: Maybe<(
    { __typename?: 'CreateTopicStackPayload' }
    & Pick<CreateTopicStackPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveCategoryFromTopicStackMutationVariables = Exact<{
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
}>;


export type RemoveCategoryFromTopicStackMutation = (
  { __typename?: 'Mutation' }
  & { removeCategoryFromTopicStack?: Maybe<(
    { __typename?: 'RemoveCategoryFromTopicStackPayload' }
    & Pick<RemoveCategoryFromTopicStackPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateTopicStackMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleLocale?: Maybe<LocaleInput>;
  descriptionLocale?: Maybe<LocaleInput>;
}>;


export type UpdateTopicStackMutation = (
  { __typename?: 'Mutation' }
  & { updateTopicStack?: Maybe<(
    { __typename?: 'UpdateTopicStackPayload' }
    & Pick<UpdateTopicStackPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type BanUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  isBan: Scalars['Boolean'];
}>;


export type BanUserMutation = (
  { __typename?: 'Mutation' }
  & { banUser?: Maybe<(
    { __typename?: 'BanUserPayload' }
    & Pick<BanUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'DeleteUserPayload' }
    & Pick<DeleteUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AddChatroomToVideoMutationVariables = Exact<{
  id: Scalars['ID'];
  live: Scalars['Boolean'];
}>;


export type AddChatroomToVideoMutation = (
  { __typename?: 'Mutation' }
  & { addChatroomToVideo?: Maybe<(
    { __typename?: 'AddChatroomToVideoPayload' }
    & Pick<AddChatroomToVideoPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AddFeaturedVideoMutationVariables = Exact<{
  id: Scalars['ID'];
  languageCode: Scalars['String'];
}>;


export type AddFeaturedVideoMutation = (
  { __typename?: 'Mutation' }
  & { featureVideo?: Maybe<(
    { __typename?: 'FeatureVideoPayload' }
    & { video?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type AddFeaturedGe15VideoMutationVariables = Exact<{
  id: Scalars['ID'];
  languageCode: Scalars['String'];
}>;


export type AddFeaturedGe15VideoMutation = (
  { __typename?: 'Mutation' }
  & { featureGe15Video?: Maybe<(
    { __typename?: 'FeatureGe15VideoPayload' }
    & { video?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type AddVideoCategoryMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  categoryName: Scalars['String'];
}>;


export type AddVideoCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addVideoCategory?: Maybe<(
    { __typename?: 'AddVideoCategoryPayload' }
    & { videos?: Maybe<Array<(
      { __typename?: 'Video' }
      & Pick<Video, 'id'>
      & { categories: Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CrawlYoutubeVideoMutationVariables = Exact<{
  link: Scalars['String'];
  publisherId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isWebViewable?: Maybe<Scalars['Boolean']>;
}>;


export type CrawlYoutubeVideoMutation = (
  { __typename?: 'Mutation' }
  & { crawlYoutubeVideo?: Maybe<(
    { __typename?: 'CrawlYoutubeVideoPayload' }
    & Pick<CrawlYoutubeVideoPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteFeaturedVideoMutationVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type DeleteFeaturedVideoMutation = (
  { __typename?: 'Mutation' }
  & { unfeatureVideo?: Maybe<(
    { __typename?: 'UnfeatureVideoPayload' }
    & Pick<UnfeatureVideoPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type DeleteVideoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteVideoMutation = (
  { __typename?: 'Mutation' }
  & { deleteVideo?: Maybe<(
    { __typename?: 'DeleteVideoPayload' }
    & { video?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteVideoNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteVideoNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteVideoNotification?: Maybe<(
    { __typename?: 'DeleteVideoNotificationPayload' }
    & Pick<DeleteVideoNotificationPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type EditVideoMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  orientation?: Maybe<Scalars['String']>;
  isWebViewable?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  liveChatId?: Maybe<Scalars['String']>;
}>;


export type EditVideoMutation = (
  { __typename?: 'Mutation' }
  & { editVideo?: Maybe<(
    { __typename?: 'EditVideoPayload' }
    & Pick<EditVideoPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveFeaturedVideoMutationVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type RemoveFeaturedVideoMutation = (
  { __typename?: 'Mutation' }
  & { unfeatureVideo?: Maybe<(
    { __typename?: 'UnfeatureVideoPayload' }
    & Pick<UnfeatureVideoPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type RemoveFeaturedGe15VideoMutationVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type RemoveFeaturedGe15VideoMutation = (
  { __typename?: 'Mutation' }
  & { unfeatureGe15Video?: Maybe<(
    { __typename?: 'UnfeatureGe15VideoPayload' }
    & Pick<UnfeatureGe15VideoPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type RemoveVideoCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  categoryName: Scalars['String'];
}>;


export type RemoveVideoCategoryMutation = (
  { __typename?: 'Mutation' }
  & { removeVideoCategory?: Maybe<(
    { __typename?: 'RemoveVideoCategoryPayload' }
    & { video?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'id'>
      & { categories: Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateVideoNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  videoId: Scalars['ID'];
  sendAt?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type CreateVideoNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createVideoNotification?: Maybe<(
    { __typename?: 'CreateVideoNotificationPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AppBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type AppBannersQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, appBanners: Array<(
    { __typename: 'AppBanner' }
    & Pick<AppBanner, 'id' | 'key' | 'updatedAt' | 'image' | 'icon' | 'path' | 'dismissable' | 'buttonText' | 'backgroundColorLight' | 'backgroundColorDark' | 'buttonBackgroundColorLight' | 'buttonBackgroundColorDark' | 'buttonTextColorLight' | 'buttonTextColorDark'>
    & { title?: Maybe<(
      { __typename?: 'Locale' }
      & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
    )>, description?: Maybe<(
      { __typename?: 'Locale' }
      & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
    )>, pathLocale?: Maybe<(
      { __typename?: 'Locale' }
      & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
    )>, buttonTextLocale?: Maybe<(
      { __typename?: 'Locale' }
      & Pick<Locale, 'id' | 'en' | 'ms' | 'zh'>
    )> }
  )> }
);

export type AuthorPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AuthorPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, author?: Maybe<(
    { __typename?: 'Author' }
    & AuthorDetailsFragment
  )> }
);

export type CustomBannerWithPublishersQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomBannerWithPublishersQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, customBanner: (
    { __typename?: 'CustomBanner' }
    & CustomBannerFragment
  ), publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name'>
  )>> }
);

export type CustomBannerQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomBannerQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, customBanner: (
    { __typename?: 'CustomBanner' }
    & CustomBannerFragment
  ) }
);

export type CampaignPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CampaignPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, campaign?: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'imageUrl' | 'bannerImageUrl'>
    & { title?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )>, subtitle?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )>, bannerTitle?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )>, bannerSubtitle?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )>, description?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )> }
  )> }
);

export type CampaignInstallsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CampaignInstallsPageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'appsflyerInvites'>
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )> }
);

export type CarouselQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CarouselQuery = (
  { __typename?: 'Query' }
  & { carousel: (
    { __typename?: 'Carousel' }
    & Pick<Carousel, 'categories' | 'createdAt' | 'id'>
    & { carouselContents?: Maybe<Array<(
      { __typename?: 'CarouselContent' }
      & Pick<CarouselContent, 'id' | 'sponsored'>
      & { contentable?: Maybe<(
        { __typename: 'Category' }
        & Pick<Category, 'id' | 'name'>
      ) | (
        { __typename: 'News' }
        & Pick<News, 'id' | 'title'>
      ) | (
        { __typename: 'Video' }
        & Pick<Video, 'id' | 'title'>
      )> }
    )>>, newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ) }
  ) }
);

export type CarouselsQueryVariables = Exact<{
  publisherId?: Maybe<Scalars['ID']>;
  categoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CarouselsQuery = (
  { __typename?: 'Query' }
  & { carousels?: Maybe<Array<(
    { __typename: 'Carousel' }
    & Pick<Carousel, 'id' | 'categories' | 'createdAt'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ), carouselContents?: Maybe<Array<(
      { __typename: 'CarouselContent' }
      & Pick<CarouselContent, 'id'>
      & { contentable?: Maybe<{ __typename?: 'Category' } | (
        { __typename?: 'News' }
        & Pick<News, 'id' | 'title'>
      ) | (
        { __typename: 'Video' }
        & Pick<Video, 'id' | 'title'>
      )> }
    )>> }
  )>> }
);

export type ChatIndexPageQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ChatIndexPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, usersWithIds?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'uuid' | 'avatar' | 'verified'>
  )>> }
);

export type CommentPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommentPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, comment?: Maybe<(
    { __typename?: 'Comment' }
    & { reports: Array<(
      { __typename?: 'Report' }
      & ReportFragment
    )> }
    & CommentFragment
  )> }
);

export type CovidStatQueryVariables = Exact<{ [key: string]: never; }>;


export type CovidStatQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, covidStat: (
    { __typename?: 'CovidStat' }
    & CovidStatFragment
  ) }
);

export type CreatePinnedNewsMutationVariables = Exact<{
  languageCode: Scalars['String'];
  newsId: Scalars['String'];
  key: Scalars['String'];
}>;


export type CreatePinnedNewsMutation = (
  { __typename?: 'Mutation' }
  & { createPinnedNews?: Maybe<(
    { __typename?: 'CreatePinnedNewsPayload' }
    & Pick<CreatePinnedNewsPayload, 'success'>
    & { pinnedNewsArticle?: Maybe<(
      { __typename?: 'PinnedNewsArticle' }
      & Pick<PinnedNewsArticle, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateNewsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateNewsPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'active'>
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'show'>
  )>> }
);

export type EditNewsPageQueryVariables = Exact<{
  newsId: Scalars['ID'];
}>;


export type EditNewsPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, news?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'description' | 'content' | 'imageUrl' | 'hasNewsContent' | 'hasComments'>
    & { newsContent?: Maybe<(
      { __typename?: 'NewsContent' }
      & Pick<NewsContent, 'content'>
    )> }
  )> }
);

export type GetPublishersNewsPageQueryVariables = Exact<{
  languageCode?: Maybe<Scalars['String']>;
}>;


export type GetPublishersNewsPageQuery = (
  { __typename?: 'Query' }
  & { publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name'>
  )>> }
);

export type AllNewsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNewsPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name'>
  )>>, typesearchAlgoliaFlag?: Maybe<(
    { __typename?: 'TypesenseAlgoliaFlag' }
    & Pick<TypesenseAlgoliaFlag, 'typesenseIsOn'>
  )> }
);

export type PinnedNewsQueryVariables = Exact<{ [key: string]: never; }>;


export type PinnedNewsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, pinnedNewsArticles?: Maybe<Array<(
    { __typename?: 'PinnedNewsArticle' }
    & Pick<PinnedNewsArticle, 'id' | 'key'>
    & { language?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'code' | 'name' | 'id'>
    )>, news?: Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'title' | 'description' | 'pubDate'>
      & { newsPublisher: (
        { __typename?: 'NewsPublisher' }
        & PublisherFragment
      ) }
    )> }
  )>>, typesearchAlgoliaFlag?: Maybe<(
    { __typename?: 'TypesenseAlgoliaFlag' }
    & Pick<TypesenseAlgoliaFlag, 'typesenseIsOn'>
  )> }
);

export type PinnedNewsArticleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PinnedNewsArticleQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, pinnedNewsArticleById?: Maybe<(
    { __typename?: 'PinnedNewsArticle' }
    & Pick<PinnedNewsArticle, 'id' | 'key'>
    & { language?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'code' | 'name' | 'id'>
    )>, news?: Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'title' | 'description' | 'pubDate'>
      & { newsPublisher: (
        { __typename?: 'NewsPublisher' }
        & PublisherFragment
      ) }
    )> }
  )> }
);

export type UpdatePinnedNewsMutationVariables = Exact<{
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  newsId: Scalars['String'];
  key: Scalars['String'];
}>;


export type UpdatePinnedNewsMutation = (
  { __typename?: 'Mutation' }
  & { updatePinnedNews?: Maybe<(
    { __typename?: 'UpdatePinnedNewsPayload' }
    & Pick<UpdatePinnedNewsPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateNotificationPageQueryVariables = Exact<{
  newsId: Scalars['ID'];
}>;


export type CreateNotificationPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, news?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'description' | 'hasNewsContent' | 'hasImageAttached' | 'updatedAt'>
    & { newsContent?: Maybe<(
      { __typename?: 'NewsContent' }
      & Pick<NewsContent, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    )>, categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>, newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ), newsNotifications: Array<(
      { __typename?: 'NewsNotification' }
      & NewsNotificationFragment
    )> }
  )>, customNotifications: Array<(
    { __typename?: 'CustomNotification' }
    & CustomNotificationFragment
  )> }
);

export type AnnouncementQueryVariables = Exact<{ [key: string]: never; }>;


export type AnnouncementQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, announcements: Array<(
    { __typename?: 'Announcement' }
    & Pick<Announcement, 'id' | 'title' | 'path' | 'description' | 'createdAt'>
  )> }
);

export type PublisherPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublisherPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, publisher?: Maybe<(
    { __typename?: 'NewsPublisher' }
    & PublisherFragment
  )> }
);

export type AllPublishersPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AllPublishersPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'iconUrl' | 'active' | 'order' | 'lastCrawlAt' | 'lastCrawlTrendingAt' | 'rssUrl' | 'slug'>
  )>> }
);

export type PublisherNotificationPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PublisherNotificationPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, languages: Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'id' | 'name'>
  )> }
);

export type AllRichNewsStacksQueryVariables = Exact<{ [key: string]: never; }>;


export type AllRichNewsStacksQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, richContentCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'updatedAt'>
  )>> }
);

export type TopicStackPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopicStackPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, topicStack?: Maybe<(
    { __typename?: 'TopicStack' }
    & Pick<TopicStack, 'id' | 'title'>
    & { titleLocale?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )>, descriptionLocale?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )> }
  )>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type AllTopicStacksPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTopicStacksPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, topicStacks: Array<(
    { __typename?: 'TopicStack' }
    & Pick<TopicStack, 'id' | 'title'>
    & { titleLocale?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )>, descriptionLocale?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )> }
  )> }
);

export type TopicPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopicPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'languages' | 'hasRichContent' | 'hasComments'>
    & { suggestedCategories: Array<(
      { __typename?: 'SuggestedCategory' }
      & Pick<SuggestedCategory, 'id' | 'targetedCategoryId' | 'name' | 'order'>
    )>, author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'imageUrl' | 'verified'>
      & { nameLocale: (
        { __typename?: 'Locale' }
        & LocaleFragment
      ) }
    )> }
    & TopicFragment
  )> }
);

export type TopicFeedQueryVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
}>;


export type TopicFeedQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, topicFeed: (
    { __typename?: 'TopicFeed' }
    & Pick<TopicFeed, 'id' | 'data' | 'updatedAt' | 'lastPublishedAt'>
    & { category: (
      { __typename?: 'Category' }
      & Pick<Category, 'name'>
    ) }
  ) }
);

export type CreateAuthorPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreateAuthorPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )> }
);

export type TopicFeedsQueryVariables = Exact<{
  languageCode?: Maybe<Scalars['String']>;
}>;


export type TopicFeedsQuery = (
  { __typename?: 'Query' }
  & { topicFeeds: Array<(
    { __typename?: 'TopicFeed' }
    & Pick<TopicFeed, 'id' | 'data' | 'updatedAt'>
    & { category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    ) }
  )> }
);

export type AllTopicsPageQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  explore?: Maybe<Scalars['Boolean']>;
  categoryType?: Maybe<Scalars['String']>;
}>;


export type AllTopicsPageQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'emoji' | 'order' | 'show' | 'explore' | 'slug' | 'hasComments'>
  )>> }
);

export type TopicRichNewsStackQueryVariables = Exact<{
  categoryId: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
}>;


export type TopicRichNewsStackQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'slug' | 'name' | 'imageUrl' | 'lastPublishedAt'>
    & { exploreNameLocale?: Maybe<(
      { __typename?: 'Locale' }
      & LocaleFragment
    )> }
  )>, richContent?: Maybe<(
    { __typename?: 'RichContent' }
    & Pick<RichContent, 'data' | 'draft' | 'updatedAt' | 'publishedAt' | 'availableLanguages'>
  )> }
);

export type CreateVideoNotificationPageQueryVariables = Exact<{
  videoId: Scalars['ID'];
}>;


export type CreateVideoNotificationPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, video?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'title' | 'description' | 'externalId' | 'liveChatId'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ), categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>, videoNotifications: Array<(
      { __typename?: 'VideoNotification' }
      & VideoNotificationFragment
    )> }
  )> }
);

export type CrawlYoutubeVideoPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CrawlYoutubeVideoPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'active'>
  )>> }
);

export type EditVideoPageQueryVariables = Exact<{
  videoId: Scalars['ID'];
}>;


export type EditVideoPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, video?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'title' | 'description' | 'isWebViewable' | 'orientation' | 'externalId' | 'hasComments' | 'liveChatId' | 'isShort'>
  )> }
);

export type FeaturedVideoQueryVariables = Exact<{ [key: string]: never; }>;


export type FeaturedVideoQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, featuredVideo?: Maybe<Array<(
    { __typename?: 'FeaturedVideo' }
    & Pick<FeaturedVideo, 'id'>
    & { language?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'code' | 'name' | 'id'>
    )>, video?: Maybe<(
      { __typename?: 'Video' }
      & FeaturedVideoFragment
    )> }
  )>> }
);

export type Ge15FeaturedVideoQueryVariables = Exact<{ [key: string]: never; }>;


export type Ge15FeaturedVideoQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, ge15FeaturedVideo?: Maybe<Array<(
    { __typename?: 'FeaturedVideo' }
    & Pick<FeaturedVideo, 'id'>
    & { language?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'code' | 'name' | 'id'>
    )>, video?: Maybe<(
      { __typename?: 'Video' }
      & FeaturedVideoFragment
    )> }
  )>> }
);

export type AllVideosPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AllVideosPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name'>
  )>> }
);

export type AdminAttachmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminAttachmentsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { attachments: Array<(
      { __typename: 'AdminAttachment' }
      & Pick<AdminAttachment, 'id' | 'url' | 'filename'>
    )> }
  )> }
);

export type CategoriesWithIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CategoriesWithIdsQuery = (
  { __typename?: 'Query' }
  & { categoriesWithIds?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'imageUrl'>
  )>> }
);

export type CategoriesWithNamesQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
}>;


export type CategoriesWithNamesQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type CommentsQueryVariables = Exact<{
  type?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type CommentsQuery = (
  { __typename?: 'Query' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & { parentContent?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    ) | { __typename?: 'Comment' } | (
      { __typename?: 'News' }
      & Pick<News, 'id' | 'title'>
    ) | (
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'title' | 'isShort'>
    )> }
    & CommentFragment
  )>> }
);

export type ContentWithIdsQueryVariables = Exact<{
  newsIds: Array<Scalars['ID']> | Scalars['ID'];
  videoIds: Array<Scalars['ID']> | Scalars['ID'];
  categoryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ContentWithIdsQuery = (
  { __typename?: 'Query' }
  & { newsWithIds?: Maybe<Array<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'thumbnailUrl' | 'createdAt' | 'pubDate'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ) }
  )>>, videosWithIds?: Maybe<Array<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'title' | 'externalId' | 'createdAt' | 'pubDate' | 'isShort'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ) }
  )>>, categoriesWithIds?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'imageUrl'>
  )>> }
);

export type FailedRssPublishersQueryVariables = Exact<{ [key: string]: never; }>;


export type FailedRssPublishersQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'email'>
  )>, failedRssPublishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & PublisherFragment
  )>> }
);

export type GetNewsCommentsQueryVariables = Exact<{
  newsId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetNewsCommentsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, news?: Maybe<(
    { __typename?: 'News' }
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
  )> }
);

export type GetRepliesQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetRepliesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, comment?: Maybe<(
    { __typename?: 'Comment' }
    & { replies: Array<(
      { __typename?: 'Comment' }
      & ReplyFragment
    )> }
  )> }
);

export type GetTopicCommentsQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetTopicCommentsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, category?: Maybe<(
    { __typename?: 'Category' }
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
  )> }
);

export type GetVideoCommentsQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetVideoCommentsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  )>, video?: Maybe<(
    { __typename?: 'Video' }
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
  )> }
);

export type KeywordQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KeywordQuery = (
  { __typename?: 'Query' }
  & { keyword?: Maybe<(
    { __typename?: 'Keyword' }
    & Pick<Keyword, 'id' | 'language' | 'position' | 'keywordString' | 'createdAt'>
  )> }
);

export type KeywordsQueryVariables = Exact<{
  languageCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type KeywordsQuery = (
  { __typename?: 'Query' }
  & { keywords?: Maybe<Array<(
    { __typename?: 'Keyword' }
    & Pick<Keyword, 'id' | 'keywordString' | 'position' | 'language' | 'createdAt'>
  )>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'email'>
  )> }
);

export type NewPublisherNotificationQueryVariables = Exact<{
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type NewPublisherNotificationQuery = (
  { __typename?: 'Query' }
  & { newPublisherNotifications?: Maybe<Array<(
    { __typename?: 'NewPublisherNotification' }
    & Pick<NewPublisherNotification, 'id' | 'title' | 'description' | 'sendAt' | 'createdAt'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
    ) }
  )>> }
);

export type NewsNotificationQueryVariables = Exact<{
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  customNotificationId?: Maybe<Scalars['ID']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type NewsNotificationQuery = (
  { __typename?: 'Query' }
  & { newsNotifications?: Maybe<Array<(
    { __typename?: 'NewsNotification' }
    & NewsNotificationFragment
  )>>, customNotifications: Array<(
    { __typename?: 'CustomNotification' }
    & CustomNotificationFragment
  )> }
);

export type NewsStackNotificationQueryVariables = Exact<{
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type NewsStackNotificationQuery = (
  { __typename?: 'Query' }
  & { newsStackNotifications?: Maybe<Array<(
    { __typename?: 'NewsStackNotification' }
    & Pick<NewsStackNotification, 'id' | 'createdAt' | 'sendAt'>
    & { titleLocale: (
      { __typename?: 'Locale' }
      & LocaleFragment
    ), category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    ) }
  )>> }
);

export type NewsWithIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type NewsWithIdsQuery = (
  { __typename?: 'Query' }
  & { newsWithIds?: Maybe<Array<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'description' | 'imageUrl' | 'thumbnailUrl' | 'good' | 'pubDate' | 'createdAt' | 'hasComments'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'iconUrl' | 'name'>
    ), categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>, newsNotifications: Array<(
      { __typename?: 'NewsNotification' }
      & Pick<NewsNotification, 'id' | 'createdAt' | 'sendAt'>
      & { customNotification: (
        { __typename?: 'CustomNotification' }
        & Pick<CustomNotification, 'id' | 'name'>
      ) }
    )> }
  )>> }
);

export type PublishersQueryVariables = Exact<{
  type?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type PublishersQuery = (
  { __typename?: 'Query' }
  & { publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & PublisherFragment
  )>> }
);

export type ReportsQueryVariables = Exact<{
  type?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ReportsQuery = (
  { __typename?: 'Query' }
  & { reports?: Maybe<Array<(
    { __typename?: 'Report' }
    & ReportFragment
  )>> }
);

export type ShortsPlaylistsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type ShortsPlaylistsQuery = (
  { __typename?: 'Query' }
  & { playlists: Array<(
    { __typename?: 'Playlist' }
    & Pick<Playlist, 'id' | 'name' | 'slug'>
  )> }
);

export type TopNewsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopNewsQuery = (
  { __typename?: 'Query' }
  & { topNews?: Maybe<(
    { __typename?: 'TopNews' }
    & Pick<TopNews, 'id' | 'languageCode' | 'newsIdArray' | 'updatedAt' | 'createdAt'>
    & { news: Array<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'title'>
    )> }
  )> }
);

export type TopNewsListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type TopNewsListQuery = (
  { __typename?: 'Query' }
  & { topNewsList?: Maybe<Array<(
    { __typename?: 'TopNews' }
    & Pick<TopNews, 'id' | 'languageCode' | 'newsIdArray' | 'updatedAt' | 'createdAt'>
    & { metadata?: Maybe<Array<(
      { __typename?: 'TopNewsMetadata' }
      & Pick<TopNewsMetadata, 'newsId' | 'trendingRank'>
    )>>, news: Array<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'title'>
    )> }
  )>> }
);

export type TopicStackWithIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopicStackWithIdQuery = (
  { __typename?: 'Query' }
  & { topicStack?: Maybe<(
    { __typename?: 'TopicStack' }
    & Pick<TopicStack, 'id'>
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>> }
  )> }
);

export type TypesearchAlgoliaFlagQueryVariables = Exact<{ [key: string]: never; }>;


export type TypesearchAlgoliaFlagQuery = (
  { __typename?: 'Query' }
  & { typesearchAlgoliaFlag?: Maybe<(
    { __typename?: 'TypesenseAlgoliaFlag' }
    & Pick<TypesenseAlgoliaFlag, 'typesenseIsOn'>
  )> }
);

export type FindUserQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type FindUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'username' | 'uuid' | 'isBannedFromComment'>
    & { comments: Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'createdAt' | 'content' | 'likesCount' | 'hasReports' | 'commentableType' | 'commentableId'>
    )>, reportingComments: Array<(
      { __typename?: 'Comment' }
      & CommentFragment
    )> }
  )> }
);

export type VideoNotificationQueryVariables = Exact<{
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  afterSendAt?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type VideoNotificationQuery = (
  { __typename?: 'Query' }
  & { videoNotifications?: Maybe<Array<(
    { __typename?: 'VideoNotification' }
    & Pick<VideoNotification, 'id' | 'title' | 'createdAt' | 'sendAt'>
    & { video: (
      { __typename?: 'Video' }
      & Pick<Video, 'id'>
      & { newsPublisher: (
        { __typename?: 'NewsPublisher' }
        & Pick<NewsPublisher, 'id' | 'name'>
      ) }
    ) }
  )>> }
);

export type VideosWithIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type VideosWithIdsQuery = (
  { __typename?: 'Query' }
  & { videosWithIds?: Maybe<Array<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'title' | 'externalId' | 'createdAt' | 'pubDate' | 'isShort'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'name'>
    ), categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>, videoNotifications: Array<(
      { __typename?: 'VideoNotification' }
      & VideoNotificationFragment
    )> }
  )>> }
);

export type CategoriesQueryVariables = Exact<{
  orderByName?: Maybe<Scalars['Boolean']>;
  withoutStacks?: Maybe<Scalars['Boolean']>;
}>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type PublishersDropdownQueryVariables = Exact<{
  type?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderByName?: Maybe<Scalars['Boolean']>;
}>;


export type PublishersDropdownQuery = (
  { __typename?: 'Query' }
  & { publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name'>
  )>> }
);

export const CovidStatFragmentDoc = gql`
    fragment CovidStat on CovidStat {
  id
  newCases
  activeCases
  recovered
  deaths
  totalCases
  totalRecovered
  totalDeaths
  todayActive
  date
  updatedAt
  cat1
  cat2
  cat3
  cat4
  cat5
  stateData {
    id
    name
    newCases
    totalCases
  }
}
    `;
export const LocaleFragmentDoc = gql`
    fragment Locale on Locale {
  id
  en
  ms
  zh
}
    `;
export const CustomBannerFragmentDoc = gql`
    fragment CustomBanner on CustomBanner {
  id
  latestTabBanner {
    ...Locale
  }
  covidTabBanner {
    ...Locale
  }
  goodNewsBanner {
    ...Locale
  }
  dailyDosesLabel {
    ...Locale
  }
  vaccinationGoalLabel {
    ...Locale
  }
  totalDosesLabel {
    ...Locale
  }
  oneDoseLabel {
    ...Locale
  }
  twoDosesLabel {
    ...Locale
  }
  newCovidCasesLabel {
    ...Locale
  }
  confirmedCasesLabel {
    ...Locale
  }
  activeCasesLabel {
    ...Locale
  }
  recoveredCasesLabel {
    ...Locale
  }
  fatalitiesCasesLabel {
    ...Locale
  }
  stateCasesLabel {
    ...Locale
  }
  searchBarHintText {
    ...Locale
  }
  customLatestTabBanner {
    ...Locale
  }
  customLatestTabBannerColor {
    ...Locale
  }
  customLatestTabBannerLink {
    ...Locale
  }
  communityBannerColor {
    ...Locale
  }
  communityBannerImage {
    ...Locale
  }
  communityBannerLink {
    ...Locale
  }
  communityBannerSubtitle {
    ...Locale
  }
  communityBannerTitle {
    ...Locale
  }
}
    ${LocaleFragmentDoc}`;
export const ErrorFragmentDoc = gql`
    fragment Error on Error {
  path
  detail
}
    `;
export const FeaturedVideoFragmentDoc = gql`
    fragment FeaturedVideo on Video {
  id
  createdAt
  description
  externalId
  newsPublisher {
    id
    name
  }
  pubDate
  title
}
    `;
export const CustomNotificationFragmentDoc = gql`
    fragment CustomNotification on CustomNotification {
  id
  name
}
    `;
export const NewsNotificationFragmentDoc = gql`
    fragment NewsNotification on NewsNotification {
  id
  title
  sendAt
  description
  createdAt
  news {
    id
    categories {
      id
      name
    }
    newsPublisher {
      id
      name
    }
  }
  customNotification {
    ...CustomNotification
  }
}
    ${CustomNotificationFragmentDoc}`;
export const PublisherFragmentDoc = gql`
    fragment Publisher on NewsPublisher {
  id
  name
  slug
  order
  imageUrl
  iconUrl
  vectorUrl
  description
  email
  phoneNumber
  active
  url
  newPublisher: new
  badges
  lastCrawlAt
  lastCrawlTrendingAt
  lastSendTrendingNotificationAt
  rssUrl
  trendingUrl
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  displayName
  username
  uuid
  isBannedFromComment
}
    `;
export const ReplyFragmentDoc = gql`
    fragment Reply on Comment {
  id
  createdAt
  content
  likesCount
  hasReports
  commentableType
  commentableId
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  id
  createdAt
  content
  likesCount
  hasReports
  commentableType
  commentableId
  user {
    ...User
  }
  hasReplies
  replyParentComment {
    id
    commentableType
    commentableId
    content
    deletedAt
  }
  deletedAt
}
    ${UserFragmentDoc}`;
export const ReportFragmentDoc = gql`
    fragment Report on Report {
  id
  reason
  createdAt
  status
  reportingUser {
    ...User
  }
  reportedComment {
    ...Comment
  }
}
    ${UserFragmentDoc}
${CommentFragmentDoc}`;
export const TopicFragmentDoc = gql`
    fragment Topic on Category {
  id
  name
  slug
  emoji
  order
  show
  imageUrl
  explore
  newStatus
  hot
  updated
  exclusive
  imageUrl
  hasThumbnail
  thumbnailUrl
  lastEditedAt
  lastPublishedAt
  updatedAt
  searchable
  nameLocale {
    ...Locale
  }
  exploreNameLocale {
    ...Locale
  }
  webTitleLocale {
    ...Locale
  }
  descriptionLocale {
    ...Locale
  }
}
    ${LocaleFragmentDoc}`;
export const VideoSummaryFragmentDoc = gql`
    fragment VideoSummary on Video {
  id
  title
}
    `;
export const VideoNotificationFragmentDoc = gql`
    fragment VideoNotification on VideoNotification {
  id
  title
  sendAt
  description
  createdAt
}
    `;
export const AuthorDetailsFragmentDoc = gql`
    fragment authorDetails on Author {
  id
  nameLocale {
    ...Locale
  }
  imageUrl
  authorType
  verified
  path
}
    ${LocaleFragmentDoc}`;
export const DeleteAdminAttachmentDocument = gql`
    mutation DeleteAdminAttachment($attachmentId: ID!) {
  deleteAdminAttachment(attachmentId: $attachmentId) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type DeleteAdminAttachmentMutationFn = Apollo.MutationFunction<DeleteAdminAttachmentMutation, DeleteAdminAttachmentMutationVariables>;

/**
 * __useDeleteAdminAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAdminAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminAttachmentMutation, { data, loading, error }] = useDeleteAdminAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteAdminAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminAttachmentMutation, DeleteAdminAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminAttachmentMutation, DeleteAdminAttachmentMutationVariables>(DeleteAdminAttachmentDocument, options);
      }
export type DeleteAdminAttachmentMutationHookResult = ReturnType<typeof useDeleteAdminAttachmentMutation>;
export type DeleteAdminAttachmentMutationResult = Apollo.MutationResult<DeleteAdminAttachmentMutation>;
export type DeleteAdminAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteAdminAttachmentMutation, DeleteAdminAttachmentMutationVariables>;
export const UploadAdminAttachmentDocument = gql`
    mutation UploadAdminAttachment($attachment: File!) {
  uploadAdminAttachment(attachment: $attachment) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type UploadAdminAttachmentMutationFn = Apollo.MutationFunction<UploadAdminAttachmentMutation, UploadAdminAttachmentMutationVariables>;

/**
 * __useUploadAdminAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadAdminAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAdminAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAdminAttachmentMutation, { data, loading, error }] = useUploadAdminAttachmentMutation({
 *   variables: {
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useUploadAdminAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UploadAdminAttachmentMutation, UploadAdminAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAdminAttachmentMutation, UploadAdminAttachmentMutationVariables>(UploadAdminAttachmentDocument, options);
      }
export type UploadAdminAttachmentMutationHookResult = ReturnType<typeof useUploadAdminAttachmentMutation>;
export type UploadAdminAttachmentMutationResult = Apollo.MutationResult<UploadAdminAttachmentMutation>;
export type UploadAdminAttachmentMutationOptions = Apollo.BaseMutationOptions<UploadAdminAttachmentMutation, UploadAdminAttachmentMutationVariables>;
export const UpdateAppBannerDocument = gql`
    mutation UpdateAppBanner($key: String!, $input: AppBannerInput!) {
  updateAppBanner(key: $key, input: $input) {
    success
    errors {
      ...Error
    }
    appBanner {
      __typename
      id
      key
      updatedAt
      title {
        id
        en
        ms
        zh
      }
      description {
        id
        en
        ms
        zh
      }
      image
      icon
      path
      pathLocale {
        id
        en
        ms
        zh
      }
      buttonTextLocale {
        id
        en
        ms
        zh
      }
      dismissable
      buttonText
      backgroundColorLight
      backgroundColorDark
      buttonBackgroundColorLight
      buttonBackgroundColorDark
      buttonTextColorLight
      buttonTextColorDark
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAppBannerMutationFn = Apollo.MutationFunction<UpdateAppBannerMutation, UpdateAppBannerMutationVariables>;

/**
 * __useUpdateAppBannerMutation__
 *
 * To run a mutation, you first call `useUpdateAppBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppBannerMutation, { data, loading, error }] = useUpdateAppBannerMutation({
 *   variables: {
 *      key: // value for 'key'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppBannerMutation, UpdateAppBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppBannerMutation, UpdateAppBannerMutationVariables>(UpdateAppBannerDocument, options);
      }
export type UpdateAppBannerMutationHookResult = ReturnType<typeof useUpdateAppBannerMutation>;
export type UpdateAppBannerMutationResult = Apollo.MutationResult<UpdateAppBannerMutation>;
export type UpdateAppBannerMutationOptions = Apollo.BaseMutationOptions<UpdateAppBannerMutation, UpdateAppBannerMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    me {
      id
      email
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UpdateAuthorForTopicDocument = gql`
    mutation UpdateAuthorForTopic($authorId: ID!, $nameLocale: LocaleInput!, $image: File, $authorType: String!, $verified: Boolean, $path: String) {
  updateAuthorForTopic(
    authorId: $authorId
    input: {nameLocale: $nameLocale, image: $image, authorType: $authorType, verified: $verified, path: $path}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAuthorForTopicMutationFn = Apollo.MutationFunction<UpdateAuthorForTopicMutation, UpdateAuthorForTopicMutationVariables>;

/**
 * __useUpdateAuthorForTopicMutation__
 *
 * To run a mutation, you first call `useUpdateAuthorForTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthorForTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthorForTopicMutation, { data, loading, error }] = useUpdateAuthorForTopicMutation({
 *   variables: {
 *      authorId: // value for 'authorId'
 *      nameLocale: // value for 'nameLocale'
 *      image: // value for 'image'
 *      authorType: // value for 'authorType'
 *      verified: // value for 'verified'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useUpdateAuthorForTopicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuthorForTopicMutation, UpdateAuthorForTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuthorForTopicMutation, UpdateAuthorForTopicMutationVariables>(UpdateAuthorForTopicDocument, options);
      }
export type UpdateAuthorForTopicMutationHookResult = ReturnType<typeof useUpdateAuthorForTopicMutation>;
export type UpdateAuthorForTopicMutationResult = Apollo.MutationResult<UpdateAuthorForTopicMutation>;
export type UpdateAuthorForTopicMutationOptions = Apollo.BaseMutationOptions<UpdateAuthorForTopicMutation, UpdateAuthorForTopicMutationVariables>;
export const UpdateCustomBannerDocument = gql`
    mutation UpdateCustomBanner($latestTabBanner: LocaleInput, $covidTabBanner: LocaleInput, $goodNewsBanner: LocaleInput, $dailyDosesLabel: LocaleInput, $vaccinationGoalLabel: LocaleInput, $totalDosesLabel: LocaleInput, $oneDoseLabel: LocaleInput, $twoDosesLabel: LocaleInput, $newCovidCasesLabel: LocaleInput, $confirmedCasesLabel: LocaleInput, $activeCasesLabel: LocaleInput, $recoveredCasesLabel: LocaleInput, $fatalitiesCasesLabel: LocaleInput, $stateCasesLabel: LocaleInput, $searchBarHintText: LocaleInput, $customLatestTabBanner: LocaleInput, $customLatestTabBannerColor: LocaleInput, $customLatestTabBannerLink: LocaleInput, $communityBannerColor: LocaleInput, $communityBannerImage: LocaleInput, $communityBannerLink: LocaleInput, $communityBannerSubtitle: LocaleInput, $communityBannerTitle: LocaleInput) {
  updateCustomBanner(
    input: {latestTabBanner: $latestTabBanner, covidTabBanner: $covidTabBanner, goodNewsBanner: $goodNewsBanner, dailyDosesLabel: $dailyDosesLabel, vaccinationGoalLabel: $vaccinationGoalLabel, totalDosesLabel: $totalDosesLabel, oneDoseLabel: $oneDoseLabel, twoDosesLabel: $twoDosesLabel, newCovidCasesLabel: $newCovidCasesLabel, confirmedCasesLabel: $confirmedCasesLabel, activeCasesLabel: $activeCasesLabel, recoveredCasesLabel: $recoveredCasesLabel, fatalitiesCasesLabel: $fatalitiesCasesLabel, stateCasesLabel: $stateCasesLabel, searchBarHintText: $searchBarHintText, customLatestTabBanner: $customLatestTabBanner, customLatestTabBannerColor: $customLatestTabBannerColor, customLatestTabBannerLink: $customLatestTabBannerLink, communityBannerColor: $communityBannerColor, communityBannerImage: $communityBannerImage, communityBannerLink: $communityBannerLink, communityBannerSubtitle: $communityBannerSubtitle, communityBannerTitle: $communityBannerTitle}
  ) {
    customBanner {
      ...CustomBanner
    }
    errors {
      ...Error
    }
  }
}
    ${CustomBannerFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdateCustomBannerMutationFn = Apollo.MutationFunction<UpdateCustomBannerMutation, UpdateCustomBannerMutationVariables>;

/**
 * __useUpdateCustomBannerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomBannerMutation, { data, loading, error }] = useUpdateCustomBannerMutation({
 *   variables: {
 *      latestTabBanner: // value for 'latestTabBanner'
 *      covidTabBanner: // value for 'covidTabBanner'
 *      goodNewsBanner: // value for 'goodNewsBanner'
 *      dailyDosesLabel: // value for 'dailyDosesLabel'
 *      vaccinationGoalLabel: // value for 'vaccinationGoalLabel'
 *      totalDosesLabel: // value for 'totalDosesLabel'
 *      oneDoseLabel: // value for 'oneDoseLabel'
 *      twoDosesLabel: // value for 'twoDosesLabel'
 *      newCovidCasesLabel: // value for 'newCovidCasesLabel'
 *      confirmedCasesLabel: // value for 'confirmedCasesLabel'
 *      activeCasesLabel: // value for 'activeCasesLabel'
 *      recoveredCasesLabel: // value for 'recoveredCasesLabel'
 *      fatalitiesCasesLabel: // value for 'fatalitiesCasesLabel'
 *      stateCasesLabel: // value for 'stateCasesLabel'
 *      searchBarHintText: // value for 'searchBarHintText'
 *      customLatestTabBanner: // value for 'customLatestTabBanner'
 *      customLatestTabBannerColor: // value for 'customLatestTabBannerColor'
 *      customLatestTabBannerLink: // value for 'customLatestTabBannerLink'
 *      communityBannerColor: // value for 'communityBannerColor'
 *      communityBannerImage: // value for 'communityBannerImage'
 *      communityBannerLink: // value for 'communityBannerLink'
 *      communityBannerSubtitle: // value for 'communityBannerSubtitle'
 *      communityBannerTitle: // value for 'communityBannerTitle'
 *   },
 * });
 */
export function useUpdateCustomBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomBannerMutation, UpdateCustomBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomBannerMutation, UpdateCustomBannerMutationVariables>(UpdateCustomBannerDocument, options);
      }
export type UpdateCustomBannerMutationHookResult = ReturnType<typeof useUpdateCustomBannerMutation>;
export type UpdateCustomBannerMutationResult = Apollo.MutationResult<UpdateCustomBannerMutation>;
export type UpdateCustomBannerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomBannerMutation, UpdateCustomBannerMutationVariables>;
export const EditCampaignDocument = gql`
    mutation EditCampaign($id: ID!, $title: LocaleInput!, $subtitle: LocaleInput!, $bannerTitle: LocaleInput!, $bannerSubtitle: LocaleInput!, $description: LocaleInput!, $image: File, $bannerImage: File) {
  editCampaign(
    id: $id
    input: {title: $title, subtitle: $subtitle, bannerTitle: $bannerTitle, bannerSubtitle: $bannerSubtitle, description: $description, image: $image, bannerImage: $bannerImage}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type EditCampaignMutationFn = Apollo.MutationFunction<EditCampaignMutation, EditCampaignMutationVariables>;

/**
 * __useEditCampaignMutation__
 *
 * To run a mutation, you first call `useEditCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCampaignMutation, { data, loading, error }] = useEditCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      bannerTitle: // value for 'bannerTitle'
 *      bannerSubtitle: // value for 'bannerSubtitle'
 *      description: // value for 'description'
 *      image: // value for 'image'
 *      bannerImage: // value for 'bannerImage'
 *   },
 * });
 */
export function useEditCampaignMutation(baseOptions?: Apollo.MutationHookOptions<EditCampaignMutation, EditCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCampaignMutation, EditCampaignMutationVariables>(EditCampaignDocument, options);
      }
export type EditCampaignMutationHookResult = ReturnType<typeof useEditCampaignMutation>;
export type EditCampaignMutationResult = Apollo.MutationResult<EditCampaignMutation>;
export type EditCampaignMutationOptions = Apollo.BaseMutationOptions<EditCampaignMutation, EditCampaignMutationVariables>;
export const UpdateCarouselContentDocument = gql`
    mutation UpdateCarouselContent($id: ID!, $sponsored: Boolean, $contentableId: ID, $contentableType: String) {
  updateCarouselContent(
    data: {id: $id, sponsored: $sponsored, contentableId: $contentableId, contentableType: $contentableType}
  ) {
    success
  }
}
    `;
export type UpdateCarouselContentMutationFn = Apollo.MutationFunction<UpdateCarouselContentMutation, UpdateCarouselContentMutationVariables>;

/**
 * __useUpdateCarouselContentMutation__
 *
 * To run a mutation, you first call `useUpdateCarouselContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarouselContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarouselContentMutation, { data, loading, error }] = useUpdateCarouselContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sponsored: // value for 'sponsored'
 *      contentableId: // value for 'contentableId'
 *      contentableType: // value for 'contentableType'
 *   },
 * });
 */
export function useUpdateCarouselContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCarouselContentMutation, UpdateCarouselContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCarouselContentMutation, UpdateCarouselContentMutationVariables>(UpdateCarouselContentDocument, options);
      }
export type UpdateCarouselContentMutationHookResult = ReturnType<typeof useUpdateCarouselContentMutation>;
export type UpdateCarouselContentMutationResult = Apollo.MutationResult<UpdateCarouselContentMutation>;
export type UpdateCarouselContentMutationOptions = Apollo.BaseMutationOptions<UpdateCarouselContentMutation, UpdateCarouselContentMutationVariables>;
export const AddToCarouselDocument = gql`
    mutation AddToCarousel($id: ID!, $contentableId: ID!, $contentableType: String!) {
  addToCarousel(
    data: {id: $id, contentableId: $contentableId, contentableType: $contentableType}
  ) {
    success
    errors {
      detail
      path
      payload
      type
    }
  }
}
    `;
export type AddToCarouselMutationFn = Apollo.MutationFunction<AddToCarouselMutation, AddToCarouselMutationVariables>;

/**
 * __useAddToCarouselMutation__
 *
 * To run a mutation, you first call `useAddToCarouselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCarouselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCarouselMutation, { data, loading, error }] = useAddToCarouselMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contentableId: // value for 'contentableId'
 *      contentableType: // value for 'contentableType'
 *   },
 * });
 */
export function useAddToCarouselMutation(baseOptions?: Apollo.MutationHookOptions<AddToCarouselMutation, AddToCarouselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToCarouselMutation, AddToCarouselMutationVariables>(AddToCarouselDocument, options);
      }
export type AddToCarouselMutationHookResult = ReturnType<typeof useAddToCarouselMutation>;
export type AddToCarouselMutationResult = Apollo.MutationResult<AddToCarouselMutation>;
export type AddToCarouselMutationOptions = Apollo.BaseMutationOptions<AddToCarouselMutation, AddToCarouselMutationVariables>;
export const AddContentBulkDocument = gql`
    mutation AddContentBulk($carouselIds: [ID!]!, $carouselContents: [CarouselContentInput!]!) {
  addContentBulk(
    data: {carouselIds: $carouselIds, carouselContents: $carouselContents}
  ) {
    success
    errors {
      detail
      path
      payload
      type
    }
  }
}
    `;
export type AddContentBulkMutationFn = Apollo.MutationFunction<AddContentBulkMutation, AddContentBulkMutationVariables>;

/**
 * __useAddContentBulkMutation__
 *
 * To run a mutation, you first call `useAddContentBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentBulkMutation, { data, loading, error }] = useAddContentBulkMutation({
 *   variables: {
 *      carouselIds: // value for 'carouselIds'
 *      carouselContents: // value for 'carouselContents'
 *   },
 * });
 */
export function useAddContentBulkMutation(baseOptions?: Apollo.MutationHookOptions<AddContentBulkMutation, AddContentBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContentBulkMutation, AddContentBulkMutationVariables>(AddContentBulkDocument, options);
      }
export type AddContentBulkMutationHookResult = ReturnType<typeof useAddContentBulkMutation>;
export type AddContentBulkMutationResult = Apollo.MutationResult<AddContentBulkMutation>;
export type AddContentBulkMutationOptions = Apollo.BaseMutationOptions<AddContentBulkMutation, AddContentBulkMutationVariables>;
export const ClearContentDocument = gql`
    mutation ClearContent($carouselId: ID!) {
  clearContent(data: {carouselId: $carouselId}) {
    success
    errors {
      detail
      path
      payload
      type
    }
  }
}
    `;
export type ClearContentMutationFn = Apollo.MutationFunction<ClearContentMutation, ClearContentMutationVariables>;

/**
 * __useClearContentMutation__
 *
 * To run a mutation, you first call `useClearContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearContentMutation, { data, loading, error }] = useClearContentMutation({
 *   variables: {
 *      carouselId: // value for 'carouselId'
 *   },
 * });
 */
export function useClearContentMutation(baseOptions?: Apollo.MutationHookOptions<ClearContentMutation, ClearContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearContentMutation, ClearContentMutationVariables>(ClearContentDocument, options);
      }
export type ClearContentMutationHookResult = ReturnType<typeof useClearContentMutation>;
export type ClearContentMutationResult = Apollo.MutationResult<ClearContentMutation>;
export type ClearContentMutationOptions = Apollo.BaseMutationOptions<ClearContentMutation, ClearContentMutationVariables>;
export const ClearContentBulkDocument = gql`
    mutation ClearContentBulk($carouselIds: [ID!]!) {
  clearContentBulk(data: {carouselIds: $carouselIds}) {
    success
    errors {
      detail
      path
      payload
      type
    }
  }
}
    `;
export type ClearContentBulkMutationFn = Apollo.MutationFunction<ClearContentBulkMutation, ClearContentBulkMutationVariables>;

/**
 * __useClearContentBulkMutation__
 *
 * To run a mutation, you first call `useClearContentBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearContentBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearContentBulkMutation, { data, loading, error }] = useClearContentBulkMutation({
 *   variables: {
 *      carouselIds: // value for 'carouselIds'
 *   },
 * });
 */
export function useClearContentBulkMutation(baseOptions?: Apollo.MutationHookOptions<ClearContentBulkMutation, ClearContentBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearContentBulkMutation, ClearContentBulkMutationVariables>(ClearContentBulkDocument, options);
      }
export type ClearContentBulkMutationHookResult = ReturnType<typeof useClearContentBulkMutation>;
export type ClearContentBulkMutationResult = Apollo.MutationResult<ClearContentBulkMutation>;
export type ClearContentBulkMutationOptions = Apollo.BaseMutationOptions<ClearContentBulkMutation, ClearContentBulkMutationVariables>;
export const CreateCarouselDocument = gql`
    mutation CreateCarousel($categoryIds: [ID!]!, $newsPublisherId: ID!, $carouselContents: [CarouselContentInput!]) {
  createCarousel(
    data: {categoryIds: $categoryIds, newsPublisherId: $newsPublisherId, carouselContents: $carouselContents}
  ) {
    success
    errors {
      detail
      path
      payload
      type
    }
  }
}
    `;
export type CreateCarouselMutationFn = Apollo.MutationFunction<CreateCarouselMutation, CreateCarouselMutationVariables>;

/**
 * __useCreateCarouselMutation__
 *
 * To run a mutation, you first call `useCreateCarouselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarouselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarouselMutation, { data, loading, error }] = useCreateCarouselMutation({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *      newsPublisherId: // value for 'newsPublisherId'
 *      carouselContents: // value for 'carouselContents'
 *   },
 * });
 */
export function useCreateCarouselMutation(baseOptions?: Apollo.MutationHookOptions<CreateCarouselMutation, CreateCarouselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCarouselMutation, CreateCarouselMutationVariables>(CreateCarouselDocument, options);
      }
export type CreateCarouselMutationHookResult = ReturnType<typeof useCreateCarouselMutation>;
export type CreateCarouselMutationResult = Apollo.MutationResult<CreateCarouselMutation>;
export type CreateCarouselMutationOptions = Apollo.BaseMutationOptions<CreateCarouselMutation, CreateCarouselMutationVariables>;
export const CreateCarouselBulkDocument = gql`
    mutation CreateCarouselBulk($categoryIds: [ID!]!, $newsPublisherIds: [ID!]!, $carouselContents: [CarouselContentInput!]) {
  createCarouselBulk(
    data: {categoryIds: $categoryIds, newsPublisherIds: $newsPublisherIds, carouselContents: $carouselContents}
  ) {
    success
    errors {
      detail
      path
      payload
      type
    }
  }
}
    `;
export type CreateCarouselBulkMutationFn = Apollo.MutationFunction<CreateCarouselBulkMutation, CreateCarouselBulkMutationVariables>;

/**
 * __useCreateCarouselBulkMutation__
 *
 * To run a mutation, you first call `useCreateCarouselBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarouselBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarouselBulkMutation, { data, loading, error }] = useCreateCarouselBulkMutation({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *      newsPublisherIds: // value for 'newsPublisherIds'
 *      carouselContents: // value for 'carouselContents'
 *   },
 * });
 */
export function useCreateCarouselBulkMutation(baseOptions?: Apollo.MutationHookOptions<CreateCarouselBulkMutation, CreateCarouselBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCarouselBulkMutation, CreateCarouselBulkMutationVariables>(CreateCarouselBulkDocument, options);
      }
export type CreateCarouselBulkMutationHookResult = ReturnType<typeof useCreateCarouselBulkMutation>;
export type CreateCarouselBulkMutationResult = Apollo.MutationResult<CreateCarouselBulkMutation>;
export type CreateCarouselBulkMutationOptions = Apollo.BaseMutationOptions<CreateCarouselBulkMutation, CreateCarouselBulkMutationVariables>;
export const DeleteCarouselDocument = gql`
    mutation DeleteCarousel($id: ID!) {
  deleteCarousel(id: $id) {
    success
    errors {
      detail
      path
      payload
      type
    }
  }
}
    `;
export type DeleteCarouselMutationFn = Apollo.MutationFunction<DeleteCarouselMutation, DeleteCarouselMutationVariables>;

/**
 * __useDeleteCarouselMutation__
 *
 * To run a mutation, you first call `useDeleteCarouselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarouselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarouselMutation, { data, loading, error }] = useDeleteCarouselMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCarouselMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCarouselMutation, DeleteCarouselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCarouselMutation, DeleteCarouselMutationVariables>(DeleteCarouselDocument, options);
      }
export type DeleteCarouselMutationHookResult = ReturnType<typeof useDeleteCarouselMutation>;
export type DeleteCarouselMutationResult = Apollo.MutationResult<DeleteCarouselMutation>;
export type DeleteCarouselMutationOptions = Apollo.BaseMutationOptions<DeleteCarouselMutation, DeleteCarouselMutationVariables>;
export const RemoveFromCarouselDocument = gql`
    mutation RemoveFromCarousel($id: ID!, $contentableId: ID!, $contentableType: String!) {
  removeFromCarousel(
    data: {id: $id, contentableId: $contentableId, contentableType: $contentableType}
  ) {
    success
  }
}
    `;
export type RemoveFromCarouselMutationFn = Apollo.MutationFunction<RemoveFromCarouselMutation, RemoveFromCarouselMutationVariables>;

/**
 * __useRemoveFromCarouselMutation__
 *
 * To run a mutation, you first call `useRemoveFromCarouselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCarouselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCarouselMutation, { data, loading, error }] = useRemoveFromCarouselMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contentableId: // value for 'contentableId'
 *      contentableType: // value for 'contentableType'
 *   },
 * });
 */
export function useRemoveFromCarouselMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromCarouselMutation, RemoveFromCarouselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromCarouselMutation, RemoveFromCarouselMutationVariables>(RemoveFromCarouselDocument, options);
      }
export type RemoveFromCarouselMutationHookResult = ReturnType<typeof useRemoveFromCarouselMutation>;
export type RemoveFromCarouselMutationResult = Apollo.MutationResult<RemoveFromCarouselMutation>;
export type RemoveFromCarouselMutationOptions = Apollo.BaseMutationOptions<RemoveFromCarouselMutation, RemoveFromCarouselMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentIds: [ID!]!) {
  deleteComment(commentIds: $commentIds) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentIds: // value for 'commentIds'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const NotifyCovidStatDocument = gql`
    mutation NotifyCovidStat {
  notifyCovidStat {
    success
  }
}
    `;
export type NotifyCovidStatMutationFn = Apollo.MutationFunction<NotifyCovidStatMutation, NotifyCovidStatMutationVariables>;

/**
 * __useNotifyCovidStatMutation__
 *
 * To run a mutation, you first call `useNotifyCovidStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyCovidStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyCovidStatMutation, { data, loading, error }] = useNotifyCovidStatMutation({
 *   variables: {
 *   },
 * });
 */
export function useNotifyCovidStatMutation(baseOptions?: Apollo.MutationHookOptions<NotifyCovidStatMutation, NotifyCovidStatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyCovidStatMutation, NotifyCovidStatMutationVariables>(NotifyCovidStatDocument, options);
      }
export type NotifyCovidStatMutationHookResult = ReturnType<typeof useNotifyCovidStatMutation>;
export type NotifyCovidStatMutationResult = Apollo.MutationResult<NotifyCovidStatMutation>;
export type NotifyCovidStatMutationOptions = Apollo.BaseMutationOptions<NotifyCovidStatMutation, NotifyCovidStatMutationVariables>;
export const UpdateCovidStatDocument = gql`
    mutation UpdateCovidStat($cat1: Int!, $cat2: Int!, $cat3: Int!, $cat4: Int!, $cat5: Int!) {
  updateCovidStat(
    input: {cat1: $cat1, cat2: $cat2, cat3: $cat3, cat4: $cat4, cat5: $cat5}
  ) {
    covidStat {
      ...CovidStat
    }
    errors {
      ...Error
    }
  }
}
    ${CovidStatFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdateCovidStatMutationFn = Apollo.MutationFunction<UpdateCovidStatMutation, UpdateCovidStatMutationVariables>;

/**
 * __useUpdateCovidStatMutation__
 *
 * To run a mutation, you first call `useUpdateCovidStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCovidStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCovidStatMutation, { data, loading, error }] = useUpdateCovidStatMutation({
 *   variables: {
 *      cat1: // value for 'cat1'
 *      cat2: // value for 'cat2'
 *      cat3: // value for 'cat3'
 *      cat4: // value for 'cat4'
 *      cat5: // value for 'cat5'
 *   },
 * });
 */
export function useUpdateCovidStatMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCovidStatMutation, UpdateCovidStatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCovidStatMutation, UpdateCovidStatMutationVariables>(UpdateCovidStatDocument, options);
      }
export type UpdateCovidStatMutationHookResult = ReturnType<typeof useUpdateCovidStatMutation>;
export type UpdateCovidStatMutationResult = Apollo.MutationResult<UpdateCovidStatMutation>;
export type UpdateCovidStatMutationOptions = Apollo.BaseMutationOptions<UpdateCovidStatMutation, UpdateCovidStatMutationVariables>;
export const CreateKeywordDocument = gql`
    mutation CreateKeyword($language: String!, $keywordString: String!) {
  createKeyword(data: {language: $language, keywordString: $keywordString}) {
    success
  }
}
    `;
export type CreateKeywordMutationFn = Apollo.MutationFunction<CreateKeywordMutation, CreateKeywordMutationVariables>;

/**
 * __useCreateKeywordMutation__
 *
 * To run a mutation, you first call `useCreateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeywordMutation, { data, loading, error }] = useCreateKeywordMutation({
 *   variables: {
 *      language: // value for 'language'
 *      keywordString: // value for 'keywordString'
 *   },
 * });
 */
export function useCreateKeywordMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeywordMutation, CreateKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKeywordMutation, CreateKeywordMutationVariables>(CreateKeywordDocument, options);
      }
export type CreateKeywordMutationHookResult = ReturnType<typeof useCreateKeywordMutation>;
export type CreateKeywordMutationResult = Apollo.MutationResult<CreateKeywordMutation>;
export type CreateKeywordMutationOptions = Apollo.BaseMutationOptions<CreateKeywordMutation, CreateKeywordMutationVariables>;
export const UpdateKeywordDocument = gql`
    mutation UpdateKeyword($keywordId: ID!, $position: Int!, $language: String!, $keywordString: String!) {
  updateKeyword(
    keywordId: $keywordId
    data: {language: $language, keywordString: $keywordString, position: $position}
  ) {
    success
  }
}
    `;
export type UpdateKeywordMutationFn = Apollo.MutationFunction<UpdateKeywordMutation, UpdateKeywordMutationVariables>;

/**
 * __useUpdateKeywordMutation__
 *
 * To run a mutation, you first call `useUpdateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeywordMutation, { data, loading, error }] = useUpdateKeywordMutation({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *      position: // value for 'position'
 *      language: // value for 'language'
 *      keywordString: // value for 'keywordString'
 *   },
 * });
 */
export function useUpdateKeywordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKeywordMutation, UpdateKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKeywordMutation, UpdateKeywordMutationVariables>(UpdateKeywordDocument, options);
      }
export type UpdateKeywordMutationHookResult = ReturnType<typeof useUpdateKeywordMutation>;
export type UpdateKeywordMutationResult = Apollo.MutationResult<UpdateKeywordMutation>;
export type UpdateKeywordMutationOptions = Apollo.BaseMutationOptions<UpdateKeywordMutation, UpdateKeywordMutationVariables>;
export const DeleteNewPublisherNotificationDocument = gql`
    mutation DeleteNewPublisherNotification($id: ID!) {
  deleteNewPublisherNotification(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteNewPublisherNotificationMutationFn = Apollo.MutationFunction<DeleteNewPublisherNotificationMutation, DeleteNewPublisherNotificationMutationVariables>;

/**
 * __useDeleteNewPublisherNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNewPublisherNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewPublisherNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewPublisherNotificationMutation, { data, loading, error }] = useDeleteNewPublisherNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewPublisherNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNewPublisherNotificationMutation, DeleteNewPublisherNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNewPublisherNotificationMutation, DeleteNewPublisherNotificationMutationVariables>(DeleteNewPublisherNotificationDocument, options);
      }
export type DeleteNewPublisherNotificationMutationHookResult = ReturnType<typeof useDeleteNewPublisherNotificationMutation>;
export type DeleteNewPublisherNotificationMutationResult = Apollo.MutationResult<DeleteNewPublisherNotificationMutation>;
export type DeleteNewPublisherNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNewPublisherNotificationMutation, DeleteNewPublisherNotificationMutationVariables>;
export const AddCategoryDocument = gql`
    mutation AddCategory($ids: [ID!]!, $categoryName: String!) {
  addCategory(ids: $ids, categoryName: $categoryName) {
    news {
      id
      categories {
        id
        name
      }
    }
  }
}
    `;
export type AddCategoryMutationFn = Apollo.MutationFunction<AddCategoryMutation, AddCategoryMutationVariables>;

/**
 * __useAddCategoryMutation__
 *
 * To run a mutation, you first call `useAddCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryMutation, { data, loading, error }] = useAddCategoryMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function useAddCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoryMutation, AddCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoryMutation, AddCategoryMutationVariables>(AddCategoryDocument, options);
      }
export type AddCategoryMutationHookResult = ReturnType<typeof useAddCategoryMutation>;
export type AddCategoryMutationResult = Apollo.MutationResult<AddCategoryMutation>;
export type AddCategoryMutationOptions = Apollo.BaseMutationOptions<AddCategoryMutation, AddCategoryMutationVariables>;
export const AddExtraCovidCategoryDocument = gql`
    mutation AddExtraCovidCategory($id: ID!) {
  addExtraCovidCategory(id: $id) {
    news {
      id
      categories {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AddExtraCovidCategoryMutationFn = Apollo.MutationFunction<AddExtraCovidCategoryMutation, AddExtraCovidCategoryMutationVariables>;

/**
 * __useAddExtraCovidCategoryMutation__
 *
 * To run a mutation, you first call `useAddExtraCovidCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExtraCovidCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExtraCovidCategoryMutation, { data, loading, error }] = useAddExtraCovidCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddExtraCovidCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddExtraCovidCategoryMutation, AddExtraCovidCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddExtraCovidCategoryMutation, AddExtraCovidCategoryMutationVariables>(AddExtraCovidCategoryDocument, options);
      }
export type AddExtraCovidCategoryMutationHookResult = ReturnType<typeof useAddExtraCovidCategoryMutation>;
export type AddExtraCovidCategoryMutationResult = Apollo.MutationResult<AddExtraCovidCategoryMutation>;
export type AddExtraCovidCategoryMutationOptions = Apollo.BaseMutationOptions<AddExtraCovidCategoryMutation, AddExtraCovidCategoryMutationVariables>;
export const CrawlNewsDocument = gql`
    mutation CrawlNews($originalUrl: String!, $newsPublisherId: ID!, $categoryNames: [String!]!) {
  crawlNews(
    input: {originalUrl: $originalUrl, newsPublisherId: $newsPublisherId, categoryNames: $categoryNames}
  ) {
    news {
      id
      title
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CrawlNewsMutationFn = Apollo.MutationFunction<CrawlNewsMutation, CrawlNewsMutationVariables>;

/**
 * __useCrawlNewsMutation__
 *
 * To run a mutation, you first call `useCrawlNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrawlNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crawlNewsMutation, { data, loading, error }] = useCrawlNewsMutation({
 *   variables: {
 *      originalUrl: // value for 'originalUrl'
 *      newsPublisherId: // value for 'newsPublisherId'
 *      categoryNames: // value for 'categoryNames'
 *   },
 * });
 */
export function useCrawlNewsMutation(baseOptions?: Apollo.MutationHookOptions<CrawlNewsMutation, CrawlNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrawlNewsMutation, CrawlNewsMutationVariables>(CrawlNewsDocument, options);
      }
export type CrawlNewsMutationHookResult = ReturnType<typeof useCrawlNewsMutation>;
export type CrawlNewsMutationResult = Apollo.MutationResult<CrawlNewsMutation>;
export type CrawlNewsMutationOptions = Apollo.BaseMutationOptions<CrawlNewsMutation, CrawlNewsMutationVariables>;
export const CrawlNewsContentDocument = gql`
    mutation CrawlNewsContent($id: ID!) {
  crawlNewsContent(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CrawlNewsContentMutationFn = Apollo.MutationFunction<CrawlNewsContentMutation, CrawlNewsContentMutationVariables>;

/**
 * __useCrawlNewsContentMutation__
 *
 * To run a mutation, you first call `useCrawlNewsContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrawlNewsContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crawlNewsContentMutation, { data, loading, error }] = useCrawlNewsContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCrawlNewsContentMutation(baseOptions?: Apollo.MutationHookOptions<CrawlNewsContentMutation, CrawlNewsContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrawlNewsContentMutation, CrawlNewsContentMutationVariables>(CrawlNewsContentDocument, options);
      }
export type CrawlNewsContentMutationHookResult = ReturnType<typeof useCrawlNewsContentMutation>;
export type CrawlNewsContentMutationResult = Apollo.MutationResult<CrawlNewsContentMutation>;
export type CrawlNewsContentMutationOptions = Apollo.BaseMutationOptions<CrawlNewsContentMutation, CrawlNewsContentMutationVariables>;
export const DecrawlNewsContentDocument = gql`
    mutation DecrawlNewsContent($id: ID!) {
  decrawlNewsContent(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DecrawlNewsContentMutationFn = Apollo.MutationFunction<DecrawlNewsContentMutation, DecrawlNewsContentMutationVariables>;

/**
 * __useDecrawlNewsContentMutation__
 *
 * To run a mutation, you first call `useDecrawlNewsContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecrawlNewsContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decrawlNewsContentMutation, { data, loading, error }] = useDecrawlNewsContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDecrawlNewsContentMutation(baseOptions?: Apollo.MutationHookOptions<DecrawlNewsContentMutation, DecrawlNewsContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DecrawlNewsContentMutation, DecrawlNewsContentMutationVariables>(DecrawlNewsContentDocument, options);
      }
export type DecrawlNewsContentMutationHookResult = ReturnType<typeof useDecrawlNewsContentMutation>;
export type DecrawlNewsContentMutationResult = Apollo.MutationResult<DecrawlNewsContentMutation>;
export type DecrawlNewsContentMutationOptions = Apollo.BaseMutationOptions<DecrawlNewsContentMutation, DecrawlNewsContentMutationVariables>;
export const DeleteNewsDocument = gql`
    mutation DeleteNews($id: ID!) {
  deleteNews(id: $id) {
    news {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteNewsMutationFn = Apollo.MutationFunction<DeleteNewsMutation, DeleteNewsMutationVariables>;

/**
 * __useDeleteNewsMutation__
 *
 * To run a mutation, you first call `useDeleteNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsMutation, { data, loading, error }] = useDeleteNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNewsMutation, DeleteNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNewsMutation, DeleteNewsMutationVariables>(DeleteNewsDocument, options);
      }
export type DeleteNewsMutationHookResult = ReturnType<typeof useDeleteNewsMutation>;
export type DeleteNewsMutationResult = Apollo.MutationResult<DeleteNewsMutation>;
export type DeleteNewsMutationOptions = Apollo.BaseMutationOptions<DeleteNewsMutation, DeleteNewsMutationVariables>;
export const EditNewsDocument = gql`
    mutation EditNews($id: ID!, $title: String, $description: String, $content: String, $readerMode: String, $image: File) {
  editNews(
    id: $id
    input: {title: $title, description: $description, content: $content, readerMode: $readerMode, image: $image}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type EditNewsMutationFn = Apollo.MutationFunction<EditNewsMutation, EditNewsMutationVariables>;

/**
 * __useEditNewsMutation__
 *
 * To run a mutation, you first call `useEditNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNewsMutation, { data, loading, error }] = useEditNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      content: // value for 'content'
 *      readerMode: // value for 'readerMode'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useEditNewsMutation(baseOptions?: Apollo.MutationHookOptions<EditNewsMutation, EditNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNewsMutation, EditNewsMutationVariables>(EditNewsDocument, options);
      }
export type EditNewsMutationHookResult = ReturnType<typeof useEditNewsMutation>;
export type EditNewsMutationResult = Apollo.MutationResult<EditNewsMutation>;
export type EditNewsMutationOptions = Apollo.BaseMutationOptions<EditNewsMutation, EditNewsMutationVariables>;
export const FetchImageDocument = gql`
    mutation fetchImage($id: ID!) {
  fetchImage(id: $id) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type FetchImageMutationFn = Apollo.MutationFunction<FetchImageMutation, FetchImageMutationVariables>;

/**
 * __useFetchImageMutation__
 *
 * To run a mutation, you first call `useFetchImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchImageMutation, { data, loading, error }] = useFetchImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchImageMutation(baseOptions?: Apollo.MutationHookOptions<FetchImageMutation, FetchImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FetchImageMutation, FetchImageMutationVariables>(FetchImageDocument, options);
      }
export type FetchImageMutationHookResult = ReturnType<typeof useFetchImageMutation>;
export type FetchImageMutationResult = Apollo.MutationResult<FetchImageMutation>;
export type FetchImageMutationOptions = Apollo.BaseMutationOptions<FetchImageMutation, FetchImageMutationVariables>;
export const PinNewsDocument = gql`
    mutation PinNews($id: ID!, $languageCode: String!) {
  pinNews(id: $id, languageCode: $languageCode) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type PinNewsMutationFn = Apollo.MutationFunction<PinNewsMutation, PinNewsMutationVariables>;

/**
 * __usePinNewsMutation__
 *
 * To run a mutation, you first call `usePinNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinNewsMutation, { data, loading, error }] = usePinNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function usePinNewsMutation(baseOptions?: Apollo.MutationHookOptions<PinNewsMutation, PinNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinNewsMutation, PinNewsMutationVariables>(PinNewsDocument, options);
      }
export type PinNewsMutationHookResult = ReturnType<typeof usePinNewsMutation>;
export type PinNewsMutationResult = Apollo.MutationResult<PinNewsMutation>;
export type PinNewsMutationOptions = Apollo.BaseMutationOptions<PinNewsMutation, PinNewsMutationVariables>;
export const RemoveCategoryDocument = gql`
    mutation RemoveCategory($id: ID!, $categoryName: String!) {
  removeCategory(id: $id, categoryName: $categoryName) {
    news {
      id
      categories {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveCategoryMutationFn = Apollo.MutationFunction<RemoveCategoryMutation, RemoveCategoryMutationVariables>;

/**
 * __useRemoveCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCategoryMutation, { data, loading, error }] = useRemoveCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function useRemoveCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCategoryMutation, RemoveCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCategoryMutation, RemoveCategoryMutationVariables>(RemoveCategoryDocument, options);
      }
export type RemoveCategoryMutationHookResult = ReturnType<typeof useRemoveCategoryMutation>;
export type RemoveCategoryMutationResult = Apollo.MutationResult<RemoveCategoryMutation>;
export type RemoveCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveCategoryMutation, RemoveCategoryMutationVariables>;
export const SetGoodNewsDocument = gql`
    mutation setGoodNews($id: ID!) {
  setGoodNews(id: $id) {
    news {
      id
      good
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type SetGoodNewsMutationFn = Apollo.MutationFunction<SetGoodNewsMutation, SetGoodNewsMutationVariables>;

/**
 * __useSetGoodNewsMutation__
 *
 * To run a mutation, you first call `useSetGoodNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGoodNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGoodNewsMutation, { data, loading, error }] = useSetGoodNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetGoodNewsMutation(baseOptions?: Apollo.MutationHookOptions<SetGoodNewsMutation, SetGoodNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGoodNewsMutation, SetGoodNewsMutationVariables>(SetGoodNewsDocument, options);
      }
export type SetGoodNewsMutationHookResult = ReturnType<typeof useSetGoodNewsMutation>;
export type SetGoodNewsMutationResult = Apollo.MutationResult<SetGoodNewsMutation>;
export type SetGoodNewsMutationOptions = Apollo.BaseMutationOptions<SetGoodNewsMutation, SetGoodNewsMutationVariables>;
export const UnpinNewsDocument = gql`
    mutation UnpinNews($languageCode: String!) {
  unpinNews(languageCode: $languageCode) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UnpinNewsMutationFn = Apollo.MutationFunction<UnpinNewsMutation, UnpinNewsMutationVariables>;

/**
 * __useUnpinNewsMutation__
 *
 * To run a mutation, you first call `useUnpinNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinNewsMutation, { data, loading, error }] = useUnpinNewsMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useUnpinNewsMutation(baseOptions?: Apollo.MutationHookOptions<UnpinNewsMutation, UnpinNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpinNewsMutation, UnpinNewsMutationVariables>(UnpinNewsDocument, options);
      }
export type UnpinNewsMutationHookResult = ReturnType<typeof useUnpinNewsMutation>;
export type UnpinNewsMutationResult = Apollo.MutationResult<UnpinNewsMutation>;
export type UnpinNewsMutationOptions = Apollo.BaseMutationOptions<UnpinNewsMutation, UnpinNewsMutationVariables>;
export const CreateNewsNotificationDocument = gql`
    mutation CreateNewsNotification($title: String!, $description: String!, $newsId: ID!, $customNotificationIds: [ID!]!, $sendAt: ISO8601DateTime, $highPriority: Boolean) {
  createNewsNotification(
    input: {title: $title, description: $description, newsId: $newsId, customNotificationIds: $customNotificationIds, sendAt: $sendAt, highPriority: $highPriority}
  ) {
    newsNotifications {
      ...NewsNotification
    }
    errors {
      ...Error
    }
  }
}
    ${NewsNotificationFragmentDoc}
${ErrorFragmentDoc}`;
export type CreateNewsNotificationMutationFn = Apollo.MutationFunction<CreateNewsNotificationMutation, CreateNewsNotificationMutationVariables>;

/**
 * __useCreateNewsNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNewsNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsNotificationMutation, { data, loading, error }] = useCreateNewsNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      newsId: // value for 'newsId'
 *      customNotificationIds: // value for 'customNotificationIds'
 *      sendAt: // value for 'sendAt'
 *      highPriority: // value for 'highPriority'
 *   },
 * });
 */
export function useCreateNewsNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsNotificationMutation, CreateNewsNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewsNotificationMutation, CreateNewsNotificationMutationVariables>(CreateNewsNotificationDocument, options);
      }
export type CreateNewsNotificationMutationHookResult = ReturnType<typeof useCreateNewsNotificationMutation>;
export type CreateNewsNotificationMutationResult = Apollo.MutationResult<CreateNewsNotificationMutation>;
export type CreateNewsNotificationMutationOptions = Apollo.BaseMutationOptions<CreateNewsNotificationMutation, CreateNewsNotificationMutationVariables>;
export const DeleteNewsNotificationDocument = gql`
    mutation DeleteNewsNotification($id: ID!) {
  deleteNewsNotification(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteNewsNotificationMutationFn = Apollo.MutationFunction<DeleteNewsNotificationMutation, DeleteNewsNotificationMutationVariables>;

/**
 * __useDeleteNewsNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNewsNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsNotificationMutation, { data, loading, error }] = useDeleteNewsNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewsNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNewsNotificationMutation, DeleteNewsNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNewsNotificationMutation, DeleteNewsNotificationMutationVariables>(DeleteNewsNotificationDocument, options);
      }
export type DeleteNewsNotificationMutationHookResult = ReturnType<typeof useDeleteNewsNotificationMutation>;
export type DeleteNewsNotificationMutationResult = Apollo.MutationResult<DeleteNewsNotificationMutation>;
export type DeleteNewsNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNewsNotificationMutation, DeleteNewsNotificationMutationVariables>;
export const CreatePrn2023ContentNotificationDocument = gql`
    mutation CreatePrn2023ContentNotification($contentId: ID!, $contentType: String!, $title: String!, $description: String!, $states: [String!]!) {
  createPrn2023ContentNotification(
    input: {contentId: $contentId, contentType: $contentType, title: $title, description: $description, states: $states}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreatePrn2023ContentNotificationMutationFn = Apollo.MutationFunction<CreatePrn2023ContentNotificationMutation, CreatePrn2023ContentNotificationMutationVariables>;

/**
 * __useCreatePrn2023ContentNotificationMutation__
 *
 * To run a mutation, you first call `useCreatePrn2023ContentNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrn2023ContentNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrn2023ContentNotificationMutation, { data, loading, error }] = useCreatePrn2023ContentNotificationMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      contentType: // value for 'contentType'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useCreatePrn2023ContentNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrn2023ContentNotificationMutation, CreatePrn2023ContentNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrn2023ContentNotificationMutation, CreatePrn2023ContentNotificationMutationVariables>(CreatePrn2023ContentNotificationDocument, options);
      }
export type CreatePrn2023ContentNotificationMutationHookResult = ReturnType<typeof useCreatePrn2023ContentNotificationMutation>;
export type CreatePrn2023ContentNotificationMutationResult = Apollo.MutationResult<CreatePrn2023ContentNotificationMutation>;
export type CreatePrn2023ContentNotificationMutationOptions = Apollo.BaseMutationOptions<CreatePrn2023ContentNotificationMutation, CreatePrn2023ContentNotificationMutationVariables>;
export const CreatePrn2023GenericNotificationDocument = gql`
    mutation CreatePrn2023GenericNotification($titleLocale: LocaleInput!, $descriptionLocale: LocaleInput!, $path: String!, $states: [String!]!) {
  createPrn2023GenericNotification(
    input: {titleLocale: $titleLocale, descriptionLocale: $descriptionLocale, path: $path, states: $states}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreatePrn2023GenericNotificationMutationFn = Apollo.MutationFunction<CreatePrn2023GenericNotificationMutation, CreatePrn2023GenericNotificationMutationVariables>;

/**
 * __useCreatePrn2023GenericNotificationMutation__
 *
 * To run a mutation, you first call `useCreatePrn2023GenericNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrn2023GenericNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrn2023GenericNotificationMutation, { data, loading, error }] = useCreatePrn2023GenericNotificationMutation({
 *   variables: {
 *      titleLocale: // value for 'titleLocale'
 *      descriptionLocale: // value for 'descriptionLocale'
 *      path: // value for 'path'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useCreatePrn2023GenericNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrn2023GenericNotificationMutation, CreatePrn2023GenericNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrn2023GenericNotificationMutation, CreatePrn2023GenericNotificationMutationVariables>(CreatePrn2023GenericNotificationDocument, options);
      }
export type CreatePrn2023GenericNotificationMutationHookResult = ReturnType<typeof useCreatePrn2023GenericNotificationMutation>;
export type CreatePrn2023GenericNotificationMutationResult = Apollo.MutationResult<CreatePrn2023GenericNotificationMutation>;
export type CreatePrn2023GenericNotificationMutationOptions = Apollo.BaseMutationOptions<CreatePrn2023GenericNotificationMutation, CreatePrn2023GenericNotificationMutationVariables>;
export const CreatePrn2023NewsStackNotificationDocument = gql`
    mutation CreatePrn2023NewsStackNotification($titleLocale: LocaleInput!, $descriptionLocale: LocaleInput!, $trendingTopicId: ID!, $states: [String!]!) {
  createPrn2023NewsStackNotification(
    input: {titleLocale: $titleLocale, descriptionLocale: $descriptionLocale, trendingTopicId: $trendingTopicId, states: $states}
  ) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type CreatePrn2023NewsStackNotificationMutationFn = Apollo.MutationFunction<CreatePrn2023NewsStackNotificationMutation, CreatePrn2023NewsStackNotificationMutationVariables>;

/**
 * __useCreatePrn2023NewsStackNotificationMutation__
 *
 * To run a mutation, you first call `useCreatePrn2023NewsStackNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrn2023NewsStackNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrn2023NewsStackNotificationMutation, { data, loading, error }] = useCreatePrn2023NewsStackNotificationMutation({
 *   variables: {
 *      titleLocale: // value for 'titleLocale'
 *      descriptionLocale: // value for 'descriptionLocale'
 *      trendingTopicId: // value for 'trendingTopicId'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useCreatePrn2023NewsStackNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrn2023NewsStackNotificationMutation, CreatePrn2023NewsStackNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrn2023NewsStackNotificationMutation, CreatePrn2023NewsStackNotificationMutationVariables>(CreatePrn2023NewsStackNotificationDocument, options);
      }
export type CreatePrn2023NewsStackNotificationMutationHookResult = ReturnType<typeof useCreatePrn2023NewsStackNotificationMutation>;
export type CreatePrn2023NewsStackNotificationMutationResult = Apollo.MutationResult<CreatePrn2023NewsStackNotificationMutation>;
export type CreatePrn2023NewsStackNotificationMutationOptions = Apollo.BaseMutationOptions<CreatePrn2023NewsStackNotificationMutation, CreatePrn2023NewsStackNotificationMutationVariables>;
export const SendAnnouncementNotificationDocument = gql`
    mutation SendAnnouncementNotification($title: String!, $path: String!, $languageCode: String!, $description: String, $image: File, $targetNonPrn2023NotiPreference: Boolean, $targetChatNoti: Boolean) {
  sendAnnouncementNotification(
    title: $title
    description: $description
    languageCode: $languageCode
    path: $path
    image: $image
    targetNonPrn2023NotiPreference: $targetNonPrn2023NotiPreference
    targetChatNoti: $targetChatNoti
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type SendAnnouncementNotificationMutationFn = Apollo.MutationFunction<SendAnnouncementNotificationMutation, SendAnnouncementNotificationMutationVariables>;

/**
 * __useSendAnnouncementNotificationMutation__
 *
 * To run a mutation, you first call `useSendAnnouncementNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAnnouncementNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAnnouncementNotificationMutation, { data, loading, error }] = useSendAnnouncementNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      path: // value for 'path'
 *      languageCode: // value for 'languageCode'
 *      description: // value for 'description'
 *      image: // value for 'image'
 *      targetNonPrn2023NotiPreference: // value for 'targetNonPrn2023NotiPreference'
 *      targetChatNoti: // value for 'targetChatNoti'
 *   },
 * });
 */
export function useSendAnnouncementNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendAnnouncementNotificationMutation, SendAnnouncementNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAnnouncementNotificationMutation, SendAnnouncementNotificationMutationVariables>(SendAnnouncementNotificationDocument, options);
      }
export type SendAnnouncementNotificationMutationHookResult = ReturnType<typeof useSendAnnouncementNotificationMutation>;
export type SendAnnouncementNotificationMutationResult = Apollo.MutationResult<SendAnnouncementNotificationMutation>;
export type SendAnnouncementNotificationMutationOptions = Apollo.BaseMutationOptions<SendAnnouncementNotificationMutation, SendAnnouncementNotificationMutationVariables>;
export const SendNewPublisherNotificationDocument = gql`
    mutation SendNewPublisherNotification($title: String!, $description: String!, $publisherId: ID!, $sendAt: ISO8601DateTime) {
  sendNewPublisherNotification(
    input: {title: $title, description: $description, publisherId: $publisherId, sendAt: $sendAt}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type SendNewPublisherNotificationMutationFn = Apollo.MutationFunction<SendNewPublisherNotificationMutation, SendNewPublisherNotificationMutationVariables>;

/**
 * __useSendNewPublisherNotificationMutation__
 *
 * To run a mutation, you first call `useSendNewPublisherNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNewPublisherNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNewPublisherNotificationMutation, { data, loading, error }] = useSendNewPublisherNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      publisherId: // value for 'publisherId'
 *      sendAt: // value for 'sendAt'
 *   },
 * });
 */
export function useSendNewPublisherNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendNewPublisherNotificationMutation, SendNewPublisherNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNewPublisherNotificationMutation, SendNewPublisherNotificationMutationVariables>(SendNewPublisherNotificationDocument, options);
      }
export type SendNewPublisherNotificationMutationHookResult = ReturnType<typeof useSendNewPublisherNotificationMutation>;
export type SendNewPublisherNotificationMutationResult = Apollo.MutationResult<SendNewPublisherNotificationMutation>;
export type SendNewPublisherNotificationMutationOptions = Apollo.BaseMutationOptions<SendNewPublisherNotificationMutation, SendNewPublisherNotificationMutationVariables>;
export const SendPublisherNotificationDocument = gql`
    mutation SendPublisherNotification($languageIds: [ID!]!, $title: String!, $description: String) {
  sendPublisherNotification(
    input: {languageIds: $languageIds, title: $title, description: $description}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type SendPublisherNotificationMutationFn = Apollo.MutationFunction<SendPublisherNotificationMutation, SendPublisherNotificationMutationVariables>;

/**
 * __useSendPublisherNotificationMutation__
 *
 * To run a mutation, you first call `useSendPublisherNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPublisherNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPublisherNotificationMutation, { data, loading, error }] = useSendPublisherNotificationMutation({
 *   variables: {
 *      languageIds: // value for 'languageIds'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSendPublisherNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendPublisherNotificationMutation, SendPublisherNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPublisherNotificationMutation, SendPublisherNotificationMutationVariables>(SendPublisherNotificationDocument, options);
      }
export type SendPublisherNotificationMutationHookResult = ReturnType<typeof useSendPublisherNotificationMutation>;
export type SendPublisherNotificationMutationResult = Apollo.MutationResult<SendPublisherNotificationMutation>;
export type SendPublisherNotificationMutationOptions = Apollo.BaseMutationOptions<SendPublisherNotificationMutation, SendPublisherNotificationMutationVariables>;
export const UpdatePublisherDocument = gql`
    mutation UpdatePublisher($id: ID!, $name: String, $url: String, $active: Boolean, $new: Boolean, $order: Int, $image: File, $vector: File, $icon: File, $description: String, $email: String, $phoneNumber: String, $badges: String) {
  updatePublisher(
    id: $id
    input: {name: $name, active: $active, new: $new, order: $order, image: $image, vector: $vector, url: $url, icon: $icon, description: $description, email: $email, phoneNumber: $phoneNumber, badges: $badges}
  ) {
    publisher {
      ...Publisher
    }
    errors {
      ...Error
    }
  }
}
    ${PublisherFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdatePublisherMutationFn = Apollo.MutationFunction<UpdatePublisherMutation, UpdatePublisherMutationVariables>;

/**
 * __useUpdatePublisherMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherMutation, { data, loading, error }] = useUpdatePublisherMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      active: // value for 'active'
 *      new: // value for 'new'
 *      order: // value for 'order'
 *      image: // value for 'image'
 *      vector: // value for 'vector'
 *      icon: // value for 'icon'
 *      description: // value for 'description'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      badges: // value for 'badges'
 *   },
 * });
 */
export function useUpdatePublisherMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherMutation, UpdatePublisherMutationVariables>(UpdatePublisherDocument, options);
      }
export type UpdatePublisherMutationHookResult = ReturnType<typeof useUpdatePublisherMutation>;
export type UpdatePublisherMutationResult = Apollo.MutationResult<UpdatePublisherMutation>;
export type UpdatePublisherMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>;
export const EditReportDocument = gql`
    mutation EditReport($reportIds: [ID!]!, $type: String!) {
  editReport(reportIds: $reportIds, type: $type) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type EditReportMutationFn = Apollo.MutationFunction<EditReportMutation, EditReportMutationVariables>;

/**
 * __useEditReportMutation__
 *
 * To run a mutation, you first call `useEditReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportMutation, { data, loading, error }] = useEditReportMutation({
 *   variables: {
 *      reportIds: // value for 'reportIds'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useEditReportMutation(baseOptions?: Apollo.MutationHookOptions<EditReportMutation, EditReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReportMutation, EditReportMutationVariables>(EditReportDocument, options);
      }
export type EditReportMutationHookResult = ReturnType<typeof useEditReportMutation>;
export type EditReportMutationResult = Apollo.MutationResult<EditReportMutation>;
export type EditReportMutationOptions = Apollo.BaseMutationOptions<EditReportMutation, EditReportMutationVariables>;
export const CopyBlockToLangRichContentDocument = gql`
    mutation CopyBlockToLangRichContent($categoryId: ID!, $languageCodes: [String!]!, $blocks: [JSON!]!) {
  copyBlockToLangRichContent(
    categoryId: $categoryId
    languageCodes: $languageCodes
    blocks: $blocks
  ) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type CopyBlockToLangRichContentMutationFn = Apollo.MutationFunction<CopyBlockToLangRichContentMutation, CopyBlockToLangRichContentMutationVariables>;

/**
 * __useCopyBlockToLangRichContentMutation__
 *
 * To run a mutation, you first call `useCopyBlockToLangRichContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyBlockToLangRichContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyBlockToLangRichContentMutation, { data, loading, error }] = useCopyBlockToLangRichContentMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCodes: // value for 'languageCodes'
 *      blocks: // value for 'blocks'
 *   },
 * });
 */
export function useCopyBlockToLangRichContentMutation(baseOptions?: Apollo.MutationHookOptions<CopyBlockToLangRichContentMutation, CopyBlockToLangRichContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyBlockToLangRichContentMutation, CopyBlockToLangRichContentMutationVariables>(CopyBlockToLangRichContentDocument, options);
      }
export type CopyBlockToLangRichContentMutationHookResult = ReturnType<typeof useCopyBlockToLangRichContentMutation>;
export type CopyBlockToLangRichContentMutationResult = Apollo.MutationResult<CopyBlockToLangRichContentMutation>;
export type CopyBlockToLangRichContentMutationOptions = Apollo.BaseMutationOptions<CopyBlockToLangRichContentMutation, CopyBlockToLangRichContentMutationVariables>;
export const CreateRichStacksDocument = gql`
    mutation CreateRichStacks($categoryId: ID!, $languageCode: ID) {
  createRichContent(categoryId: $categoryId, languageCode: $languageCode) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateRichStacksMutationFn = Apollo.MutationFunction<CreateRichStacksMutation, CreateRichStacksMutationVariables>;

/**
 * __useCreateRichStacksMutation__
 *
 * To run a mutation, you first call `useCreateRichStacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRichStacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRichStacksMutation, { data, loading, error }] = useCreateRichStacksMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useCreateRichStacksMutation(baseOptions?: Apollo.MutationHookOptions<CreateRichStacksMutation, CreateRichStacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRichStacksMutation, CreateRichStacksMutationVariables>(CreateRichStacksDocument, options);
      }
export type CreateRichStacksMutationHookResult = ReturnType<typeof useCreateRichStacksMutation>;
export type CreateRichStacksMutationResult = Apollo.MutationResult<CreateRichStacksMutation>;
export type CreateRichStacksMutationOptions = Apollo.BaseMutationOptions<CreateRichStacksMutation, CreateRichStacksMutationVariables>;
export const DeleteRichContentDocument = gql`
    mutation DeleteRichContent($categoryId: ID!, $languageCode: ID!) {
  deleteRichContent(categoryId: $categoryId, languageCode: $languageCode) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type DeleteRichContentMutationFn = Apollo.MutationFunction<DeleteRichContentMutation, DeleteRichContentMutationVariables>;

/**
 * __useDeleteRichContentMutation__
 *
 * To run a mutation, you first call `useDeleteRichContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRichContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRichContentMutation, { data, loading, error }] = useDeleteRichContentMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useDeleteRichContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRichContentMutation, DeleteRichContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRichContentMutation, DeleteRichContentMutationVariables>(DeleteRichContentDocument, options);
      }
export type DeleteRichContentMutationHookResult = ReturnType<typeof useDeleteRichContentMutation>;
export type DeleteRichContentMutationResult = Apollo.MutationResult<DeleteRichContentMutation>;
export type DeleteRichContentMutationOptions = Apollo.BaseMutationOptions<DeleteRichContentMutation, DeleteRichContentMutationVariables>;
export const DuplicateRichStacksDocument = gql`
    mutation DuplicateRichStacks($categoryId: ID!, $languageCode: String, $fromLanguageCode: String!, $languageCodes: [String!]) {
  duplicateRichContent(
    categoryId: $categoryId
    languageCode: $languageCode
    fromLanguageCode: $fromLanguageCode
    languageCodes: $languageCodes
  ) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type DuplicateRichStacksMutationFn = Apollo.MutationFunction<DuplicateRichStacksMutation, DuplicateRichStacksMutationVariables>;

/**
 * __useDuplicateRichStacksMutation__
 *
 * To run a mutation, you first call `useDuplicateRichStacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRichStacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRichStacksMutation, { data, loading, error }] = useDuplicateRichStacksMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCode: // value for 'languageCode'
 *      fromLanguageCode: // value for 'fromLanguageCode'
 *      languageCodes: // value for 'languageCodes'
 *   },
 * });
 */
export function useDuplicateRichStacksMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateRichStacksMutation, DuplicateRichStacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateRichStacksMutation, DuplicateRichStacksMutationVariables>(DuplicateRichStacksDocument, options);
      }
export type DuplicateRichStacksMutationHookResult = ReturnType<typeof useDuplicateRichStacksMutation>;
export type DuplicateRichStacksMutationResult = Apollo.MutationResult<DuplicateRichStacksMutation>;
export type DuplicateRichStacksMutationOptions = Apollo.BaseMutationOptions<DuplicateRichStacksMutation, DuplicateRichStacksMutationVariables>;
export const UpdateRichStacksDocument = gql`
    mutation updateRichStacks($categoryId: ID!, $data: JSON!, $publish: Boolean, $languageCode: String, $updatePublishedAt: Boolean) {
  updateRichContent(
    categoryId: $categoryId
    data: $data
    publish: $publish
    languageCode: $languageCode
    updatePublishedAt: $updatePublishedAt
  ) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateRichStacksMutationFn = Apollo.MutationFunction<UpdateRichStacksMutation, UpdateRichStacksMutationVariables>;

/**
 * __useUpdateRichStacksMutation__
 *
 * To run a mutation, you first call `useUpdateRichStacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRichStacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRichStacksMutation, { data, loading, error }] = useUpdateRichStacksMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      data: // value for 'data'
 *      publish: // value for 'publish'
 *      languageCode: // value for 'languageCode'
 *      updatePublishedAt: // value for 'updatePublishedAt'
 *   },
 * });
 */
export function useUpdateRichStacksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRichStacksMutation, UpdateRichStacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRichStacksMutation, UpdateRichStacksMutationVariables>(UpdateRichStacksDocument, options);
      }
export type UpdateRichStacksMutationHookResult = ReturnType<typeof useUpdateRichStacksMutation>;
export type UpdateRichStacksMutationResult = Apollo.MutationResult<UpdateRichStacksMutation>;
export type UpdateRichStacksMutationOptions = Apollo.BaseMutationOptions<UpdateRichStacksMutation, UpdateRichStacksMutationVariables>;
export const UploadImageRichStacksDocument = gql`
    mutation UploadImageRichStacks($categoryId: ID!, $image: File!, $languageCode: String) {
  uploadImageRichContent(
    categoryId: $categoryId
    image: $image
    languageCode: $languageCode
  ) {
    success
    imageUrl
    errors {
      path
      detail
    }
  }
}
    `;
export type UploadImageRichStacksMutationFn = Apollo.MutationFunction<UploadImageRichStacksMutation, UploadImageRichStacksMutationVariables>;

/**
 * __useUploadImageRichStacksMutation__
 *
 * To run a mutation, you first call `useUploadImageRichStacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageRichStacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageRichStacksMutation, { data, loading, error }] = useUploadImageRichStacksMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      image: // value for 'image'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useUploadImageRichStacksMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageRichStacksMutation, UploadImageRichStacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageRichStacksMutation, UploadImageRichStacksMutationVariables>(UploadImageRichStacksDocument, options);
      }
export type UploadImageRichStacksMutationHookResult = ReturnType<typeof useUploadImageRichStacksMutation>;
export type UploadImageRichStacksMutationResult = Apollo.MutationResult<UploadImageRichStacksMutation>;
export type UploadImageRichStacksMutationOptions = Apollo.BaseMutationOptions<UploadImageRichStacksMutation, UploadImageRichStacksMutationVariables>;
export const AddSuggestedCategoriesToTopicDocument = gql`
    mutation AddSuggestedCategoriesToTopic($id: ID!, $suggestedCategories: [AddSuggestedCategoriesToTopicInput!]!) {
  addSuggestedCategoriesToTopic(
    id: $id
    suggestedCategories: $suggestedCategories
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AddSuggestedCategoriesToTopicMutationFn = Apollo.MutationFunction<AddSuggestedCategoriesToTopicMutation, AddSuggestedCategoriesToTopicMutationVariables>;

/**
 * __useAddSuggestedCategoriesToTopicMutation__
 *
 * To run a mutation, you first call `useAddSuggestedCategoriesToTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSuggestedCategoriesToTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSuggestedCategoriesToTopicMutation, { data, loading, error }] = useAddSuggestedCategoriesToTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      suggestedCategories: // value for 'suggestedCategories'
 *   },
 * });
 */
export function useAddSuggestedCategoriesToTopicMutation(baseOptions?: Apollo.MutationHookOptions<AddSuggestedCategoriesToTopicMutation, AddSuggestedCategoriesToTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSuggestedCategoriesToTopicMutation, AddSuggestedCategoriesToTopicMutationVariables>(AddSuggestedCategoriesToTopicDocument, options);
      }
export type AddSuggestedCategoriesToTopicMutationHookResult = ReturnType<typeof useAddSuggestedCategoriesToTopicMutation>;
export type AddSuggestedCategoriesToTopicMutationResult = Apollo.MutationResult<AddSuggestedCategoriesToTopicMutation>;
export type AddSuggestedCategoriesToTopicMutationOptions = Apollo.BaseMutationOptions<AddSuggestedCategoriesToTopicMutation, AddSuggestedCategoriesToTopicMutationVariables>;
export const CreateAuthorForTopicDocument = gql`
    mutation CreateAuthorForTopic($categoryId: ID!, $nameLocale: LocaleInput!, $image: File, $authorType: String!, $verified: Boolean, $path: String) {
  createAuthorForTopic(
    categoryId: $categoryId
    input: {nameLocale: $nameLocale, image: $image, authorType: $authorType, verified: $verified, path: $path}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateAuthorForTopicMutationFn = Apollo.MutationFunction<CreateAuthorForTopicMutation, CreateAuthorForTopicMutationVariables>;

/**
 * __useCreateAuthorForTopicMutation__
 *
 * To run a mutation, you first call `useCreateAuthorForTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorForTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthorForTopicMutation, { data, loading, error }] = useCreateAuthorForTopicMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      nameLocale: // value for 'nameLocale'
 *      image: // value for 'image'
 *      authorType: // value for 'authorType'
 *      verified: // value for 'verified'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useCreateAuthorForTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthorForTopicMutation, CreateAuthorForTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthorForTopicMutation, CreateAuthorForTopicMutationVariables>(CreateAuthorForTopicDocument, options);
      }
export type CreateAuthorForTopicMutationHookResult = ReturnType<typeof useCreateAuthorForTopicMutation>;
export type CreateAuthorForTopicMutationResult = Apollo.MutationResult<CreateAuthorForTopicMutation>;
export type CreateAuthorForTopicMutationOptions = Apollo.BaseMutationOptions<CreateAuthorForTopicMutation, CreateAuthorForTopicMutationVariables>;
export const CreateTopicDocument = gql`
    mutation CreateTopic($name: String!, $emoji: String, $order: Int, $show: Boolean, $newStatus: Boolean, $hot: Boolean, $explore: Boolean, $image: File, $thumbnail: File, $nameLocale: LocaleInput, $exploreNameLocale: LocaleInput, $webTitleLocale: LocaleInput, $descriptionLocale: LocaleInput, $isNewsStack: Boolean) {
  createTopic(
    input: {name: $name, emoji: $emoji, order: $order, show: $show, explore: $explore, newStatus: $newStatus, hot: $hot, image: $image, thumbnail: $thumbnail, nameLocale: $nameLocale, exploreNameLocale: $exploreNameLocale, webTitleLocale: $webTitleLocale, descriptionLocale: $descriptionLocale, isNewsStack: $isNewsStack}
  ) {
    topic {
      ...Topic
    }
    errors {
      ...Error
    }
  }
}
    ${TopicFragmentDoc}
${ErrorFragmentDoc}`;
export type CreateTopicMutationFn = Apollo.MutationFunction<CreateTopicMutation, CreateTopicMutationVariables>;

/**
 * __useCreateTopicMutation__
 *
 * To run a mutation, you first call `useCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicMutation, { data, loading, error }] = useCreateTopicMutation({
 *   variables: {
 *      name: // value for 'name'
 *      emoji: // value for 'emoji'
 *      order: // value for 'order'
 *      show: // value for 'show'
 *      newStatus: // value for 'newStatus'
 *      hot: // value for 'hot'
 *      explore: // value for 'explore'
 *      image: // value for 'image'
 *      thumbnail: // value for 'thumbnail'
 *      nameLocale: // value for 'nameLocale'
 *      exploreNameLocale: // value for 'exploreNameLocale'
 *      webTitleLocale: // value for 'webTitleLocale'
 *      descriptionLocale: // value for 'descriptionLocale'
 *      isNewsStack: // value for 'isNewsStack'
 *   },
 * });
 */
export function useCreateTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicMutation, CreateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(CreateTopicDocument, options);
      }
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<CreateTopicMutation>;
export type CreateTopicMutationOptions = Apollo.BaseMutationOptions<CreateTopicMutation, CreateTopicMutationVariables>;
export const CreateTopicFeedDocument = gql`
    mutation createTopicFeed($categoryId: ID!, $languageCode: ID!) {
  createTopicFeed(categoryId: $categoryId, languageCode: $languageCode) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateTopicFeedMutationFn = Apollo.MutationFunction<CreateTopicFeedMutation, CreateTopicFeedMutationVariables>;

/**
 * __useCreateTopicFeedMutation__
 *
 * To run a mutation, you first call `useCreateTopicFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicFeedMutation, { data, loading, error }] = useCreateTopicFeedMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useCreateTopicFeedMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicFeedMutation, CreateTopicFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicFeedMutation, CreateTopicFeedMutationVariables>(CreateTopicFeedDocument, options);
      }
export type CreateTopicFeedMutationHookResult = ReturnType<typeof useCreateTopicFeedMutation>;
export type CreateTopicFeedMutationResult = Apollo.MutationResult<CreateTopicFeedMutation>;
export type CreateTopicFeedMutationOptions = Apollo.BaseMutationOptions<CreateTopicFeedMutation, CreateTopicFeedMutationVariables>;
export const DeleteTopicDocument = gql`
    mutation DeleteTopic($topicId: ID!) {
  deleteTopic(topicId: $topicId) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteTopicMutationFn = Apollo.MutationFunction<DeleteTopicMutation, DeleteTopicMutationVariables>;

/**
 * __useDeleteTopicMutation__
 *
 * To run a mutation, you first call `useDeleteTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicMutation, { data, loading, error }] = useDeleteTopicMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useDeleteTopicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicMutation, DeleteTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(DeleteTopicDocument, options);
      }
export type DeleteTopicMutationHookResult = ReturnType<typeof useDeleteTopicMutation>;
export type DeleteTopicMutationResult = Apollo.MutationResult<DeleteTopicMutation>;
export type DeleteTopicMutationOptions = Apollo.BaseMutationOptions<DeleteTopicMutation, DeleteTopicMutationVariables>;
export const DeleteTopicThumbnailDocument = gql`
    mutation DeleteTopicThumbnail($id: ID!) {
  deleteTopicThumbnail(topicId: $id) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type DeleteTopicThumbnailMutationFn = Apollo.MutationFunction<DeleteTopicThumbnailMutation, DeleteTopicThumbnailMutationVariables>;

/**
 * __useDeleteTopicThumbnailMutation__
 *
 * To run a mutation, you first call `useDeleteTopicThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicThumbnailMutation, { data, loading, error }] = useDeleteTopicThumbnailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTopicThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicThumbnailMutation, DeleteTopicThumbnailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicThumbnailMutation, DeleteTopicThumbnailMutationVariables>(DeleteTopicThumbnailDocument, options);
      }
export type DeleteTopicThumbnailMutationHookResult = ReturnType<typeof useDeleteTopicThumbnailMutation>;
export type DeleteTopicThumbnailMutationResult = Apollo.MutationResult<DeleteTopicThumbnailMutation>;
export type DeleteTopicThumbnailMutationOptions = Apollo.BaseMutationOptions<DeleteTopicThumbnailMutation, DeleteTopicThumbnailMutationVariables>;
export const DeleteTrendingTopicNotificationDocument = gql`
    mutation DeleteTrendingTopicNotification($id: ID!) {
  deleteTrendingTopicNotification(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteTrendingTopicNotificationMutationFn = Apollo.MutationFunction<DeleteTrendingTopicNotificationMutation, DeleteTrendingTopicNotificationMutationVariables>;

/**
 * __useDeleteTrendingTopicNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteTrendingTopicNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrendingTopicNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrendingTopicNotificationMutation, { data, loading, error }] = useDeleteTrendingTopicNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrendingTopicNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrendingTopicNotificationMutation, DeleteTrendingTopicNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrendingTopicNotificationMutation, DeleteTrendingTopicNotificationMutationVariables>(DeleteTrendingTopicNotificationDocument, options);
      }
export type DeleteTrendingTopicNotificationMutationHookResult = ReturnType<typeof useDeleteTrendingTopicNotificationMutation>;
export type DeleteTrendingTopicNotificationMutationResult = Apollo.MutationResult<DeleteTrendingTopicNotificationMutation>;
export type DeleteTrendingTopicNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteTrendingTopicNotificationMutation, DeleteTrendingTopicNotificationMutationVariables>;
export const RemoveSuggestedCategoryFromTopicDocument = gql`
    mutation RemoveSuggestedCategoryFromTopic($id: ID!, $suggestedCategoryId: ID!) {
  removeSuggestedCategoryFromTopic(
    id: $id
    suggestedCategoryId: $suggestedCategoryId
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveSuggestedCategoryFromTopicMutationFn = Apollo.MutationFunction<RemoveSuggestedCategoryFromTopicMutation, RemoveSuggestedCategoryFromTopicMutationVariables>;

/**
 * __useRemoveSuggestedCategoryFromTopicMutation__
 *
 * To run a mutation, you first call `useRemoveSuggestedCategoryFromTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSuggestedCategoryFromTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSuggestedCategoryFromTopicMutation, { data, loading, error }] = useRemoveSuggestedCategoryFromTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      suggestedCategoryId: // value for 'suggestedCategoryId'
 *   },
 * });
 */
export function useRemoveSuggestedCategoryFromTopicMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSuggestedCategoryFromTopicMutation, RemoveSuggestedCategoryFromTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSuggestedCategoryFromTopicMutation, RemoveSuggestedCategoryFromTopicMutationVariables>(RemoveSuggestedCategoryFromTopicDocument, options);
      }
export type RemoveSuggestedCategoryFromTopicMutationHookResult = ReturnType<typeof useRemoveSuggestedCategoryFromTopicMutation>;
export type RemoveSuggestedCategoryFromTopicMutationResult = Apollo.MutationResult<RemoveSuggestedCategoryFromTopicMutation>;
export type RemoveSuggestedCategoryFromTopicMutationOptions = Apollo.BaseMutationOptions<RemoveSuggestedCategoryFromTopicMutation, RemoveSuggestedCategoryFromTopicMutationVariables>;
export const SendTrendingTopicNotificationDocument = gql`
    mutation SendTrendingTopicNotification($trendingTopicId: ID!, $titleLocale: LocaleInput!, $sendAt: ISO8601DateTime, $descriptionLocale: LocaleInput, $highPriority: Boolean) {
  sendTrendingTopicNotification(
    input: {trendingTopicId: $trendingTopicId, titleLocale: $titleLocale, sendAt: $sendAt, descriptionLocale: $descriptionLocale, highPriority: $highPriority}
  ) {
    newsStackNotification {
      id
      titleLocale {
        ...Locale
      }
      createdAt
      sendAt
      category {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${LocaleFragmentDoc}
${ErrorFragmentDoc}`;
export type SendTrendingTopicNotificationMutationFn = Apollo.MutationFunction<SendTrendingTopicNotificationMutation, SendTrendingTopicNotificationMutationVariables>;

/**
 * __useSendTrendingTopicNotificationMutation__
 *
 * To run a mutation, you first call `useSendTrendingTopicNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrendingTopicNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrendingTopicNotificationMutation, { data, loading, error }] = useSendTrendingTopicNotificationMutation({
 *   variables: {
 *      trendingTopicId: // value for 'trendingTopicId'
 *      titleLocale: // value for 'titleLocale'
 *      sendAt: // value for 'sendAt'
 *      descriptionLocale: // value for 'descriptionLocale'
 *      highPriority: // value for 'highPriority'
 *   },
 * });
 */
export function useSendTrendingTopicNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendTrendingTopicNotificationMutation, SendTrendingTopicNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTrendingTopicNotificationMutation, SendTrendingTopicNotificationMutationVariables>(SendTrendingTopicNotificationDocument, options);
      }
export type SendTrendingTopicNotificationMutationHookResult = ReturnType<typeof useSendTrendingTopicNotificationMutation>;
export type SendTrendingTopicNotificationMutationResult = Apollo.MutationResult<SendTrendingTopicNotificationMutation>;
export type SendTrendingTopicNotificationMutationOptions = Apollo.BaseMutationOptions<SendTrendingTopicNotificationMutation, SendTrendingTopicNotificationMutationVariables>;
export const UpdateTopicDocument = gql`
    mutation UpdateTopic($id: ID!, $name: String, $emoji: String, $order: Int, $show: Boolean, $newStatus: Boolean, $hot: Boolean, $updated: Boolean, $exclusive: Boolean, $explore: Boolean, $image: File, $thumbnail: File, $nameLocale: LocaleInput, $exploreNameLocale: LocaleInput, $webTitleLocale: LocaleInput, $descriptionLocale: LocaleInput, $languages: JSON, $lastEditedAt: ISO8601DateTime, $updatePublishedAt: Boolean, $searchable: Boolean) {
  updateTopic(
    id: $id
    lastEditedAt: $lastEditedAt
    updatePublishedAt: $updatePublishedAt
    input: {name: $name, emoji: $emoji, order: $order, show: $show, explore: $explore, newStatus: $newStatus, hot: $hot, updated: $updated, exclusive: $exclusive, image: $image, thumbnail: $thumbnail, nameLocale: $nameLocale, exploreNameLocale: $exploreNameLocale, webTitleLocale: $webTitleLocale, descriptionLocale: $descriptionLocale, languages: $languages, searchable: $searchable}
  ) {
    topic {
      ...Topic
    }
    errors {
      ...Error
    }
  }
}
    ${TopicFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdateTopicMutationFn = Apollo.MutationFunction<UpdateTopicMutation, UpdateTopicMutationVariables>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      emoji: // value for 'emoji'
 *      order: // value for 'order'
 *      show: // value for 'show'
 *      newStatus: // value for 'newStatus'
 *      hot: // value for 'hot'
 *      updated: // value for 'updated'
 *      exclusive: // value for 'exclusive'
 *      explore: // value for 'explore'
 *      image: // value for 'image'
 *      thumbnail: // value for 'thumbnail'
 *      nameLocale: // value for 'nameLocale'
 *      exploreNameLocale: // value for 'exploreNameLocale'
 *      webTitleLocale: // value for 'webTitleLocale'
 *      descriptionLocale: // value for 'descriptionLocale'
 *      languages: // value for 'languages'
 *      lastEditedAt: // value for 'lastEditedAt'
 *      updatePublishedAt: // value for 'updatePublishedAt'
 *      searchable: // value for 'searchable'
 *   },
 * });
 */
export function useUpdateTopicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, options);
      }
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<UpdateTopicMutation, UpdateTopicMutationVariables>;
export const UpdateTopicFeedDocument = gql`
    mutation UpdateTopicFeed($categoryId: ID!, $languageCode: ID!, $data: JSON!, $publish: Boolean) {
  updateTopicFeed(
    categoryId: $categoryId
    languageCode: $languageCode
    publish: $publish
    data: $data
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateTopicFeedMutationFn = Apollo.MutationFunction<UpdateTopicFeedMutation, UpdateTopicFeedMutationVariables>;

/**
 * __useUpdateTopicFeedMutation__
 *
 * To run a mutation, you first call `useUpdateTopicFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicFeedMutation, { data, loading, error }] = useUpdateTopicFeedMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCode: // value for 'languageCode'
 *      data: // value for 'data'
 *      publish: // value for 'publish'
 *   },
 * });
 */
export function useUpdateTopicFeedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicFeedMutation, UpdateTopicFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicFeedMutation, UpdateTopicFeedMutationVariables>(UpdateTopicFeedDocument, options);
      }
export type UpdateTopicFeedMutationHookResult = ReturnType<typeof useUpdateTopicFeedMutation>;
export type UpdateTopicFeedMutationResult = Apollo.MutationResult<UpdateTopicFeedMutation>;
export type UpdateTopicFeedMutationOptions = Apollo.BaseMutationOptions<UpdateTopicFeedMutation, UpdateTopicFeedMutationVariables>;
export const AddCategoryToTopicStackDocument = gql`
    mutation AddCategoryToTopicStack($id: ID!, $categoryIds: [ID!]!) {
  addCategoryToTopicStack(id: $id, categoryIds: $categoryIds) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AddCategoryToTopicStackMutationFn = Apollo.MutationFunction<AddCategoryToTopicStackMutation, AddCategoryToTopicStackMutationVariables>;

/**
 * __useAddCategoryToTopicStackMutation__
 *
 * To run a mutation, you first call `useAddCategoryToTopicStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryToTopicStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryToTopicStackMutation, { data, loading, error }] = useAddCategoryToTopicStackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useAddCategoryToTopicStackMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoryToTopicStackMutation, AddCategoryToTopicStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoryToTopicStackMutation, AddCategoryToTopicStackMutationVariables>(AddCategoryToTopicStackDocument, options);
      }
export type AddCategoryToTopicStackMutationHookResult = ReturnType<typeof useAddCategoryToTopicStackMutation>;
export type AddCategoryToTopicStackMutationResult = Apollo.MutationResult<AddCategoryToTopicStackMutation>;
export type AddCategoryToTopicStackMutationOptions = Apollo.BaseMutationOptions<AddCategoryToTopicStackMutation, AddCategoryToTopicStackMutationVariables>;
export const CreateTopicStackDocument = gql`
    mutation CreateTopicStack($title: String!, $titleLocale: LocaleInput, $descriptionLocale: LocaleInput) {
  createTopicStack(
    input: {title: $title, titleLocale: $titleLocale, descriptionLocale: $descriptionLocale}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateTopicStackMutationFn = Apollo.MutationFunction<CreateTopicStackMutation, CreateTopicStackMutationVariables>;

/**
 * __useCreateTopicStackMutation__
 *
 * To run a mutation, you first call `useCreateTopicStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicStackMutation, { data, loading, error }] = useCreateTopicStackMutation({
 *   variables: {
 *      title: // value for 'title'
 *      titleLocale: // value for 'titleLocale'
 *      descriptionLocale: // value for 'descriptionLocale'
 *   },
 * });
 */
export function useCreateTopicStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicStackMutation, CreateTopicStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicStackMutation, CreateTopicStackMutationVariables>(CreateTopicStackDocument, options);
      }
export type CreateTopicStackMutationHookResult = ReturnType<typeof useCreateTopicStackMutation>;
export type CreateTopicStackMutationResult = Apollo.MutationResult<CreateTopicStackMutation>;
export type CreateTopicStackMutationOptions = Apollo.BaseMutationOptions<CreateTopicStackMutation, CreateTopicStackMutationVariables>;
export const RemoveCategoryFromTopicStackDocument = gql`
    mutation RemoveCategoryFromTopicStack($id: ID!, $categoryId: ID!) {
  removeCategoryFromTopicStack(id: $id, categoryId: $categoryId) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveCategoryFromTopicStackMutationFn = Apollo.MutationFunction<RemoveCategoryFromTopicStackMutation, RemoveCategoryFromTopicStackMutationVariables>;

/**
 * __useRemoveCategoryFromTopicStackMutation__
 *
 * To run a mutation, you first call `useRemoveCategoryFromTopicStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCategoryFromTopicStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCategoryFromTopicStackMutation, { data, loading, error }] = useRemoveCategoryFromTopicStackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRemoveCategoryFromTopicStackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCategoryFromTopicStackMutation, RemoveCategoryFromTopicStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCategoryFromTopicStackMutation, RemoveCategoryFromTopicStackMutationVariables>(RemoveCategoryFromTopicStackDocument, options);
      }
export type RemoveCategoryFromTopicStackMutationHookResult = ReturnType<typeof useRemoveCategoryFromTopicStackMutation>;
export type RemoveCategoryFromTopicStackMutationResult = Apollo.MutationResult<RemoveCategoryFromTopicStackMutation>;
export type RemoveCategoryFromTopicStackMutationOptions = Apollo.BaseMutationOptions<RemoveCategoryFromTopicStackMutation, RemoveCategoryFromTopicStackMutationVariables>;
export const UpdateTopicStackDocument = gql`
    mutation UpdateTopicStack($id: ID!, $title: String, $titleLocale: LocaleInput, $descriptionLocale: LocaleInput) {
  updateTopicStack(
    id: $id
    input: {title: $title, titleLocale: $titleLocale, descriptionLocale: $descriptionLocale}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateTopicStackMutationFn = Apollo.MutationFunction<UpdateTopicStackMutation, UpdateTopicStackMutationVariables>;

/**
 * __useUpdateTopicStackMutation__
 *
 * To run a mutation, you first call `useUpdateTopicStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicStackMutation, { data, loading, error }] = useUpdateTopicStackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      titleLocale: // value for 'titleLocale'
 *      descriptionLocale: // value for 'descriptionLocale'
 *   },
 * });
 */
export function useUpdateTopicStackMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicStackMutation, UpdateTopicStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicStackMutation, UpdateTopicStackMutationVariables>(UpdateTopicStackDocument, options);
      }
export type UpdateTopicStackMutationHookResult = ReturnType<typeof useUpdateTopicStackMutation>;
export type UpdateTopicStackMutationResult = Apollo.MutationResult<UpdateTopicStackMutation>;
export type UpdateTopicStackMutationOptions = Apollo.BaseMutationOptions<UpdateTopicStackMutation, UpdateTopicStackMutationVariables>;
export const BanUserDocument = gql`
    mutation BanUser($userId: ID!, $isBan: Boolean!) {
  banUser(userId: $userId, isBan: $isBan) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type BanUserMutationFn = Apollo.MutationFunction<BanUserMutation, BanUserMutationVariables>;

/**
 * __useBanUserMutation__
 *
 * To run a mutation, you first call `useBanUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banUserMutation, { data, loading, error }] = useBanUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isBan: // value for 'isBan'
 *   },
 * });
 */
export function useBanUserMutation(baseOptions?: Apollo.MutationHookOptions<BanUserMutation, BanUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BanUserMutation, BanUserMutationVariables>(BanUserDocument, options);
      }
export type BanUserMutationHookResult = ReturnType<typeof useBanUserMutation>;
export type BanUserMutationResult = Apollo.MutationResult<BanUserMutation>;
export type BanUserMutationOptions = Apollo.BaseMutationOptions<BanUserMutation, BanUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const AddChatroomToVideoDocument = gql`
    mutation AddChatroomToVideo($id: ID!, $live: Boolean!) {
  addChatroomToVideo(id: $id, live: $live) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AddChatroomToVideoMutationFn = Apollo.MutationFunction<AddChatroomToVideoMutation, AddChatroomToVideoMutationVariables>;

/**
 * __useAddChatroomToVideoMutation__
 *
 * To run a mutation, you first call `useAddChatroomToVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChatroomToVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChatroomToVideoMutation, { data, loading, error }] = useAddChatroomToVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      live: // value for 'live'
 *   },
 * });
 */
export function useAddChatroomToVideoMutation(baseOptions?: Apollo.MutationHookOptions<AddChatroomToVideoMutation, AddChatroomToVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChatroomToVideoMutation, AddChatroomToVideoMutationVariables>(AddChatroomToVideoDocument, options);
      }
export type AddChatroomToVideoMutationHookResult = ReturnType<typeof useAddChatroomToVideoMutation>;
export type AddChatroomToVideoMutationResult = Apollo.MutationResult<AddChatroomToVideoMutation>;
export type AddChatroomToVideoMutationOptions = Apollo.BaseMutationOptions<AddChatroomToVideoMutation, AddChatroomToVideoMutationVariables>;
export const AddFeaturedVideoDocument = gql`
    mutation AddFeaturedVideo($id: ID!, $languageCode: String!) {
  featureVideo(id: $id, languageCode: $languageCode) {
    video {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type AddFeaturedVideoMutationFn = Apollo.MutationFunction<AddFeaturedVideoMutation, AddFeaturedVideoMutationVariables>;

/**
 * __useAddFeaturedVideoMutation__
 *
 * To run a mutation, you first call `useAddFeaturedVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeaturedVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeaturedVideoMutation, { data, loading, error }] = useAddFeaturedVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useAddFeaturedVideoMutation(baseOptions?: Apollo.MutationHookOptions<AddFeaturedVideoMutation, AddFeaturedVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFeaturedVideoMutation, AddFeaturedVideoMutationVariables>(AddFeaturedVideoDocument, options);
      }
export type AddFeaturedVideoMutationHookResult = ReturnType<typeof useAddFeaturedVideoMutation>;
export type AddFeaturedVideoMutationResult = Apollo.MutationResult<AddFeaturedVideoMutation>;
export type AddFeaturedVideoMutationOptions = Apollo.BaseMutationOptions<AddFeaturedVideoMutation, AddFeaturedVideoMutationVariables>;
export const AddFeaturedGe15VideoDocument = gql`
    mutation AddFeaturedGe15Video($id: ID!, $languageCode: String!) {
  featureGe15Video(id: $id, languageCode: $languageCode) {
    video {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type AddFeaturedGe15VideoMutationFn = Apollo.MutationFunction<AddFeaturedGe15VideoMutation, AddFeaturedGe15VideoMutationVariables>;

/**
 * __useAddFeaturedGe15VideoMutation__
 *
 * To run a mutation, you first call `useAddFeaturedGe15VideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeaturedGe15VideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeaturedGe15VideoMutation, { data, loading, error }] = useAddFeaturedGe15VideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useAddFeaturedGe15VideoMutation(baseOptions?: Apollo.MutationHookOptions<AddFeaturedGe15VideoMutation, AddFeaturedGe15VideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFeaturedGe15VideoMutation, AddFeaturedGe15VideoMutationVariables>(AddFeaturedGe15VideoDocument, options);
      }
export type AddFeaturedGe15VideoMutationHookResult = ReturnType<typeof useAddFeaturedGe15VideoMutation>;
export type AddFeaturedGe15VideoMutationResult = Apollo.MutationResult<AddFeaturedGe15VideoMutation>;
export type AddFeaturedGe15VideoMutationOptions = Apollo.BaseMutationOptions<AddFeaturedGe15VideoMutation, AddFeaturedGe15VideoMutationVariables>;
export const AddVideoCategoryDocument = gql`
    mutation AddVideoCategory($ids: [ID!]!, $categoryName: String!) {
  addVideoCategory(ids: $ids, categoryName: $categoryName) {
    videos {
      id
      categories {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AddVideoCategoryMutationFn = Apollo.MutationFunction<AddVideoCategoryMutation, AddVideoCategoryMutationVariables>;

/**
 * __useAddVideoCategoryMutation__
 *
 * To run a mutation, you first call `useAddVideoCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVideoCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVideoCategoryMutation, { data, loading, error }] = useAddVideoCategoryMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function useAddVideoCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddVideoCategoryMutation, AddVideoCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVideoCategoryMutation, AddVideoCategoryMutationVariables>(AddVideoCategoryDocument, options);
      }
export type AddVideoCategoryMutationHookResult = ReturnType<typeof useAddVideoCategoryMutation>;
export type AddVideoCategoryMutationResult = Apollo.MutationResult<AddVideoCategoryMutation>;
export type AddVideoCategoryMutationOptions = Apollo.BaseMutationOptions<AddVideoCategoryMutation, AddVideoCategoryMutationVariables>;
export const CrawlYoutubeVideoDocument = gql`
    mutation CrawlYoutubeVideo($link: String!, $publisherId: ID!, $title: String, $description: String, $isWebViewable: Boolean) {
  crawlYoutubeVideo(
    link: $link
    publisherId: $publisherId
    title: $title
    description: $description
    isWebViewable: $isWebViewable
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CrawlYoutubeVideoMutationFn = Apollo.MutationFunction<CrawlYoutubeVideoMutation, CrawlYoutubeVideoMutationVariables>;

/**
 * __useCrawlYoutubeVideoMutation__
 *
 * To run a mutation, you first call `useCrawlYoutubeVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrawlYoutubeVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crawlYoutubeVideoMutation, { data, loading, error }] = useCrawlYoutubeVideoMutation({
 *   variables: {
 *      link: // value for 'link'
 *      publisherId: // value for 'publisherId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      isWebViewable: // value for 'isWebViewable'
 *   },
 * });
 */
export function useCrawlYoutubeVideoMutation(baseOptions?: Apollo.MutationHookOptions<CrawlYoutubeVideoMutation, CrawlYoutubeVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrawlYoutubeVideoMutation, CrawlYoutubeVideoMutationVariables>(CrawlYoutubeVideoDocument, options);
      }
export type CrawlYoutubeVideoMutationHookResult = ReturnType<typeof useCrawlYoutubeVideoMutation>;
export type CrawlYoutubeVideoMutationResult = Apollo.MutationResult<CrawlYoutubeVideoMutation>;
export type CrawlYoutubeVideoMutationOptions = Apollo.BaseMutationOptions<CrawlYoutubeVideoMutation, CrawlYoutubeVideoMutationVariables>;
export const DeleteFeaturedVideoDocument = gql`
    mutation DeleteFeaturedVideo($languageCode: String!) {
  unfeatureVideo(languageCode: $languageCode) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type DeleteFeaturedVideoMutationFn = Apollo.MutationFunction<DeleteFeaturedVideoMutation, DeleteFeaturedVideoMutationVariables>;

/**
 * __useDeleteFeaturedVideoMutation__
 *
 * To run a mutation, you first call `useDeleteFeaturedVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeaturedVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeaturedVideoMutation, { data, loading, error }] = useDeleteFeaturedVideoMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useDeleteFeaturedVideoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeaturedVideoMutation, DeleteFeaturedVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeaturedVideoMutation, DeleteFeaturedVideoMutationVariables>(DeleteFeaturedVideoDocument, options);
      }
export type DeleteFeaturedVideoMutationHookResult = ReturnType<typeof useDeleteFeaturedVideoMutation>;
export type DeleteFeaturedVideoMutationResult = Apollo.MutationResult<DeleteFeaturedVideoMutation>;
export type DeleteFeaturedVideoMutationOptions = Apollo.BaseMutationOptions<DeleteFeaturedVideoMutation, DeleteFeaturedVideoMutationVariables>;
export const DeleteVideoDocument = gql`
    mutation DeleteVideo($id: ID!) {
  deleteVideo(id: $id) {
    video {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteVideoMutationFn = Apollo.MutationFunction<DeleteVideoMutation, DeleteVideoMutationVariables>;

/**
 * __useDeleteVideoMutation__
 *
 * To run a mutation, you first call `useDeleteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoMutation, { data, loading, error }] = useDeleteVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVideoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideoMutation, DeleteVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideoMutation, DeleteVideoMutationVariables>(DeleteVideoDocument, options);
      }
export type DeleteVideoMutationHookResult = ReturnType<typeof useDeleteVideoMutation>;
export type DeleteVideoMutationResult = Apollo.MutationResult<DeleteVideoMutation>;
export type DeleteVideoMutationOptions = Apollo.BaseMutationOptions<DeleteVideoMutation, DeleteVideoMutationVariables>;
export const DeleteVideoNotificationDocument = gql`
    mutation DeleteVideoNotification($id: ID!) {
  deleteVideoNotification(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteVideoNotificationMutationFn = Apollo.MutationFunction<DeleteVideoNotificationMutation, DeleteVideoNotificationMutationVariables>;

/**
 * __useDeleteVideoNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteVideoNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoNotificationMutation, { data, loading, error }] = useDeleteVideoNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVideoNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideoNotificationMutation, DeleteVideoNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideoNotificationMutation, DeleteVideoNotificationMutationVariables>(DeleteVideoNotificationDocument, options);
      }
export type DeleteVideoNotificationMutationHookResult = ReturnType<typeof useDeleteVideoNotificationMutation>;
export type DeleteVideoNotificationMutationResult = Apollo.MutationResult<DeleteVideoNotificationMutation>;
export type DeleteVideoNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteVideoNotificationMutation, DeleteVideoNotificationMutationVariables>;
export const EditVideoDocument = gql`
    mutation EditVideo($id: ID!, $title: String!, $description: String!, $orientation: String, $isWebViewable: Boolean, $isShort: Boolean, $liveChatId: String) {
  editVideo(
    id: $id
    input: {title: $title, description: $description, orientation: $orientation, isWebViewable: $isWebViewable, isShort: $isShort, liveChatId: $liveChatId}
  ) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type EditVideoMutationFn = Apollo.MutationFunction<EditVideoMutation, EditVideoMutationVariables>;

/**
 * __useEditVideoMutation__
 *
 * To run a mutation, you first call `useEditVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVideoMutation, { data, loading, error }] = useEditVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      orientation: // value for 'orientation'
 *      isWebViewable: // value for 'isWebViewable'
 *      isShort: // value for 'isShort'
 *      liveChatId: // value for 'liveChatId'
 *   },
 * });
 */
export function useEditVideoMutation(baseOptions?: Apollo.MutationHookOptions<EditVideoMutation, EditVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditVideoMutation, EditVideoMutationVariables>(EditVideoDocument, options);
      }
export type EditVideoMutationHookResult = ReturnType<typeof useEditVideoMutation>;
export type EditVideoMutationResult = Apollo.MutationResult<EditVideoMutation>;
export type EditVideoMutationOptions = Apollo.BaseMutationOptions<EditVideoMutation, EditVideoMutationVariables>;
export const RemoveFeaturedVideoDocument = gql`
    mutation RemoveFeaturedVideo($languageCode: String!) {
  unfeatureVideo(languageCode: $languageCode) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type RemoveFeaturedVideoMutationFn = Apollo.MutationFunction<RemoveFeaturedVideoMutation, RemoveFeaturedVideoMutationVariables>;

/**
 * __useRemoveFeaturedVideoMutation__
 *
 * To run a mutation, you first call `useRemoveFeaturedVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFeaturedVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFeaturedVideoMutation, { data, loading, error }] = useRemoveFeaturedVideoMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useRemoveFeaturedVideoMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFeaturedVideoMutation, RemoveFeaturedVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFeaturedVideoMutation, RemoveFeaturedVideoMutationVariables>(RemoveFeaturedVideoDocument, options);
      }
export type RemoveFeaturedVideoMutationHookResult = ReturnType<typeof useRemoveFeaturedVideoMutation>;
export type RemoveFeaturedVideoMutationResult = Apollo.MutationResult<RemoveFeaturedVideoMutation>;
export type RemoveFeaturedVideoMutationOptions = Apollo.BaseMutationOptions<RemoveFeaturedVideoMutation, RemoveFeaturedVideoMutationVariables>;
export const RemoveFeaturedGe15VideoDocument = gql`
    mutation RemoveFeaturedGe15Video($languageCode: String!) {
  unfeatureGe15Video(languageCode: $languageCode) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type RemoveFeaturedGe15VideoMutationFn = Apollo.MutationFunction<RemoveFeaturedGe15VideoMutation, RemoveFeaturedGe15VideoMutationVariables>;

/**
 * __useRemoveFeaturedGe15VideoMutation__
 *
 * To run a mutation, you first call `useRemoveFeaturedGe15VideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFeaturedGe15VideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFeaturedGe15VideoMutation, { data, loading, error }] = useRemoveFeaturedGe15VideoMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useRemoveFeaturedGe15VideoMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFeaturedGe15VideoMutation, RemoveFeaturedGe15VideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFeaturedGe15VideoMutation, RemoveFeaturedGe15VideoMutationVariables>(RemoveFeaturedGe15VideoDocument, options);
      }
export type RemoveFeaturedGe15VideoMutationHookResult = ReturnType<typeof useRemoveFeaturedGe15VideoMutation>;
export type RemoveFeaturedGe15VideoMutationResult = Apollo.MutationResult<RemoveFeaturedGe15VideoMutation>;
export type RemoveFeaturedGe15VideoMutationOptions = Apollo.BaseMutationOptions<RemoveFeaturedGe15VideoMutation, RemoveFeaturedGe15VideoMutationVariables>;
export const RemoveVideoCategoryDocument = gql`
    mutation RemoveVideoCategory($id: ID!, $categoryName: String!) {
  removeVideoCategory(id: $id, categoryName: $categoryName) {
    video {
      id
      categories {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveVideoCategoryMutationFn = Apollo.MutationFunction<RemoveVideoCategoryMutation, RemoveVideoCategoryMutationVariables>;

/**
 * __useRemoveVideoCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveVideoCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVideoCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVideoCategoryMutation, { data, loading, error }] = useRemoveVideoCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function useRemoveVideoCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVideoCategoryMutation, RemoveVideoCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVideoCategoryMutation, RemoveVideoCategoryMutationVariables>(RemoveVideoCategoryDocument, options);
      }
export type RemoveVideoCategoryMutationHookResult = ReturnType<typeof useRemoveVideoCategoryMutation>;
export type RemoveVideoCategoryMutationResult = Apollo.MutationResult<RemoveVideoCategoryMutation>;
export type RemoveVideoCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveVideoCategoryMutation, RemoveVideoCategoryMutationVariables>;
export const CreateVideoNotificationDocument = gql`
    mutation CreateVideoNotification($title: String!, $description: String!, $videoId: ID!, $sendAt: ISO8601DateTime) {
  createVideoNotification(
    input: {title: $title, description: $description, videoId: $videoId, sendAt: $sendAt}
  ) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateVideoNotificationMutationFn = Apollo.MutationFunction<CreateVideoNotificationMutation, CreateVideoNotificationMutationVariables>;

/**
 * __useCreateVideoNotificationMutation__
 *
 * To run a mutation, you first call `useCreateVideoNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoNotificationMutation, { data, loading, error }] = useCreateVideoNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      videoId: // value for 'videoId'
 *      sendAt: // value for 'sendAt'
 *   },
 * });
 */
export function useCreateVideoNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoNotificationMutation, CreateVideoNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoNotificationMutation, CreateVideoNotificationMutationVariables>(CreateVideoNotificationDocument, options);
      }
export type CreateVideoNotificationMutationHookResult = ReturnType<typeof useCreateVideoNotificationMutation>;
export type CreateVideoNotificationMutationResult = Apollo.MutationResult<CreateVideoNotificationMutation>;
export type CreateVideoNotificationMutationOptions = Apollo.BaseMutationOptions<CreateVideoNotificationMutation, CreateVideoNotificationMutationVariables>;
export const AppBannersDocument = gql`
    query AppBanners {
  me {
    id
  }
  appBanners {
    __typename
    id
    key
    updatedAt
    title {
      id
      en
      ms
      zh
    }
    description {
      id
      en
      ms
      zh
    }
    image
    icon
    path
    pathLocale {
      id
      en
      ms
      zh
    }
    buttonTextLocale {
      id
      en
      ms
      zh
    }
    dismissable
    buttonText
    backgroundColorLight
    backgroundColorDark
    buttonBackgroundColorLight
    buttonBackgroundColorDark
    buttonTextColorLight
    buttonTextColorDark
  }
}
    `;

/**
 * __useAppBannersQuery__
 *
 * To run a query within a React component, call `useAppBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppBannersQuery(baseOptions?: Apollo.QueryHookOptions<AppBannersQuery, AppBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppBannersQuery, AppBannersQueryVariables>(AppBannersDocument, options);
      }
export function useAppBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppBannersQuery, AppBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppBannersQuery, AppBannersQueryVariables>(AppBannersDocument, options);
        }
export type AppBannersQueryHookResult = ReturnType<typeof useAppBannersQuery>;
export type AppBannersLazyQueryHookResult = ReturnType<typeof useAppBannersLazyQuery>;
export type AppBannersQueryResult = Apollo.QueryResult<AppBannersQuery, AppBannersQueryVariables>;
export const AuthorPageDocument = gql`
    query AuthorPage($id: ID!) {
  me {
    id
  }
  author(id: $id) {
    ...authorDetails
  }
}
    ${AuthorDetailsFragmentDoc}`;

/**
 * __useAuthorPageQuery__
 *
 * To run a query within a React component, call `useAuthorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuthorPageQuery(baseOptions: Apollo.QueryHookOptions<AuthorPageQuery, AuthorPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorPageQuery, AuthorPageQueryVariables>(AuthorPageDocument, options);
      }
export function useAuthorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorPageQuery, AuthorPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorPageQuery, AuthorPageQueryVariables>(AuthorPageDocument, options);
        }
export type AuthorPageQueryHookResult = ReturnType<typeof useAuthorPageQuery>;
export type AuthorPageLazyQueryHookResult = ReturnType<typeof useAuthorPageLazyQuery>;
export type AuthorPageQueryResult = Apollo.QueryResult<AuthorPageQuery, AuthorPageQueryVariables>;
export const CustomBannerWithPublishersDocument = gql`
    query CustomBannerWithPublishers {
  me {
    id
  }
  customBanner {
    ...CustomBanner
  }
  publishers {
    id
    name
  }
}
    ${CustomBannerFragmentDoc}`;

/**
 * __useCustomBannerWithPublishersQuery__
 *
 * To run a query within a React component, call `useCustomBannerWithPublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBannerWithPublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBannerWithPublishersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomBannerWithPublishersQuery(baseOptions?: Apollo.QueryHookOptions<CustomBannerWithPublishersQuery, CustomBannerWithPublishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBannerWithPublishersQuery, CustomBannerWithPublishersQueryVariables>(CustomBannerWithPublishersDocument, options);
      }
export function useCustomBannerWithPublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBannerWithPublishersQuery, CustomBannerWithPublishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBannerWithPublishersQuery, CustomBannerWithPublishersQueryVariables>(CustomBannerWithPublishersDocument, options);
        }
export type CustomBannerWithPublishersQueryHookResult = ReturnType<typeof useCustomBannerWithPublishersQuery>;
export type CustomBannerWithPublishersLazyQueryHookResult = ReturnType<typeof useCustomBannerWithPublishersLazyQuery>;
export type CustomBannerWithPublishersQueryResult = Apollo.QueryResult<CustomBannerWithPublishersQuery, CustomBannerWithPublishersQueryVariables>;
export const CustomBannerDocument = gql`
    query CustomBanner {
  me {
    id
  }
  customBanner {
    ...CustomBanner
  }
}
    ${CustomBannerFragmentDoc}`;

/**
 * __useCustomBannerQuery__
 *
 * To run a query within a React component, call `useCustomBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomBannerQuery(baseOptions?: Apollo.QueryHookOptions<CustomBannerQuery, CustomBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBannerQuery, CustomBannerQueryVariables>(CustomBannerDocument, options);
      }
export function useCustomBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBannerQuery, CustomBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBannerQuery, CustomBannerQueryVariables>(CustomBannerDocument, options);
        }
export type CustomBannerQueryHookResult = ReturnType<typeof useCustomBannerQuery>;
export type CustomBannerLazyQueryHookResult = ReturnType<typeof useCustomBannerLazyQuery>;
export type CustomBannerQueryResult = Apollo.QueryResult<CustomBannerQuery, CustomBannerQueryVariables>;
export const CampaignPageDocument = gql`
    query CampaignPage {
  me {
    id
  }
  campaign {
    id
    title {
      ...Locale
    }
    subtitle {
      ...Locale
    }
    bannerTitle {
      ...Locale
    }
    bannerSubtitle {
      ...Locale
    }
    description {
      ...Locale
    }
    imageUrl
    bannerImageUrl
  }
}
    ${LocaleFragmentDoc}`;

/**
 * __useCampaignPageQuery__
 *
 * To run a query within a React component, call `useCampaignPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCampaignPageQuery(baseOptions?: Apollo.QueryHookOptions<CampaignPageQuery, CampaignPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignPageQuery, CampaignPageQueryVariables>(CampaignPageDocument, options);
      }
export function useCampaignPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignPageQuery, CampaignPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignPageQuery, CampaignPageQueryVariables>(CampaignPageDocument, options);
        }
export type CampaignPageQueryHookResult = ReturnType<typeof useCampaignPageQuery>;
export type CampaignPageLazyQueryHookResult = ReturnType<typeof useCampaignPageLazyQuery>;
export type CampaignPageQueryResult = Apollo.QueryResult<CampaignPageQuery, CampaignPageQueryVariables>;
export const CampaignInstallsPageDocument = gql`
    query CampaignInstallsPage {
  me {
    id
  }
  appsflyerInvites
}
    `;

/**
 * __useCampaignInstallsPageQuery__
 *
 * To run a query within a React component, call `useCampaignInstallsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignInstallsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignInstallsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCampaignInstallsPageQuery(baseOptions?: Apollo.QueryHookOptions<CampaignInstallsPageQuery, CampaignInstallsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignInstallsPageQuery, CampaignInstallsPageQueryVariables>(CampaignInstallsPageDocument, options);
      }
export function useCampaignInstallsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignInstallsPageQuery, CampaignInstallsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignInstallsPageQuery, CampaignInstallsPageQueryVariables>(CampaignInstallsPageDocument, options);
        }
export type CampaignInstallsPageQueryHookResult = ReturnType<typeof useCampaignInstallsPageQuery>;
export type CampaignInstallsPageLazyQueryHookResult = ReturnType<typeof useCampaignInstallsPageLazyQuery>;
export type CampaignInstallsPageQueryResult = Apollo.QueryResult<CampaignInstallsPageQuery, CampaignInstallsPageQueryVariables>;
export const CarouselDocument = gql`
    query carousel($id: ID!) {
  carousel(id: $id) {
    categories
    createdAt
    id
    carouselContents {
      id
      sponsored
      contentable {
        ... on Category {
          __typename
          id
          name
        }
        ... on News {
          __typename
          id
          title
        }
        ... on Video {
          __typename
          id
          title
        }
      }
    }
    newsPublisher {
      id
      name
    }
  }
}
    `;

/**
 * __useCarouselQuery__
 *
 * To run a query within a React component, call `useCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarouselQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCarouselQuery(baseOptions: Apollo.QueryHookOptions<CarouselQuery, CarouselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarouselQuery, CarouselQueryVariables>(CarouselDocument, options);
      }
export function useCarouselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarouselQuery, CarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarouselQuery, CarouselQueryVariables>(CarouselDocument, options);
        }
export type CarouselQueryHookResult = ReturnType<typeof useCarouselQuery>;
export type CarouselLazyQueryHookResult = ReturnType<typeof useCarouselLazyQuery>;
export type CarouselQueryResult = Apollo.QueryResult<CarouselQuery, CarouselQueryVariables>;
export const CarouselsDocument = gql`
    query carousels($publisherId: ID, $categoryIds: [ID!]) {
  carousels(publisherId: $publisherId, categoryIds: $categoryIds) {
    __typename
    id
    categories
    createdAt
    newsPublisher {
      id
      name
    }
    carouselContents {
      __typename
      id
      contentable {
        ... on News {
          id
          title
        }
        ... on Video {
          __typename
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useCarouselsQuery__
 *
 * To run a query within a React component, call `useCarouselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarouselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarouselsQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useCarouselsQuery(baseOptions?: Apollo.QueryHookOptions<CarouselsQuery, CarouselsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarouselsQuery, CarouselsQueryVariables>(CarouselsDocument, options);
      }
export function useCarouselsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarouselsQuery, CarouselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarouselsQuery, CarouselsQueryVariables>(CarouselsDocument, options);
        }
export type CarouselsQueryHookResult = ReturnType<typeof useCarouselsQuery>;
export type CarouselsLazyQueryHookResult = ReturnType<typeof useCarouselsLazyQuery>;
export type CarouselsQueryResult = Apollo.QueryResult<CarouselsQuery, CarouselsQueryVariables>;
export const ChatIndexPageDocument = gql`
    query ChatIndexPage($ids: [ID!]!) {
  me {
    id
  }
  usersWithIds(ids: $ids) {
    id
    username
    uuid
    avatar
    verified
  }
}
    `;

/**
 * __useChatIndexPageQuery__
 *
 * To run a query within a React component, call `useChatIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatIndexPageQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useChatIndexPageQuery(baseOptions: Apollo.QueryHookOptions<ChatIndexPageQuery, ChatIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatIndexPageQuery, ChatIndexPageQueryVariables>(ChatIndexPageDocument, options);
      }
export function useChatIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatIndexPageQuery, ChatIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatIndexPageQuery, ChatIndexPageQueryVariables>(ChatIndexPageDocument, options);
        }
export type ChatIndexPageQueryHookResult = ReturnType<typeof useChatIndexPageQuery>;
export type ChatIndexPageLazyQueryHookResult = ReturnType<typeof useChatIndexPageLazyQuery>;
export type ChatIndexPageQueryResult = Apollo.QueryResult<ChatIndexPageQuery, ChatIndexPageQueryVariables>;
export const CommentPageDocument = gql`
    query CommentPage($id: ID!) {
  me {
    id
  }
  comment(id: $id) {
    ...Comment
    reports {
      ...Report
    }
  }
}
    ${CommentFragmentDoc}
${ReportFragmentDoc}`;

/**
 * __useCommentPageQuery__
 *
 * To run a query within a React component, call `useCommentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentPageQuery(baseOptions: Apollo.QueryHookOptions<CommentPageQuery, CommentPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentPageQuery, CommentPageQueryVariables>(CommentPageDocument, options);
      }
export function useCommentPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentPageQuery, CommentPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentPageQuery, CommentPageQueryVariables>(CommentPageDocument, options);
        }
export type CommentPageQueryHookResult = ReturnType<typeof useCommentPageQuery>;
export type CommentPageLazyQueryHookResult = ReturnType<typeof useCommentPageLazyQuery>;
export type CommentPageQueryResult = Apollo.QueryResult<CommentPageQuery, CommentPageQueryVariables>;
export const CovidStatDocument = gql`
    query CovidStat {
  me {
    id
  }
  covidStat {
    ...CovidStat
  }
}
    ${CovidStatFragmentDoc}`;

/**
 * __useCovidStatQuery__
 *
 * To run a query within a React component, call `useCovidStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useCovidStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCovidStatQuery({
 *   variables: {
 *   },
 * });
 */
export function useCovidStatQuery(baseOptions?: Apollo.QueryHookOptions<CovidStatQuery, CovidStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CovidStatQuery, CovidStatQueryVariables>(CovidStatDocument, options);
      }
export function useCovidStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CovidStatQuery, CovidStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CovidStatQuery, CovidStatQueryVariables>(CovidStatDocument, options);
        }
export type CovidStatQueryHookResult = ReturnType<typeof useCovidStatQuery>;
export type CovidStatLazyQueryHookResult = ReturnType<typeof useCovidStatLazyQuery>;
export type CovidStatQueryResult = Apollo.QueryResult<CovidStatQuery, CovidStatQueryVariables>;
export const CreatePinnedNewsDocument = gql`
    mutation createPinnedNews($languageCode: String!, $newsId: String!, $key: String!) {
  createPinnedNews(languageCode: $languageCode, newsId: $newsId, key: $key) {
    success
    pinnedNewsArticle {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreatePinnedNewsMutationFn = Apollo.MutationFunction<CreatePinnedNewsMutation, CreatePinnedNewsMutationVariables>;

/**
 * __useCreatePinnedNewsMutation__
 *
 * To run a mutation, you first call `useCreatePinnedNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePinnedNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPinnedNewsMutation, { data, loading, error }] = useCreatePinnedNewsMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      newsId: // value for 'newsId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useCreatePinnedNewsMutation(baseOptions?: Apollo.MutationHookOptions<CreatePinnedNewsMutation, CreatePinnedNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePinnedNewsMutation, CreatePinnedNewsMutationVariables>(CreatePinnedNewsDocument, options);
      }
export type CreatePinnedNewsMutationHookResult = ReturnType<typeof useCreatePinnedNewsMutation>;
export type CreatePinnedNewsMutationResult = Apollo.MutationResult<CreatePinnedNewsMutation>;
export type CreatePinnedNewsMutationOptions = Apollo.BaseMutationOptions<CreatePinnedNewsMutation, CreatePinnedNewsMutationVariables>;
export const CreateNewsPageDocument = gql`
    query CreateNewsPage {
  me {
    id
  }
  publishers {
    id
    name
    active
  }
  categories {
    id
    name
    show
  }
}
    `;

/**
 * __useCreateNewsPageQuery__
 *
 * To run a query within a React component, call `useCreateNewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateNewsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateNewsPageQuery(baseOptions?: Apollo.QueryHookOptions<CreateNewsPageQuery, CreateNewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateNewsPageQuery, CreateNewsPageQueryVariables>(CreateNewsPageDocument, options);
      }
export function useCreateNewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateNewsPageQuery, CreateNewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateNewsPageQuery, CreateNewsPageQueryVariables>(CreateNewsPageDocument, options);
        }
export type CreateNewsPageQueryHookResult = ReturnType<typeof useCreateNewsPageQuery>;
export type CreateNewsPageLazyQueryHookResult = ReturnType<typeof useCreateNewsPageLazyQuery>;
export type CreateNewsPageQueryResult = Apollo.QueryResult<CreateNewsPageQuery, CreateNewsPageQueryVariables>;
export const EditNewsPageDocument = gql`
    query EditNewsPage($newsId: ID!) {
  me {
    id
  }
  news(id: $newsId) {
    id
    title
    description
    content
    imageUrl
    hasNewsContent
    hasComments
    newsContent {
      content
    }
  }
}
    `;

/**
 * __useEditNewsPageQuery__
 *
 * To run a query within a React component, call `useEditNewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditNewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditNewsPageQuery({
 *   variables: {
 *      newsId: // value for 'newsId'
 *   },
 * });
 */
export function useEditNewsPageQuery(baseOptions: Apollo.QueryHookOptions<EditNewsPageQuery, EditNewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditNewsPageQuery, EditNewsPageQueryVariables>(EditNewsPageDocument, options);
      }
export function useEditNewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditNewsPageQuery, EditNewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditNewsPageQuery, EditNewsPageQueryVariables>(EditNewsPageDocument, options);
        }
export type EditNewsPageQueryHookResult = ReturnType<typeof useEditNewsPageQuery>;
export type EditNewsPageLazyQueryHookResult = ReturnType<typeof useEditNewsPageLazyQuery>;
export type EditNewsPageQueryResult = Apollo.QueryResult<EditNewsPageQuery, EditNewsPageQueryVariables>;
export const GetPublishersNewsPageDocument = gql`
    query GetPublishersNewsPage($languageCode: String) {
  publishers(
    type: "active"
    languageCode: $languageCode
    offsetAttributes: {limit: 500}
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetPublishersNewsPageQuery__
 *
 * To run a query within a React component, call `useGetPublishersNewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishersNewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishersNewsPageQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetPublishersNewsPageQuery(baseOptions?: Apollo.QueryHookOptions<GetPublishersNewsPageQuery, GetPublishersNewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishersNewsPageQuery, GetPublishersNewsPageQueryVariables>(GetPublishersNewsPageDocument, options);
      }
export function useGetPublishersNewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishersNewsPageQuery, GetPublishersNewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishersNewsPageQuery, GetPublishersNewsPageQueryVariables>(GetPublishersNewsPageDocument, options);
        }
export type GetPublishersNewsPageQueryHookResult = ReturnType<typeof useGetPublishersNewsPageQuery>;
export type GetPublishersNewsPageLazyQueryHookResult = ReturnType<typeof useGetPublishersNewsPageLazyQuery>;
export type GetPublishersNewsPageQueryResult = Apollo.QueryResult<GetPublishersNewsPageQuery, GetPublishersNewsPageQueryVariables>;
export const AllNewsPageDocument = gql`
    query AllNewsPage {
  me {
    id
  }
  categories(active: true) {
    id
    name
  }
  publishers {
    id
    name
  }
  typesearchAlgoliaFlag {
    typesenseIsOn
  }
}
    `;

/**
 * __useAllNewsPageQuery__
 *
 * To run a query within a React component, call `useAllNewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNewsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNewsPageQuery(baseOptions?: Apollo.QueryHookOptions<AllNewsPageQuery, AllNewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNewsPageQuery, AllNewsPageQueryVariables>(AllNewsPageDocument, options);
      }
export function useAllNewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNewsPageQuery, AllNewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNewsPageQuery, AllNewsPageQueryVariables>(AllNewsPageDocument, options);
        }
export type AllNewsPageQueryHookResult = ReturnType<typeof useAllNewsPageQuery>;
export type AllNewsPageLazyQueryHookResult = ReturnType<typeof useAllNewsPageLazyQuery>;
export type AllNewsPageQueryResult = Apollo.QueryResult<AllNewsPageQuery, AllNewsPageQueryVariables>;
export const PinnedNewsDocument = gql`
    query PinnedNews {
  me {
    id
  }
  pinnedNewsArticles {
    id
    key
    language {
      code
      name
      id
    }
    news {
      id
      title
      description
      pubDate
      newsPublisher {
        ...Publisher
      }
    }
  }
  typesearchAlgoliaFlag {
    typesenseIsOn
  }
}
    ${PublisherFragmentDoc}`;

/**
 * __usePinnedNewsQuery__
 *
 * To run a query within a React component, call `usePinnedNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePinnedNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePinnedNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePinnedNewsQuery(baseOptions?: Apollo.QueryHookOptions<PinnedNewsQuery, PinnedNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PinnedNewsQuery, PinnedNewsQueryVariables>(PinnedNewsDocument, options);
      }
export function usePinnedNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PinnedNewsQuery, PinnedNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PinnedNewsQuery, PinnedNewsQueryVariables>(PinnedNewsDocument, options);
        }
export type PinnedNewsQueryHookResult = ReturnType<typeof usePinnedNewsQuery>;
export type PinnedNewsLazyQueryHookResult = ReturnType<typeof usePinnedNewsLazyQuery>;
export type PinnedNewsQueryResult = Apollo.QueryResult<PinnedNewsQuery, PinnedNewsQueryVariables>;
export const PinnedNewsArticleDocument = gql`
    query pinnedNewsArticle($id: ID!) {
  me {
    id
  }
  pinnedNewsArticleById(id: $id) {
    id
    key
    language {
      code
      name
      id
    }
    news {
      id
      title
      description
      pubDate
      newsPublisher {
        ...Publisher
      }
    }
  }
}
    ${PublisherFragmentDoc}`;

/**
 * __usePinnedNewsArticleQuery__
 *
 * To run a query within a React component, call `usePinnedNewsArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePinnedNewsArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePinnedNewsArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePinnedNewsArticleQuery(baseOptions: Apollo.QueryHookOptions<PinnedNewsArticleQuery, PinnedNewsArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PinnedNewsArticleQuery, PinnedNewsArticleQueryVariables>(PinnedNewsArticleDocument, options);
      }
export function usePinnedNewsArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PinnedNewsArticleQuery, PinnedNewsArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PinnedNewsArticleQuery, PinnedNewsArticleQueryVariables>(PinnedNewsArticleDocument, options);
        }
export type PinnedNewsArticleQueryHookResult = ReturnType<typeof usePinnedNewsArticleQuery>;
export type PinnedNewsArticleLazyQueryHookResult = ReturnType<typeof usePinnedNewsArticleLazyQuery>;
export type PinnedNewsArticleQueryResult = Apollo.QueryResult<PinnedNewsArticleQuery, PinnedNewsArticleQueryVariables>;
export const UpdatePinnedNewsDocument = gql`
    mutation updatePinnedNews($id: ID!, $languageCode: String!, $newsId: String!, $key: String!) {
  updatePinnedNews(
    id: $id
    languageCode: $languageCode
    newsId: $newsId
    key: $key
  ) {
    success
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdatePinnedNewsMutationFn = Apollo.MutationFunction<UpdatePinnedNewsMutation, UpdatePinnedNewsMutationVariables>;

/**
 * __useUpdatePinnedNewsMutation__
 *
 * To run a mutation, you first call `useUpdatePinnedNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePinnedNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePinnedNewsMutation, { data, loading, error }] = useUpdatePinnedNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *      newsId: // value for 'newsId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUpdatePinnedNewsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePinnedNewsMutation, UpdatePinnedNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePinnedNewsMutation, UpdatePinnedNewsMutationVariables>(UpdatePinnedNewsDocument, options);
      }
export type UpdatePinnedNewsMutationHookResult = ReturnType<typeof useUpdatePinnedNewsMutation>;
export type UpdatePinnedNewsMutationResult = Apollo.MutationResult<UpdatePinnedNewsMutation>;
export type UpdatePinnedNewsMutationOptions = Apollo.BaseMutationOptions<UpdatePinnedNewsMutation, UpdatePinnedNewsMutationVariables>;
export const CreateNotificationPageDocument = gql`
    query CreateNotificationPage($newsId: ID!) {
  me {
    id
  }
  news(id: $newsId) {
    id
    title
    description
    hasNewsContent
    hasImageAttached
    newsContent {
      id
      content
      createdAt
      updatedAt
    }
    categories {
      id
      name
    }
    newsPublisher {
      id
      name
    }
    newsNotifications {
      ...NewsNotification
    }
    updatedAt
  }
  customNotifications {
    ...CustomNotification
  }
}
    ${NewsNotificationFragmentDoc}
${CustomNotificationFragmentDoc}`;

/**
 * __useCreateNotificationPageQuery__
 *
 * To run a query within a React component, call `useCreateNotificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateNotificationPageQuery({
 *   variables: {
 *      newsId: // value for 'newsId'
 *   },
 * });
 */
export function useCreateNotificationPageQuery(baseOptions: Apollo.QueryHookOptions<CreateNotificationPageQuery, CreateNotificationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateNotificationPageQuery, CreateNotificationPageQueryVariables>(CreateNotificationPageDocument, options);
      }
export function useCreateNotificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateNotificationPageQuery, CreateNotificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateNotificationPageQuery, CreateNotificationPageQueryVariables>(CreateNotificationPageDocument, options);
        }
export type CreateNotificationPageQueryHookResult = ReturnType<typeof useCreateNotificationPageQuery>;
export type CreateNotificationPageLazyQueryHookResult = ReturnType<typeof useCreateNotificationPageLazyQuery>;
export type CreateNotificationPageQueryResult = Apollo.QueryResult<CreateNotificationPageQuery, CreateNotificationPageQueryVariables>;
export const AnnouncementDocument = gql`
    query Announcement {
  me {
    id
  }
  announcements {
    id
    title
    path
    description
    createdAt
  }
}
    `;

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementQuery(baseOptions?: Apollo.QueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, options);
      }
export function useAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, options);
        }
export type AnnouncementQueryHookResult = ReturnType<typeof useAnnouncementQuery>;
export type AnnouncementLazyQueryHookResult = ReturnType<typeof useAnnouncementLazyQuery>;
export type AnnouncementQueryResult = Apollo.QueryResult<AnnouncementQuery, AnnouncementQueryVariables>;
export const PublisherPageDocument = gql`
    query PublisherPage($id: ID!) {
  me {
    id
  }
  publisher(id: $id) {
    ...Publisher
  }
}
    ${PublisherFragmentDoc}`;

/**
 * __usePublisherPageQuery__
 *
 * To run a query within a React component, call `usePublisherPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublisherPageQuery(baseOptions: Apollo.QueryHookOptions<PublisherPageQuery, PublisherPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherPageQuery, PublisherPageQueryVariables>(PublisherPageDocument, options);
      }
export function usePublisherPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherPageQuery, PublisherPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherPageQuery, PublisherPageQueryVariables>(PublisherPageDocument, options);
        }
export type PublisherPageQueryHookResult = ReturnType<typeof usePublisherPageQuery>;
export type PublisherPageLazyQueryHookResult = ReturnType<typeof usePublisherPageLazyQuery>;
export type PublisherPageQueryResult = Apollo.QueryResult<PublisherPageQuery, PublisherPageQueryVariables>;
export const AllPublishersPageDocument = gql`
    query AllPublishersPage {
  me {
    id
  }
  publishers {
    id
    name
    iconUrl
    active
    order
    lastCrawlAt
    lastCrawlTrendingAt
    rssUrl
    slug
  }
}
    `;

/**
 * __useAllPublishersPageQuery__
 *
 * To run a query within a React component, call `useAllPublishersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPublishersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPublishersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPublishersPageQuery(baseOptions?: Apollo.QueryHookOptions<AllPublishersPageQuery, AllPublishersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPublishersPageQuery, AllPublishersPageQueryVariables>(AllPublishersPageDocument, options);
      }
export function useAllPublishersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPublishersPageQuery, AllPublishersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPublishersPageQuery, AllPublishersPageQueryVariables>(AllPublishersPageDocument, options);
        }
export type AllPublishersPageQueryHookResult = ReturnType<typeof useAllPublishersPageQuery>;
export type AllPublishersPageLazyQueryHookResult = ReturnType<typeof useAllPublishersPageLazyQuery>;
export type AllPublishersPageQueryResult = Apollo.QueryResult<AllPublishersPageQuery, AllPublishersPageQueryVariables>;
export const PublisherNotificationPageDocument = gql`
    query PublisherNotificationPage {
  me {
    id
  }
  languages {
    id
    name
  }
}
    `;

/**
 * __usePublisherNotificationPageQuery__
 *
 * To run a query within a React component, call `usePublisherNotificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherNotificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherNotificationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublisherNotificationPageQuery(baseOptions?: Apollo.QueryHookOptions<PublisherNotificationPageQuery, PublisherNotificationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherNotificationPageQuery, PublisherNotificationPageQueryVariables>(PublisherNotificationPageDocument, options);
      }
export function usePublisherNotificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherNotificationPageQuery, PublisherNotificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherNotificationPageQuery, PublisherNotificationPageQueryVariables>(PublisherNotificationPageDocument, options);
        }
export type PublisherNotificationPageQueryHookResult = ReturnType<typeof usePublisherNotificationPageQuery>;
export type PublisherNotificationPageLazyQueryHookResult = ReturnType<typeof usePublisherNotificationPageLazyQuery>;
export type PublisherNotificationPageQueryResult = Apollo.QueryResult<PublisherNotificationPageQuery, PublisherNotificationPageQueryVariables>;
export const AllRichNewsStacksDocument = gql`
    query AllRichNewsStacks {
  me {
    id
  }
  richContentCategories {
    id
    name
    updatedAt
  }
}
    `;

/**
 * __useAllRichNewsStacksQuery__
 *
 * To run a query within a React component, call `useAllRichNewsStacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRichNewsStacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRichNewsStacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRichNewsStacksQuery(baseOptions?: Apollo.QueryHookOptions<AllRichNewsStacksQuery, AllRichNewsStacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllRichNewsStacksQuery, AllRichNewsStacksQueryVariables>(AllRichNewsStacksDocument, options);
      }
export function useAllRichNewsStacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRichNewsStacksQuery, AllRichNewsStacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllRichNewsStacksQuery, AllRichNewsStacksQueryVariables>(AllRichNewsStacksDocument, options);
        }
export type AllRichNewsStacksQueryHookResult = ReturnType<typeof useAllRichNewsStacksQuery>;
export type AllRichNewsStacksLazyQueryHookResult = ReturnType<typeof useAllRichNewsStacksLazyQuery>;
export type AllRichNewsStacksQueryResult = Apollo.QueryResult<AllRichNewsStacksQuery, AllRichNewsStacksQueryVariables>;
export const TopicStackPageDocument = gql`
    query TopicStackPage($id: ID!) {
  me {
    id
  }
  topicStack(id: $id) {
    id
    title
    titleLocale {
      ...Locale
    }
    descriptionLocale {
      ...Locale
    }
  }
  categories(active: true) {
    id
    name
  }
}
    ${LocaleFragmentDoc}`;

/**
 * __useTopicStackPageQuery__
 *
 * To run a query within a React component, call `useTopicStackPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicStackPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicStackPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicStackPageQuery(baseOptions: Apollo.QueryHookOptions<TopicStackPageQuery, TopicStackPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicStackPageQuery, TopicStackPageQueryVariables>(TopicStackPageDocument, options);
      }
export function useTopicStackPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicStackPageQuery, TopicStackPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicStackPageQuery, TopicStackPageQueryVariables>(TopicStackPageDocument, options);
        }
export type TopicStackPageQueryHookResult = ReturnType<typeof useTopicStackPageQuery>;
export type TopicStackPageLazyQueryHookResult = ReturnType<typeof useTopicStackPageLazyQuery>;
export type TopicStackPageQueryResult = Apollo.QueryResult<TopicStackPageQuery, TopicStackPageQueryVariables>;
export const AllTopicStacksPageDocument = gql`
    query AllTopicStacksPage {
  me {
    id
  }
  topicStacks {
    id
    title
    titleLocale {
      ...Locale
    }
    descriptionLocale {
      ...Locale
    }
  }
}
    ${LocaleFragmentDoc}`;

/**
 * __useAllTopicStacksPageQuery__
 *
 * To run a query within a React component, call `useAllTopicStacksPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTopicStacksPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTopicStacksPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTopicStacksPageQuery(baseOptions?: Apollo.QueryHookOptions<AllTopicStacksPageQuery, AllTopicStacksPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTopicStacksPageQuery, AllTopicStacksPageQueryVariables>(AllTopicStacksPageDocument, options);
      }
export function useAllTopicStacksPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTopicStacksPageQuery, AllTopicStacksPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTopicStacksPageQuery, AllTopicStacksPageQueryVariables>(AllTopicStacksPageDocument, options);
        }
export type AllTopicStacksPageQueryHookResult = ReturnType<typeof useAllTopicStacksPageQuery>;
export type AllTopicStacksPageLazyQueryHookResult = ReturnType<typeof useAllTopicStacksPageLazyQuery>;
export type AllTopicStacksPageQueryResult = Apollo.QueryResult<AllTopicStacksPageQuery, AllTopicStacksPageQueryVariables>;
export const TopicPageDocument = gql`
    query TopicPage($id: ID!) {
  me {
    id
  }
  category(id: $id) {
    ...Topic
    suggestedCategories {
      id
      targetedCategoryId
      name
      order
    }
    languages
    hasRichContent
    author {
      id
      nameLocale {
        ...Locale
      }
      imageUrl
      verified
    }
    hasComments
  }
}
    ${TopicFragmentDoc}
${LocaleFragmentDoc}`;

/**
 * __useTopicPageQuery__
 *
 * To run a query within a React component, call `useTopicPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicPageQuery(baseOptions: Apollo.QueryHookOptions<TopicPageQuery, TopicPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicPageQuery, TopicPageQueryVariables>(TopicPageDocument, options);
      }
export function useTopicPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicPageQuery, TopicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicPageQuery, TopicPageQueryVariables>(TopicPageDocument, options);
        }
export type TopicPageQueryHookResult = ReturnType<typeof useTopicPageQuery>;
export type TopicPageLazyQueryHookResult = ReturnType<typeof useTopicPageLazyQuery>;
export type TopicPageQueryResult = Apollo.QueryResult<TopicPageQuery, TopicPageQueryVariables>;
export const TopicFeedDocument = gql`
    query TopicFeed($categoryId: ID!, $languageCode: String) {
  me {
    id
  }
  topicFeed(categoryId: $categoryId, languageCode: $languageCode) {
    id
    data
    updatedAt
    lastPublishedAt
    category {
      name
    }
  }
}
    `;

/**
 * __useTopicFeedQuery__
 *
 * To run a query within a React component, call `useTopicFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicFeedQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTopicFeedQuery(baseOptions: Apollo.QueryHookOptions<TopicFeedQuery, TopicFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicFeedQuery, TopicFeedQueryVariables>(TopicFeedDocument, options);
      }
export function useTopicFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicFeedQuery, TopicFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicFeedQuery, TopicFeedQueryVariables>(TopicFeedDocument, options);
        }
export type TopicFeedQueryHookResult = ReturnType<typeof useTopicFeedQuery>;
export type TopicFeedLazyQueryHookResult = ReturnType<typeof useTopicFeedLazyQuery>;
export type TopicFeedQueryResult = Apollo.QueryResult<TopicFeedQuery, TopicFeedQueryVariables>;
export const CreateAuthorPageDocument = gql`
    query CreateAuthorPage($id: ID!) {
  me {
    id
  }
  category(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useCreateAuthorPageQuery__
 *
 * To run a query within a React component, call `useCreateAuthorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateAuthorPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateAuthorPageQuery(baseOptions: Apollo.QueryHookOptions<CreateAuthorPageQuery, CreateAuthorPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateAuthorPageQuery, CreateAuthorPageQueryVariables>(CreateAuthorPageDocument, options);
      }
export function useCreateAuthorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateAuthorPageQuery, CreateAuthorPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateAuthorPageQuery, CreateAuthorPageQueryVariables>(CreateAuthorPageDocument, options);
        }
export type CreateAuthorPageQueryHookResult = ReturnType<typeof useCreateAuthorPageQuery>;
export type CreateAuthorPageLazyQueryHookResult = ReturnType<typeof useCreateAuthorPageLazyQuery>;
export type CreateAuthorPageQueryResult = Apollo.QueryResult<CreateAuthorPageQuery, CreateAuthorPageQueryVariables>;
export const TopicFeedsDocument = gql`
    query TopicFeeds($languageCode: String) {
  topicFeeds(languageCode: $languageCode) {
    id
    data
    updatedAt
    category {
      id
      name
    }
  }
}
    `;

/**
 * __useTopicFeedsQuery__
 *
 * To run a query within a React component, call `useTopicFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicFeedsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTopicFeedsQuery(baseOptions?: Apollo.QueryHookOptions<TopicFeedsQuery, TopicFeedsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicFeedsQuery, TopicFeedsQueryVariables>(TopicFeedsDocument, options);
      }
export function useTopicFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicFeedsQuery, TopicFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicFeedsQuery, TopicFeedsQueryVariables>(TopicFeedsDocument, options);
        }
export type TopicFeedsQueryHookResult = ReturnType<typeof useTopicFeedsQuery>;
export type TopicFeedsLazyQueryHookResult = ReturnType<typeof useTopicFeedsLazyQuery>;
export type TopicFeedsQueryResult = Apollo.QueryResult<TopicFeedsQuery, TopicFeedsQueryVariables>;
export const AllTopicsPageDocument = gql`
    query AllTopicsPage($limit: Int, $offset: Int, $name: String, $explore: Boolean, $categoryType: String) {
  categories(
    offsetAttributes: {limit: $limit, offset: $offset}
    name: $name
    explore: $explore
    categoryType: $categoryType
  ) {
    id
    name
    emoji
    order
    show
    explore
    slug
    hasComments
  }
}
    `;

/**
 * __useAllTopicsPageQuery__
 *
 * To run a query within a React component, call `useAllTopicsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTopicsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTopicsPageQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *      explore: // value for 'explore'
 *      categoryType: // value for 'categoryType'
 *   },
 * });
 */
export function useAllTopicsPageQuery(baseOptions?: Apollo.QueryHookOptions<AllTopicsPageQuery, AllTopicsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTopicsPageQuery, AllTopicsPageQueryVariables>(AllTopicsPageDocument, options);
      }
export function useAllTopicsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTopicsPageQuery, AllTopicsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTopicsPageQuery, AllTopicsPageQueryVariables>(AllTopicsPageDocument, options);
        }
export type AllTopicsPageQueryHookResult = ReturnType<typeof useAllTopicsPageQuery>;
export type AllTopicsPageLazyQueryHookResult = ReturnType<typeof useAllTopicsPageLazyQuery>;
export type AllTopicsPageQueryResult = Apollo.QueryResult<AllTopicsPageQuery, AllTopicsPageQueryVariables>;
export const TopicRichNewsStackDocument = gql`
    query TopicRichNewsStack($categoryId: ID!, $languageCode: String) {
  category(id: $categoryId) {
    id
    slug
    name
    imageUrl
    lastPublishedAt
    exploreNameLocale {
      ...Locale
    }
  }
  richContent(categoryId: $categoryId, languageCode: $languageCode) {
    data
    draft
    updatedAt
    publishedAt
    availableLanguages
  }
}
    ${LocaleFragmentDoc}`;

/**
 * __useTopicRichNewsStackQuery__
 *
 * To run a query within a React component, call `useTopicRichNewsStackQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicRichNewsStackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicRichNewsStackQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTopicRichNewsStackQuery(baseOptions: Apollo.QueryHookOptions<TopicRichNewsStackQuery, TopicRichNewsStackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicRichNewsStackQuery, TopicRichNewsStackQueryVariables>(TopicRichNewsStackDocument, options);
      }
export function useTopicRichNewsStackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicRichNewsStackQuery, TopicRichNewsStackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicRichNewsStackQuery, TopicRichNewsStackQueryVariables>(TopicRichNewsStackDocument, options);
        }
export type TopicRichNewsStackQueryHookResult = ReturnType<typeof useTopicRichNewsStackQuery>;
export type TopicRichNewsStackLazyQueryHookResult = ReturnType<typeof useTopicRichNewsStackLazyQuery>;
export type TopicRichNewsStackQueryResult = Apollo.QueryResult<TopicRichNewsStackQuery, TopicRichNewsStackQueryVariables>;
export const CreateVideoNotificationPageDocument = gql`
    query CreateVideoNotificationPage($videoId: ID!) {
  me {
    id
  }
  video(id: $videoId) {
    id
    title
    description
    externalId
    liveChatId
    newsPublisher {
      id
      name
    }
    categories {
      id
      name
    }
    videoNotifications {
      ...VideoNotification
    }
  }
}
    ${VideoNotificationFragmentDoc}`;

/**
 * __useCreateVideoNotificationPageQuery__
 *
 * To run a query within a React component, call `useCreateVideoNotificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoNotificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateVideoNotificationPageQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useCreateVideoNotificationPageQuery(baseOptions: Apollo.QueryHookOptions<CreateVideoNotificationPageQuery, CreateVideoNotificationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateVideoNotificationPageQuery, CreateVideoNotificationPageQueryVariables>(CreateVideoNotificationPageDocument, options);
      }
export function useCreateVideoNotificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateVideoNotificationPageQuery, CreateVideoNotificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateVideoNotificationPageQuery, CreateVideoNotificationPageQueryVariables>(CreateVideoNotificationPageDocument, options);
        }
export type CreateVideoNotificationPageQueryHookResult = ReturnType<typeof useCreateVideoNotificationPageQuery>;
export type CreateVideoNotificationPageLazyQueryHookResult = ReturnType<typeof useCreateVideoNotificationPageLazyQuery>;
export type CreateVideoNotificationPageQueryResult = Apollo.QueryResult<CreateVideoNotificationPageQuery, CreateVideoNotificationPageQueryVariables>;
export const CrawlYoutubeVideoPageDocument = gql`
    query CrawlYoutubeVideoPage {
  me {
    id
  }
  publishers {
    id
    name
    active
  }
}
    `;

/**
 * __useCrawlYoutubeVideoPageQuery__
 *
 * To run a query within a React component, call `useCrawlYoutubeVideoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrawlYoutubeVideoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrawlYoutubeVideoPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrawlYoutubeVideoPageQuery(baseOptions?: Apollo.QueryHookOptions<CrawlYoutubeVideoPageQuery, CrawlYoutubeVideoPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrawlYoutubeVideoPageQuery, CrawlYoutubeVideoPageQueryVariables>(CrawlYoutubeVideoPageDocument, options);
      }
export function useCrawlYoutubeVideoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrawlYoutubeVideoPageQuery, CrawlYoutubeVideoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrawlYoutubeVideoPageQuery, CrawlYoutubeVideoPageQueryVariables>(CrawlYoutubeVideoPageDocument, options);
        }
export type CrawlYoutubeVideoPageQueryHookResult = ReturnType<typeof useCrawlYoutubeVideoPageQuery>;
export type CrawlYoutubeVideoPageLazyQueryHookResult = ReturnType<typeof useCrawlYoutubeVideoPageLazyQuery>;
export type CrawlYoutubeVideoPageQueryResult = Apollo.QueryResult<CrawlYoutubeVideoPageQuery, CrawlYoutubeVideoPageQueryVariables>;
export const EditVideoPageDocument = gql`
    query EditVideoPage($videoId: ID!) {
  me {
    id
  }
  video(id: $videoId) {
    id
    title
    description
    isWebViewable
    orientation
    externalId
    hasComments
    liveChatId
    isShort
  }
}
    `;

/**
 * __useEditVideoPageQuery__
 *
 * To run a query within a React component, call `useEditVideoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditVideoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditVideoPageQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useEditVideoPageQuery(baseOptions: Apollo.QueryHookOptions<EditVideoPageQuery, EditVideoPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditVideoPageQuery, EditVideoPageQueryVariables>(EditVideoPageDocument, options);
      }
export function useEditVideoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditVideoPageQuery, EditVideoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditVideoPageQuery, EditVideoPageQueryVariables>(EditVideoPageDocument, options);
        }
export type EditVideoPageQueryHookResult = ReturnType<typeof useEditVideoPageQuery>;
export type EditVideoPageLazyQueryHookResult = ReturnType<typeof useEditVideoPageLazyQuery>;
export type EditVideoPageQueryResult = Apollo.QueryResult<EditVideoPageQuery, EditVideoPageQueryVariables>;
export const FeaturedVideoDocument = gql`
    query FeaturedVideo {
  me {
    id
  }
  featuredVideo {
    id
    language {
      code
      name
      id
    }
    video {
      ...FeaturedVideo
    }
  }
}
    ${FeaturedVideoFragmentDoc}`;

/**
 * __useFeaturedVideoQuery__
 *
 * To run a query within a React component, call `useFeaturedVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedVideoQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedVideoQuery, FeaturedVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturedVideoQuery, FeaturedVideoQueryVariables>(FeaturedVideoDocument, options);
      }
export function useFeaturedVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedVideoQuery, FeaturedVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturedVideoQuery, FeaturedVideoQueryVariables>(FeaturedVideoDocument, options);
        }
export type FeaturedVideoQueryHookResult = ReturnType<typeof useFeaturedVideoQuery>;
export type FeaturedVideoLazyQueryHookResult = ReturnType<typeof useFeaturedVideoLazyQuery>;
export type FeaturedVideoQueryResult = Apollo.QueryResult<FeaturedVideoQuery, FeaturedVideoQueryVariables>;
export const Ge15FeaturedVideoDocument = gql`
    query Ge15FeaturedVideo {
  me {
    id
  }
  ge15FeaturedVideo {
    id
    language {
      code
      name
      id
    }
    video {
      ...FeaturedVideo
    }
  }
}
    ${FeaturedVideoFragmentDoc}`;

/**
 * __useGe15FeaturedVideoQuery__
 *
 * To run a query within a React component, call `useGe15FeaturedVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGe15FeaturedVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGe15FeaturedVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGe15FeaturedVideoQuery(baseOptions?: Apollo.QueryHookOptions<Ge15FeaturedVideoQuery, Ge15FeaturedVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Ge15FeaturedVideoQuery, Ge15FeaturedVideoQueryVariables>(Ge15FeaturedVideoDocument, options);
      }
export function useGe15FeaturedVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Ge15FeaturedVideoQuery, Ge15FeaturedVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Ge15FeaturedVideoQuery, Ge15FeaturedVideoQueryVariables>(Ge15FeaturedVideoDocument, options);
        }
export type Ge15FeaturedVideoQueryHookResult = ReturnType<typeof useGe15FeaturedVideoQuery>;
export type Ge15FeaturedVideoLazyQueryHookResult = ReturnType<typeof useGe15FeaturedVideoLazyQuery>;
export type Ge15FeaturedVideoQueryResult = Apollo.QueryResult<Ge15FeaturedVideoQuery, Ge15FeaturedVideoQueryVariables>;
export const AllVideosPageDocument = gql`
    query AllVideosPage {
  me {
    id
  }
  categories(active: true) {
    id
    name
  }
  publishers {
    id
    name
  }
}
    `;

/**
 * __useAllVideosPageQuery__
 *
 * To run a query within a React component, call `useAllVideosPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVideosPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVideosPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllVideosPageQuery(baseOptions?: Apollo.QueryHookOptions<AllVideosPageQuery, AllVideosPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllVideosPageQuery, AllVideosPageQueryVariables>(AllVideosPageDocument, options);
      }
export function useAllVideosPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllVideosPageQuery, AllVideosPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllVideosPageQuery, AllVideosPageQueryVariables>(AllVideosPageDocument, options);
        }
export type AllVideosPageQueryHookResult = ReturnType<typeof useAllVideosPageQuery>;
export type AllVideosPageLazyQueryHookResult = ReturnType<typeof useAllVideosPageLazyQuery>;
export type AllVideosPageQueryResult = Apollo.QueryResult<AllVideosPageQuery, AllVideosPageQueryVariables>;
export const AdminAttachmentsDocument = gql`
    query AdminAttachments {
  me {
    id
    attachments {
      __typename
      id
      url
      filename
    }
  }
}
    `;

/**
 * __useAdminAttachmentsQuery__
 *
 * To run a query within a React component, call `useAdminAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAttachmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<AdminAttachmentsQuery, AdminAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminAttachmentsQuery, AdminAttachmentsQueryVariables>(AdminAttachmentsDocument, options);
      }
export function useAdminAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminAttachmentsQuery, AdminAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminAttachmentsQuery, AdminAttachmentsQueryVariables>(AdminAttachmentsDocument, options);
        }
export type AdminAttachmentsQueryHookResult = ReturnType<typeof useAdminAttachmentsQuery>;
export type AdminAttachmentsLazyQueryHookResult = ReturnType<typeof useAdminAttachmentsLazyQuery>;
export type AdminAttachmentsQueryResult = Apollo.QueryResult<AdminAttachmentsQuery, AdminAttachmentsQueryVariables>;
export const CategoriesWithIdsDocument = gql`
    query CategoriesWithIds($ids: [ID!]!) {
  categoriesWithIds(ids: $ids) {
    id
    name
    imageUrl
  }
}
    `;

/**
 * __useCategoriesWithIdsQuery__
 *
 * To run a query within a React component, call `useCategoriesWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesWithIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCategoriesWithIdsQuery(baseOptions: Apollo.QueryHookOptions<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>(CategoriesWithIdsDocument, options);
      }
export function useCategoriesWithIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>(CategoriesWithIdsDocument, options);
        }
export type CategoriesWithIdsQueryHookResult = ReturnType<typeof useCategoriesWithIdsQuery>;
export type CategoriesWithIdsLazyQueryHookResult = ReturnType<typeof useCategoriesWithIdsLazyQuery>;
export type CategoriesWithIdsQueryResult = Apollo.QueryResult<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>;
export const CategoriesWithNamesDocument = gql`
    query CategoriesWithNames($active: Boolean, $name: String) {
  categories(active: $active, name: $name) {
    id
    name
  }
}
    `;

/**
 * __useCategoriesWithNamesQuery__
 *
 * To run a query within a React component, call `useCategoriesWithNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesWithNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesWithNamesQuery({
 *   variables: {
 *      active: // value for 'active'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCategoriesWithNamesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesWithNamesQuery, CategoriesWithNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesWithNamesQuery, CategoriesWithNamesQueryVariables>(CategoriesWithNamesDocument, options);
      }
export function useCategoriesWithNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesWithNamesQuery, CategoriesWithNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesWithNamesQuery, CategoriesWithNamesQueryVariables>(CategoriesWithNamesDocument, options);
        }
export type CategoriesWithNamesQueryHookResult = ReturnType<typeof useCategoriesWithNamesQuery>;
export type CategoriesWithNamesLazyQueryHookResult = ReturnType<typeof useCategoriesWithNamesLazyQuery>;
export type CategoriesWithNamesQueryResult = Apollo.QueryResult<CategoriesWithNamesQuery, CategoriesWithNamesQueryVariables>;
export const CommentsDocument = gql`
    query Comments($type: String, $content: String, $limit: Int, $offset: Int) {
  comments(
    type: $type
    content: $content
    offsetAttributes: {limit: $limit, offset: $offset}
  ) {
    ...Comment
    parentContent {
      ... on News {
        id
        title
      }
      ... on Category {
        id
        name
      }
      ... on Video {
        id
        title
        isShort
      }
    }
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      content: // value for 'content'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions?: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const ContentWithIdsDocument = gql`
    query ContentWithIds($newsIds: [ID!]!, $videoIds: [ID!]!, $categoryIds: [ID!]!) {
  newsWithIds(ids: $newsIds) {
    id
    title
    thumbnailUrl
    createdAt
    pubDate
    newsPublisher {
      id
      name
    }
  }
  videosWithIds(ids: $videoIds) {
    id
    title
    externalId
    createdAt
    pubDate
    isShort
    newsPublisher {
      id
      name
    }
  }
  categoriesWithIds(ids: $categoryIds) {
    id
    name
    imageUrl
  }
}
    `;

/**
 * __useContentWithIdsQuery__
 *
 * To run a query within a React component, call `useContentWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentWithIdsQuery({
 *   variables: {
 *      newsIds: // value for 'newsIds'
 *      videoIds: // value for 'videoIds'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useContentWithIdsQuery(baseOptions: Apollo.QueryHookOptions<ContentWithIdsQuery, ContentWithIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentWithIdsQuery, ContentWithIdsQueryVariables>(ContentWithIdsDocument, options);
      }
export function useContentWithIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentWithIdsQuery, ContentWithIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentWithIdsQuery, ContentWithIdsQueryVariables>(ContentWithIdsDocument, options);
        }
export type ContentWithIdsQueryHookResult = ReturnType<typeof useContentWithIdsQuery>;
export type ContentWithIdsLazyQueryHookResult = ReturnType<typeof useContentWithIdsLazyQuery>;
export type ContentWithIdsQueryResult = Apollo.QueryResult<ContentWithIdsQuery, ContentWithIdsQueryVariables>;
export const FailedRssPublishersDocument = gql`
    query FailedRssPublishers {
  me {
    id
    email
  }
  failedRssPublishers {
    ...Publisher
  }
}
    ${PublisherFragmentDoc}`;

/**
 * __useFailedRssPublishersQuery__
 *
 * To run a query within a React component, call `useFailedRssPublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailedRssPublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailedRssPublishersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFailedRssPublishersQuery(baseOptions?: Apollo.QueryHookOptions<FailedRssPublishersQuery, FailedRssPublishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FailedRssPublishersQuery, FailedRssPublishersQueryVariables>(FailedRssPublishersDocument, options);
      }
export function useFailedRssPublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FailedRssPublishersQuery, FailedRssPublishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FailedRssPublishersQuery, FailedRssPublishersQueryVariables>(FailedRssPublishersDocument, options);
        }
export type FailedRssPublishersQueryHookResult = ReturnType<typeof useFailedRssPublishersQuery>;
export type FailedRssPublishersLazyQueryHookResult = ReturnType<typeof useFailedRssPublishersLazyQuery>;
export type FailedRssPublishersQueryResult = Apollo.QueryResult<FailedRssPublishersQuery, FailedRssPublishersQueryVariables>;
export const GetNewsCommentsDocument = gql`
    query GetNewsComments($newsId: ID!, $limit: Int, $offset: Int) {
  me {
    id
  }
  news(id: $newsId) {
    comments(offsetAttributes: {limit: $limit, offset: $offset}) {
      ...Comment
    }
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useGetNewsCommentsQuery__
 *
 * To run a query within a React component, call `useGetNewsCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsCommentsQuery({
 *   variables: {
 *      newsId: // value for 'newsId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNewsCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetNewsCommentsQuery, GetNewsCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsCommentsQuery, GetNewsCommentsQueryVariables>(GetNewsCommentsDocument, options);
      }
export function useGetNewsCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsCommentsQuery, GetNewsCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsCommentsQuery, GetNewsCommentsQueryVariables>(GetNewsCommentsDocument, options);
        }
export type GetNewsCommentsQueryHookResult = ReturnType<typeof useGetNewsCommentsQuery>;
export type GetNewsCommentsLazyQueryHookResult = ReturnType<typeof useGetNewsCommentsLazyQuery>;
export type GetNewsCommentsQueryResult = Apollo.QueryResult<GetNewsCommentsQuery, GetNewsCommentsQueryVariables>;
export const GetRepliesDocument = gql`
    query GetReplies($id: ID!, $limit: Int, $offset: Int) {
  me {
    id
  }
  comment(id: $id) {
    replies(offsetAttributes: {limit: $limit, offset: $offset}) {
      ...Reply
    }
  }
}
    ${ReplyFragmentDoc}`;

/**
 * __useGetRepliesQuery__
 *
 * To run a query within a React component, call `useGetRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepliesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetRepliesQuery(baseOptions: Apollo.QueryHookOptions<GetRepliesQuery, GetRepliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRepliesQuery, GetRepliesQueryVariables>(GetRepliesDocument, options);
      }
export function useGetRepliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepliesQuery, GetRepliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRepliesQuery, GetRepliesQueryVariables>(GetRepliesDocument, options);
        }
export type GetRepliesQueryHookResult = ReturnType<typeof useGetRepliesQuery>;
export type GetRepliesLazyQueryHookResult = ReturnType<typeof useGetRepliesLazyQuery>;
export type GetRepliesQueryResult = Apollo.QueryResult<GetRepliesQuery, GetRepliesQueryVariables>;
export const GetTopicCommentsDocument = gql`
    query GetTopicComments($id: ID!, $limit: Int, $offset: Int) {
  me {
    id
  }
  category(id: $id) {
    comments(offsetAttributes: {limit: $limit, offset: $offset}) {
      ...Comment
    }
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useGetTopicCommentsQuery__
 *
 * To run a query within a React component, call `useGetTopicCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTopicCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>(GetTopicCommentsDocument, options);
      }
export function useGetTopicCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>(GetTopicCommentsDocument, options);
        }
export type GetTopicCommentsQueryHookResult = ReturnType<typeof useGetTopicCommentsQuery>;
export type GetTopicCommentsLazyQueryHookResult = ReturnType<typeof useGetTopicCommentsLazyQuery>;
export type GetTopicCommentsQueryResult = Apollo.QueryResult<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>;
export const GetVideoCommentsDocument = gql`
    query GetVideoComments($id: ID!, $limit: Int, $offset: Int) {
  me {
    id
  }
  video(id: $id) {
    comments(offsetAttributes: {limit: $limit, offset: $offset}) {
      ...Comment
    }
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useGetVideoCommentsQuery__
 *
 * To run a query within a React component, call `useGetVideoCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetVideoCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetVideoCommentsQuery, GetVideoCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoCommentsQuery, GetVideoCommentsQueryVariables>(GetVideoCommentsDocument, options);
      }
export function useGetVideoCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoCommentsQuery, GetVideoCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoCommentsQuery, GetVideoCommentsQueryVariables>(GetVideoCommentsDocument, options);
        }
export type GetVideoCommentsQueryHookResult = ReturnType<typeof useGetVideoCommentsQuery>;
export type GetVideoCommentsLazyQueryHookResult = ReturnType<typeof useGetVideoCommentsLazyQuery>;
export type GetVideoCommentsQueryResult = Apollo.QueryResult<GetVideoCommentsQuery, GetVideoCommentsQueryVariables>;
export const KeywordDocument = gql`
    query Keyword($id: ID!) {
  keyword(id: $id) {
    id
    language
    position
    keywordString
    createdAt
  }
}
    `;

/**
 * __useKeywordQuery__
 *
 * To run a query within a React component, call `useKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKeywordQuery(baseOptions: Apollo.QueryHookOptions<KeywordQuery, KeywordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KeywordQuery, KeywordQueryVariables>(KeywordDocument, options);
      }
export function useKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KeywordQuery, KeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KeywordQuery, KeywordQueryVariables>(KeywordDocument, options);
        }
export type KeywordQueryHookResult = ReturnType<typeof useKeywordQuery>;
export type KeywordLazyQueryHookResult = ReturnType<typeof useKeywordLazyQuery>;
export type KeywordQueryResult = Apollo.QueryResult<KeywordQuery, KeywordQueryVariables>;
export const KeywordsDocument = gql`
    query keywords($languageCodes: [String!], $limit: Int, $offset: Int) {
  keywords(
    languageCodes: $languageCodes
    offsetAttributes: {limit: $limit, offset: $offset}
  ) {
    id
    keywordString
    position
    language
    createdAt
  }
}
    `;

/**
 * __useKeywordsQuery__
 *
 * To run a query within a React component, call `useKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordsQuery({
 *   variables: {
 *      languageCodes: // value for 'languageCodes'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<KeywordsQuery, KeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KeywordsQuery, KeywordsQueryVariables>(KeywordsDocument, options);
      }
export function useKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KeywordsQuery, KeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KeywordsQuery, KeywordsQueryVariables>(KeywordsDocument, options);
        }
export type KeywordsQueryHookResult = ReturnType<typeof useKeywordsQuery>;
export type KeywordsLazyQueryHookResult = ReturnType<typeof useKeywordsLazyQuery>;
export type KeywordsQueryResult = Apollo.QueryResult<KeywordsQuery, KeywordsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NewPublisherNotificationDocument = gql`
    query NewPublisherNotification($perPage: Int, $page: Int, $afterSendAt: ISO8601DateTime) {
  newPublisherNotifications(
    perPage: $perPage
    page: $page
    afterSendAt: $afterSendAt
  ) {
    id
    title
    description
    sendAt
    createdAt
    newsPublisher {
      id
      name
    }
  }
}
    `;

/**
 * __useNewPublisherNotificationQuery__
 *
 * To run a query within a React component, call `useNewPublisherNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewPublisherNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewPublisherNotificationQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      afterSendAt: // value for 'afterSendAt'
 *   },
 * });
 */
export function useNewPublisherNotificationQuery(baseOptions?: Apollo.QueryHookOptions<NewPublisherNotificationQuery, NewPublisherNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewPublisherNotificationQuery, NewPublisherNotificationQueryVariables>(NewPublisherNotificationDocument, options);
      }
export function useNewPublisherNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewPublisherNotificationQuery, NewPublisherNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewPublisherNotificationQuery, NewPublisherNotificationQueryVariables>(NewPublisherNotificationDocument, options);
        }
export type NewPublisherNotificationQueryHookResult = ReturnType<typeof useNewPublisherNotificationQuery>;
export type NewPublisherNotificationLazyQueryHookResult = ReturnType<typeof useNewPublisherNotificationLazyQuery>;
export type NewPublisherNotificationQueryResult = Apollo.QueryResult<NewPublisherNotificationQuery, NewPublisherNotificationQueryVariables>;
export const NewsNotificationDocument = gql`
    query NewsNotification($perPage: Int, $page: Int, $customNotificationId: ID, $afterSendAt: ISO8601DateTime) {
  newsNotifications(
    perPage: $perPage
    page: $page
    customNotificationId: $customNotificationId
    afterSendAt: $afterSendAt
  ) {
    ...NewsNotification
  }
  customNotifications {
    ...CustomNotification
  }
}
    ${NewsNotificationFragmentDoc}
${CustomNotificationFragmentDoc}`;

/**
 * __useNewsNotificationQuery__
 *
 * To run a query within a React component, call `useNewsNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsNotificationQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      customNotificationId: // value for 'customNotificationId'
 *      afterSendAt: // value for 'afterSendAt'
 *   },
 * });
 */
export function useNewsNotificationQuery(baseOptions?: Apollo.QueryHookOptions<NewsNotificationQuery, NewsNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsNotificationQuery, NewsNotificationQueryVariables>(NewsNotificationDocument, options);
      }
export function useNewsNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsNotificationQuery, NewsNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsNotificationQuery, NewsNotificationQueryVariables>(NewsNotificationDocument, options);
        }
export type NewsNotificationQueryHookResult = ReturnType<typeof useNewsNotificationQuery>;
export type NewsNotificationLazyQueryHookResult = ReturnType<typeof useNewsNotificationLazyQuery>;
export type NewsNotificationQueryResult = Apollo.QueryResult<NewsNotificationQuery, NewsNotificationQueryVariables>;
export const NewsStackNotificationDocument = gql`
    query NewsStackNotification($perPage: Int, $page: Int, $afterSendAt: ISO8601DateTime) {
  newsStackNotifications(
    perPage: $perPage
    page: $page
    afterSendAt: $afterSendAt
  ) {
    id
    titleLocale {
      ...Locale
    }
    createdAt
    sendAt
    category {
      id
      name
    }
  }
}
    ${LocaleFragmentDoc}`;

/**
 * __useNewsStackNotificationQuery__
 *
 * To run a query within a React component, call `useNewsStackNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsStackNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsStackNotificationQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      afterSendAt: // value for 'afterSendAt'
 *   },
 * });
 */
export function useNewsStackNotificationQuery(baseOptions?: Apollo.QueryHookOptions<NewsStackNotificationQuery, NewsStackNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsStackNotificationQuery, NewsStackNotificationQueryVariables>(NewsStackNotificationDocument, options);
      }
export function useNewsStackNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsStackNotificationQuery, NewsStackNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsStackNotificationQuery, NewsStackNotificationQueryVariables>(NewsStackNotificationDocument, options);
        }
export type NewsStackNotificationQueryHookResult = ReturnType<typeof useNewsStackNotificationQuery>;
export type NewsStackNotificationLazyQueryHookResult = ReturnType<typeof useNewsStackNotificationLazyQuery>;
export type NewsStackNotificationQueryResult = Apollo.QueryResult<NewsStackNotificationQuery, NewsStackNotificationQueryVariables>;
export const NewsWithIdsDocument = gql`
    query NewsWithIds($ids: [ID!]!) {
  newsWithIds(ids: $ids) {
    id
    title
    description
    imageUrl
    thumbnailUrl
    good
    pubDate
    createdAt
    newsPublisher {
      id
      iconUrl
      name
    }
    categories {
      id
      name
    }
    newsNotifications {
      id
      customNotification {
        id
        name
      }
      createdAt
      sendAt
    }
    hasComments
  }
}
    `;

/**
 * __useNewsWithIdsQuery__
 *
 * To run a query within a React component, call `useNewsWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsWithIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useNewsWithIdsQuery(baseOptions: Apollo.QueryHookOptions<NewsWithIdsQuery, NewsWithIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsWithIdsQuery, NewsWithIdsQueryVariables>(NewsWithIdsDocument, options);
      }
export function useNewsWithIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsWithIdsQuery, NewsWithIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsWithIdsQuery, NewsWithIdsQueryVariables>(NewsWithIdsDocument, options);
        }
export type NewsWithIdsQueryHookResult = ReturnType<typeof useNewsWithIdsQuery>;
export type NewsWithIdsLazyQueryHookResult = ReturnType<typeof useNewsWithIdsLazyQuery>;
export type NewsWithIdsQueryResult = Apollo.QueryResult<NewsWithIdsQuery, NewsWithIdsQueryVariables>;
export const PublishersDocument = gql`
    query Publishers($type: String, $languageCode: String, $search: String, $limit: Int, $offset: Int) {
  publishers(
    type: $type
    languageCode: $languageCode
    search: $search
    offsetAttributes: {limit: $limit, offset: $offset}
  ) {
    ...Publisher
  }
}
    ${PublisherFragmentDoc}`;

/**
 * __usePublishersQuery__
 *
 * To run a query within a React component, call `usePublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      languageCode: // value for 'languageCode'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublishersQuery(baseOptions?: Apollo.QueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
      }
export function usePublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
        }
export type PublishersQueryHookResult = ReturnType<typeof usePublishersQuery>;
export type PublishersLazyQueryHookResult = ReturnType<typeof usePublishersLazyQuery>;
export type PublishersQueryResult = Apollo.QueryResult<PublishersQuery, PublishersQueryVariables>;
export const ReportsDocument = gql`
    query Reports($type: String, $limit: Int, $offset: Int) {
  reports(type: $type, offsetAttributes: {limit: $limit, offset: $offset}) {
    ...Report
  }
}
    ${ReportFragmentDoc}`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ShortsPlaylistsDocument = gql`
    query ShortsPlaylists($search: String) {
  playlists(search: $search) {
    id
    name
    slug
  }
}
    `;

/**
 * __useShortsPlaylistsQuery__
 *
 * To run a query within a React component, call `useShortsPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortsPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortsPlaylistsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useShortsPlaylistsQuery(baseOptions?: Apollo.QueryHookOptions<ShortsPlaylistsQuery, ShortsPlaylistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortsPlaylistsQuery, ShortsPlaylistsQueryVariables>(ShortsPlaylistsDocument, options);
      }
export function useShortsPlaylistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortsPlaylistsQuery, ShortsPlaylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortsPlaylistsQuery, ShortsPlaylistsQueryVariables>(ShortsPlaylistsDocument, options);
        }
export type ShortsPlaylistsQueryHookResult = ReturnType<typeof useShortsPlaylistsQuery>;
export type ShortsPlaylistsLazyQueryHookResult = ReturnType<typeof useShortsPlaylistsLazyQuery>;
export type ShortsPlaylistsQueryResult = Apollo.QueryResult<ShortsPlaylistsQuery, ShortsPlaylistsQueryVariables>;
export const TopNewsDocument = gql`
    query TopNews($id: ID!) {
  topNews(id: $id) {
    id
    languageCode
    newsIdArray
    news {
      id
      title
    }
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useTopNewsQuery__
 *
 * To run a query within a React component, call `useTopNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopNewsQuery(baseOptions: Apollo.QueryHookOptions<TopNewsQuery, TopNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopNewsQuery, TopNewsQueryVariables>(TopNewsDocument, options);
      }
export function useTopNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopNewsQuery, TopNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopNewsQuery, TopNewsQueryVariables>(TopNewsDocument, options);
        }
export type TopNewsQueryHookResult = ReturnType<typeof useTopNewsQuery>;
export type TopNewsLazyQueryHookResult = ReturnType<typeof useTopNewsLazyQuery>;
export type TopNewsQueryResult = Apollo.QueryResult<TopNewsQuery, TopNewsQueryVariables>;
export const TopNewsListDocument = gql`
    query topNewsList($limit: Int, $offset: Int) {
  topNewsList(offsetAttributes: {limit: $limit, offset: $offset}) {
    id
    metadata {
      newsId
      trendingRank
    }
    languageCode
    newsIdArray
    news {
      id
      title
    }
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useTopNewsListQuery__
 *
 * To run a query within a React component, call `useTopNewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNewsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTopNewsListQuery(baseOptions?: Apollo.QueryHookOptions<TopNewsListQuery, TopNewsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopNewsListQuery, TopNewsListQueryVariables>(TopNewsListDocument, options);
      }
export function useTopNewsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopNewsListQuery, TopNewsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopNewsListQuery, TopNewsListQueryVariables>(TopNewsListDocument, options);
        }
export type TopNewsListQueryHookResult = ReturnType<typeof useTopNewsListQuery>;
export type TopNewsListLazyQueryHookResult = ReturnType<typeof useTopNewsListLazyQuery>;
export type TopNewsListQueryResult = Apollo.QueryResult<TopNewsListQuery, TopNewsListQueryVariables>;
export const TopicStackWithIdDocument = gql`
    query TopicStackWithId($id: ID!) {
  topicStack(id: $id) {
    id
    categories {
      id
      name
    }
  }
}
    `;

/**
 * __useTopicStackWithIdQuery__
 *
 * To run a query within a React component, call `useTopicStackWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicStackWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicStackWithIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicStackWithIdQuery(baseOptions: Apollo.QueryHookOptions<TopicStackWithIdQuery, TopicStackWithIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicStackWithIdQuery, TopicStackWithIdQueryVariables>(TopicStackWithIdDocument, options);
      }
export function useTopicStackWithIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicStackWithIdQuery, TopicStackWithIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicStackWithIdQuery, TopicStackWithIdQueryVariables>(TopicStackWithIdDocument, options);
        }
export type TopicStackWithIdQueryHookResult = ReturnType<typeof useTopicStackWithIdQuery>;
export type TopicStackWithIdLazyQueryHookResult = ReturnType<typeof useTopicStackWithIdLazyQuery>;
export type TopicStackWithIdQueryResult = Apollo.QueryResult<TopicStackWithIdQuery, TopicStackWithIdQueryVariables>;
export const TypesearchAlgoliaFlagDocument = gql`
    query TypesearchAlgoliaFlag {
  typesearchAlgoliaFlag {
    typesenseIsOn
  }
}
    `;

/**
 * __useTypesearchAlgoliaFlagQuery__
 *
 * To run a query within a React component, call `useTypesearchAlgoliaFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypesearchAlgoliaFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypesearchAlgoliaFlagQuery({
 *   variables: {
 *   },
 * });
 */
export function useTypesearchAlgoliaFlagQuery(baseOptions?: Apollo.QueryHookOptions<TypesearchAlgoliaFlagQuery, TypesearchAlgoliaFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypesearchAlgoliaFlagQuery, TypesearchAlgoliaFlagQueryVariables>(TypesearchAlgoliaFlagDocument, options);
      }
export function useTypesearchAlgoliaFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypesearchAlgoliaFlagQuery, TypesearchAlgoliaFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypesearchAlgoliaFlagQuery, TypesearchAlgoliaFlagQueryVariables>(TypesearchAlgoliaFlagDocument, options);
        }
export type TypesearchAlgoliaFlagQueryHookResult = ReturnType<typeof useTypesearchAlgoliaFlagQuery>;
export type TypesearchAlgoliaFlagLazyQueryHookResult = ReturnType<typeof useTypesearchAlgoliaFlagLazyQuery>;
export type TypesearchAlgoliaFlagQueryResult = Apollo.QueryResult<TypesearchAlgoliaFlagQuery, TypesearchAlgoliaFlagQueryVariables>;
export const FindUserDocument = gql`
    query FindUser($id: ID, $uuid: ID, $limit: Int, $offset: Int) {
  user(id: $id, uuid: $uuid) {
    id
    displayName
    username
    uuid
    isBannedFromComment
    comments(offsetAttributes: {limit: $limit, offset: $offset}) {
      id
      createdAt
      content
      likesCount
      hasReports
      commentableType
      commentableId
    }
    reportingComments {
      ...Comment
    }
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useFindUserQuery__
 *
 * To run a query within a React component, call `useFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      uuid: // value for 'uuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindUserQuery(baseOptions?: Apollo.QueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
      }
export function useFindUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
        }
export type FindUserQueryHookResult = ReturnType<typeof useFindUserQuery>;
export type FindUserLazyQueryHookResult = ReturnType<typeof useFindUserLazyQuery>;
export type FindUserQueryResult = Apollo.QueryResult<FindUserQuery, FindUserQueryVariables>;
export const VideoNotificationDocument = gql`
    query VideoNotification($perPage: Int, $page: Int, $afterSendAt: ISO8601DateTime) {
  videoNotifications(perPage: $perPage, page: $page, afterSendAt: $afterSendAt) {
    id
    title
    createdAt
    sendAt
    video {
      id
      newsPublisher {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useVideoNotificationQuery__
 *
 * To run a query within a React component, call `useVideoNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoNotificationQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      afterSendAt: // value for 'afterSendAt'
 *   },
 * });
 */
export function useVideoNotificationQuery(baseOptions?: Apollo.QueryHookOptions<VideoNotificationQuery, VideoNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoNotificationQuery, VideoNotificationQueryVariables>(VideoNotificationDocument, options);
      }
export function useVideoNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoNotificationQuery, VideoNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoNotificationQuery, VideoNotificationQueryVariables>(VideoNotificationDocument, options);
        }
export type VideoNotificationQueryHookResult = ReturnType<typeof useVideoNotificationQuery>;
export type VideoNotificationLazyQueryHookResult = ReturnType<typeof useVideoNotificationLazyQuery>;
export type VideoNotificationQueryResult = Apollo.QueryResult<VideoNotificationQuery, VideoNotificationQueryVariables>;
export const VideosWithIdsDocument = gql`
    query VideosWithIds($ids: [ID!]!) {
  videosWithIds(ids: $ids) {
    id
    title
    externalId
    createdAt
    pubDate
    isShort
    newsPublisher {
      name
    }
    categories {
      id
      name
    }
    videoNotifications {
      ...VideoNotification
    }
  }
}
    ${VideoNotificationFragmentDoc}`;

/**
 * __useVideosWithIdsQuery__
 *
 * To run a query within a React component, call `useVideosWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosWithIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useVideosWithIdsQuery(baseOptions: Apollo.QueryHookOptions<VideosWithIdsQuery, VideosWithIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideosWithIdsQuery, VideosWithIdsQueryVariables>(VideosWithIdsDocument, options);
      }
export function useVideosWithIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideosWithIdsQuery, VideosWithIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideosWithIdsQuery, VideosWithIdsQueryVariables>(VideosWithIdsDocument, options);
        }
export type VideosWithIdsQueryHookResult = ReturnType<typeof useVideosWithIdsQuery>;
export type VideosWithIdsLazyQueryHookResult = ReturnType<typeof useVideosWithIdsLazyQuery>;
export type VideosWithIdsQueryResult = Apollo.QueryResult<VideosWithIdsQuery, VideosWithIdsQueryVariables>;
export const CategoriesDocument = gql`
    query Categories($orderByName: Boolean, $withoutStacks: Boolean) {
  categories(orderByName: $orderByName, withoutStacks: $withoutStacks) {
    id
    name
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      orderByName: // value for 'orderByName'
 *      withoutStacks: // value for 'withoutStacks'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const PublishersDropdownDocument = gql`
    query PublishersDropdown($type: String, $languageCode: String, $search: String, $limit: Int, $offset: Int, $orderByName: Boolean) {
  publishers(
    type: $type
    languageCode: $languageCode
    orderByName: $orderByName
    search: $search
    offsetAttributes: {limit: $limit, offset: $offset}
  ) {
    id
    name
  }
}
    `;

/**
 * __usePublishersDropdownQuery__
 *
 * To run a query within a React component, call `usePublishersDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersDropdownQuery({
 *   variables: {
 *      type: // value for 'type'
 *      languageCode: // value for 'languageCode'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderByName: // value for 'orderByName'
 *   },
 * });
 */
export function usePublishersDropdownQuery(baseOptions?: Apollo.QueryHookOptions<PublishersDropdownQuery, PublishersDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishersDropdownQuery, PublishersDropdownQueryVariables>(PublishersDropdownDocument, options);
      }
export function usePublishersDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishersDropdownQuery, PublishersDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishersDropdownQuery, PublishersDropdownQueryVariables>(PublishersDropdownDocument, options);
        }
export type PublishersDropdownQueryHookResult = ReturnType<typeof usePublishersDropdownQuery>;
export type PublishersDropdownLazyQueryHookResult = ReturnType<typeof usePublishersDropdownLazyQuery>;
export type PublishersDropdownQueryResult = Apollo.QueryResult<PublishersDropdownQuery, PublishersDropdownQueryVariables>;